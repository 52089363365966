import {
  TCommonProductInformationData,
  TInvoiceDiscountingProductInformationData,
  TProductInformationData,
  TWorkingCapitalProductInformationData,
} from 'pages/SubmitLoanApplication/types';

import CommonProductInformationForm from './CommonProductInformationForm';
import { CreditProducts } from 'enums/CreditProducts';
import WorkingCapitalProductInformationForm from './WorkingCapitalProductInformationForm';
import InvoiceDiscountingProductInformationForm from './InvoiceDiscountingProductInformationForm';

interface IProductInformationFormProps {
  selectedProduct: CreditProducts;
  data: TProductInformationData;
  onSubmit: (values: TProductInformationData) => void;
  onPrevStep: () => void;
}

const ProductInformationForm = ({
  data,
  onSubmit,
  selectedProduct,
  onPrevStep,
}: IProductInformationFormProps) => {
  const renderProductInformationFormByCreditProduct = () => {
    switch (selectedProduct) {
      case CreditProducts.CommodityFinancing:
      case CreditProducts.InventoryFinancing:
      case CreditProducts.PrincipalTrading:
        return (
          <CommonProductInformationForm
            data={data as TCommonProductInformationData}
            onSubmit={onSubmit}
            onPrevStep={onPrevStep}
          />
        );
      case CreditProducts.WorkingCapital:
        return (
          <WorkingCapitalProductInformationForm
            data={data as TWorkingCapitalProductInformationData}
            onSubmit={onSubmit}
            onPrevStep={onPrevStep}
          />
        );
      case CreditProducts.InvoiceDiscounting:
        return (
          <InvoiceDiscountingProductInformationForm
            data={data as TInvoiceDiscountingProductInformationData}
            onPrevStep={onPrevStep}
            onSubmit={onSubmit}
          />
        );
      default:
        return null as never;
    }
  };

  return renderProductInformationFormByCreditProduct();
};

export default ProductInformationForm;

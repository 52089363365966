export enum Variables {
  BorrowerCompanyName = 'borrower_company_name',
  BorrowerCompanyRegistrationNumber = 'borrower_company_registration_number',
  BorrowerRegisteredBusinessAddress = 'borrower_registered_business_address',
  BorrowerCountry = 'borrower_country',
  BorrowerBusinessType = 'borrower_business_type',
  BorrowerNatureOfBusiness = 'borrower_nature_of_business',
  BorrowerOperatingAddress = 'borrower_operating_address',
  BorrowerEmail = 'borrower_email',
  BorrowerPhoneNumber = 'borrower_phone_number',
  BorrowerTin = 'borrower_tin',
  BorrowerDirectorOneFirstName = 'borrower_director_1_first_name',
  BorrowerDirectorTwoFirstName = 'borrower_director_2_first_name',
  BorrowerDirectorOneMiddleName = 'borrower_director_1_middle_name',
  BorrowerDirectorTwoMiddleName = 'borrower_director_2_middle_name',
  BorrowerDirectorOneLastName = 'borrower_director_1_last_name',
  BorrowerDirectorTwoLastName = 'borrower_director_2_last_name',
  BorrowerDirectorOneDateOfBirth = 'borrower_director_1_date_of_birth',
  BorrowerDirectorTwoDateOfBirth = 'borrower_director_2_date_of_birth',
  BorrowerDirectorOneBankVerificationNumber = 'borrower_director_1_bvn',
  BorrowerDirectorTwoBankVerificationNumber = 'borrower_director_2_bvn',
  BorrowerDirectorOneEmailAddress = 'borrower_director_1_email',
  BorrowerDirectorTwoEmailAddress = 'borrower_director_2_email',
  BorrowerDirectorOnePhoneNumber = 'borrower_director_1_phone_number',
  BorrowerDirectorTwoPhoneNumber = 'borrower_director_2_phone_number',
  BorrowerDirectorOneResidentialAddress = 'borrower_director_1_residential_address',
  BorrowerDirectorTwoResidentialAddress = 'borrower_director_2_residential_address',
  BorrowerDirectorOneIdDocumentType = 'borrower_director_1_id_document_type',
  BorrowerDirectorTwoIdDocumentType = 'borrower_director_2_id_document_type',
  BorrowerDirectorOneBvnImage = 'borrower_director_1_bvn_image',
  BorrowerDirectorTwoBvnImage = 'borrower_director_2_bvn_image',
  BorrowerDirectorOneNationalIdNumber = 'borrower_director_1_national_id_number',
  BorrowerDirectorTwoNationalIdNumber = 'borrower_director_2_national_id_number',
  BorrowerDirectorTwoExists = 'borrower_director_2_exists',
  BorrowerCompanyRepresentativeFirstName = 'borrower_company_representative_first_name',
  BorrowerCompanyRepresentativeMiddleName = 'borrower_company_representative_middle_name',
  BorrowerCompanyRepresentativeLastName = 'borrower_company_representative_last_name',
  BorrowerCompanyRepresentativeDateOfBirth = 'borrower_company_representative_date_of_birth',
  BorrowerCompanyRepresentativeBvn = 'borrower_company_representative_bvn',
  BorrowerCompanyRepresentativeEmail = 'borrower_company_representative_email',
  BorrowerCompanyRepresentativePhoneNumber = 'borrower_company_representative_phone_number',
  BorrowerCompanyRepresentativeResidentialAddress = 'borrower_company_representative_residential_address',
  BorrowerCompanyRepresentativeIdDocumentType = 'borrower_company_representative_id_document_type',
  BorrowerCompanyRepresentativeBvnImage = 'borrower_company_representative_bvn_image',
  BorrowerCompanyRepresentativeNationalIdNumber = 'borrower_company_representative_national_id_number',
  BorrowerCompanyRepresentative = 'borrower_company_representative',
  BorrowerCompanyRepresentativeExists = 'borrower_company_representative_exists',
  BorrowerDirectorOneIsRepresentative = 'borrower_director_1_is_representative',
  BorrowerDirectorTwoIsRepresentative = 'borrower_director_2_is_representative',
  BorrowerAzureId = 'borrower_azure_id',
  ProductTypeList = 'product_type_list',
  ProductTypeOther = 'product_type_other',
  TransactionType = 'transaction_type',
  ProductList = 'product_list',
  ProductOther = 'product_other',
  ProductUnits = 'product_units',
  ProductQuantity = 'product_quantity',
  ProductUnitPurchasePrice = 'product_unit_purchase_price',
  ProductUnitSellingPrice = 'product_unit_selling_price',
  LogisticsCostPerTruck = 'logistics_cost_per_truck',
  LogisticsNumberOfTrucks = 'logistics_number_of_trucks',
  OtherCostsAmount = 'other_costs_amount',
  OtherCostsDescription = 'other_costs_description',
  LoanAmount = 'loan_amount',
  SupplierAddress = 'supplier_address',
  PaymentTenorInDays = 'payment_tenor_in_days',
  OfftakerName = 'offtaker_name',
  OfftakerAddress = 'offtaker_address',
  OfftakerEmail = 'offtaker_email',
  BankAccountName = 'bank_account_name',
  BankAccountNumber = 'bank_account_number',
  BankName = 'bank_name',
  BorrowerVerificationStatus = 'borrower_verification_status',
  LoanRate = 'loan_rate',
  LoanTerm = 'loan_term',
  BorrowerDirectorOneVirtualNationalIdNumber = 'borrower_director_1_virtual_national_id_number',
  BorrowerDirectorTwoVirtualNationalIdNumber = 'borrower_director_2_virtual_national_id_number',
  BorrowerCompanyRepresentativeVirtualNationalIdNumber = 'borrower_company_representative_virtual_national_id_number',
  ProductCategory = 'product_category',
  DateInvoice = 'date_invoice',
  FacilityPurpose = 'facility_purpose',
  ApplicationSource = 'application_source',
}

import { CreditProducts } from 'enums/CreditProducts';
import DocumentsUploadForm from 'components/DocumentsUploadForm/DocumentsUploadForm';
import React from 'react';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';
import { TUploadDocumentData } from 'pages/SubmitLoanApplication/types';

interface IUploadDocumentProps {
  selectedCreditProduct: CreditProducts;
  data: TUploadDocumentData;
  onPrevStep: (step: SubmitLoanApplicationSteps) => void;
  onApplicationCreate: (data: TUploadDocumentData) => void;
}

const UploadDocument = ({
  data,
  onPrevStep,
  onApplicationCreate,
  selectedCreditProduct,
}: IUploadDocumentProps) => {
  const handleApplicationCreate = (documents: TUploadDocumentData) => {
    onApplicationCreate(documents);
  };

  const handlePrevStep = () => {
    onPrevStep(SubmitLoanApplicationSteps.BankAccount);
  };

  return (
    <DocumentsUploadForm
      selectedCreditProduct={selectedCreditProduct}
      data={data}
      onPrevStep={handlePrevStep}
      onSubmit={handleApplicationCreate}
    />
  );
};

export default UploadDocument;

import * as Yup from 'yup';

import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import { getRequiredStringValidationRules } from 'helpers/validation';

export const authorizationInformationFormValidationSchema = Yup.object().shape({
  [Variables.BorrowerCompanyName]: getRequiredStringValidationRules(
    InputLabels.RegisteredBusinessName,
  ),
  [Variables.BorrowerCompanyRegistrationNumber]: getRequiredStringValidationRules(
    InputLabels.RcNumber,
  ),
  [Variables.BorrowerDirectorOneFirstName]: getRequiredStringValidationRules(InputLabels.FirstName),
  [Variables.BorrowerDirectorOneLastName]: getRequiredStringValidationRules(InputLabels.LastName),
});

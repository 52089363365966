import React from 'react';
import SupportEmail from 'components/SupportEmail';
import { SunImage, SabiLogo } from 'static/images';
import { FCWithChildren } from 'types/common';
import styles from './WelcomePageWrapper.module.scss';

const WelcomePageWrapper: FCWithChildren<{}> = ({ children }) => {
  return (
    <div className={styles.container}>
      <SabiLogo className={styles.sabiLogo} />
      <div className={styles.childrenContainer}>{children}</div>
      <SupportEmail className={styles.email} />
      <SunImage className={styles.sabiIcon} />
    </div>
  );
};

export default WelcomePageWrapper;

import InputWrapper from 'components/InputWrapper';
import React, { FC } from 'react';

export interface IInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  error?: string | React.ReactNode;
  optional?: boolean;
  inputDescription?: string | React.ReactNode;
}

const Input: FC<IInputProps> = ({
  label,
  error,
  className,
  optional,
  inputDescription,
  ...props
}) => {
  return (
    <InputWrapper
      label={label}
      error={error}
      className={className}
      optional={optional}
      inputDescription={inputDescription}
    >
      <input type="text" placeholder={label} {...props} />
    </InputWrapper>
  );
};

export default Input;

import { Variables } from 'enums/Variables';
import { VariableValue } from 'types/common';

const NUMERIC_VARIABLES = [
  Variables.ProductUnitPurchasePrice,
  Variables.ProductUnitSellingPrice,
  Variables.LogisticsCostPerTruck,
  Variables.LogisticsNumberOfTrucks,
  Variables.OtherCostsAmount,
  Variables.LoanAmount,
  Variables.PaymentTenorInDays,
  Variables.ProductQuantity,
];

export const transformData = (
  data: Record<string, VariableValue>,
): Record<string, VariableValue> => {
  const transformedData: Record<string, VariableValue> = {};

  Object.keys(data).map((key) => {
    if (!data[key]) {
      return null;
    }

    if (NUMERIC_VARIABLES.includes(key as Variables)) {
      transformedData[key] = Number((data[key] as string).replace(/\D/g, ''));
    } else {
      transformedData[key] = data[key];
    }
  });

  return transformedData;
};

import { Formik } from 'formik';
import Input from 'components/Input';
import { InputLabels } from 'enums/InputLabels';
import NextButton from 'components/NextButton';
import NumberInput from 'components/NumberInput';
import { TAuthorizationData } from 'pages/CreateAccount/types';
import { Variables } from 'enums/Variables';
import { authorizationInformationFormValidationSchema } from './validationSchema';
import styles from './AuthorizationInformationForm.module.scss';

interface IAuthorizationInformationProps {
  data: TAuthorizationData;
  onSubmit: (values: TAuthorizationData) => Promise<void>;
}

const AuthorizationInformationForm = ({ data, onSubmit }: IAuthorizationInformationProps) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={authorizationInformationFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, handleBlur, submitForm }) => {
        const getErrorMessage = (name: keyof TAuthorizationData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        return (
          <>
            <div className={styles.container}>
              <Input
                label={InputLabels.RegisteredBusinessName}
                value={values[Variables.BorrowerCompanyName]}
                onChange={handleChange}
                name={Variables.BorrowerCompanyName}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerCompanyName)}
              />
              <NumberInput
                label={InputLabels.RcNumber}
                value={values[Variables.BorrowerCompanyRegistrationNumber]}
                onChange={handleChange}
                name={Variables.BorrowerCompanyRegistrationNumber}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerCompanyRegistrationNumber)}
              />
              <Input
                label={InputLabels.FirstName}
                value={values[Variables.BorrowerDirectorOneFirstName]}
                onChange={handleChange}
                onBlur={handleBlur}
                name={Variables.BorrowerDirectorOneFirstName}
                error={getErrorMessage(Variables.BorrowerDirectorOneFirstName)}
              />
              <Input
                label={InputLabels.LastName}
                value={values[Variables.BorrowerDirectorOneLastName]}
                onChange={handleChange}
                onBlur={handleBlur}
                name={Variables.BorrowerDirectorOneLastName}
                error={getErrorMessage(Variables.BorrowerDirectorOneLastName)}
              />
            </div>

            <div className={styles.buttonContainer}>
              <NextButton onClick={submitForm} />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AuthorizationInformationForm;

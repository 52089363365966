import {
  LABELS_TO_COMMON_INPUTS_PRODUCT_INFORMATION,
  LABELS_TO_COMMON_NAMES_UPLOAD_DOCUMENT,
  LABELS_TO_INPUTS_BANK_ACCOUNT,
  LABELS_TO_INVOICE_DISCOUNTING_INPUTS_PRODUCT_INFORMATION,
  LABELS_TO_INVOICE_DISCOUNTING_UPLOAD_DOCUMENT,
  LABELS_TO_WORKING_CAPITAL_INPUTS_PRODUCT_INFORMATION,
  LABELS_TO_WORKING_CAPITAL_UPLOAD_DOCUMENT,
  MONETARY_VARIABLE_INPUT_LABELS,
} from './constants';

import { CreditProducts } from 'enums/CreditProducts';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';
import { TTableDataValue } from 'components/Table/Table';
import { VariableValue } from 'types/common';
import { Variables } from 'enums/Variables';
import { convertToMonetary } from 'helpers/formatters';

const DEFAULT_FRACTION_DIGITS = 0;

const getLabelAndVariablesFromSectionName = (
  productType: CreditProducts,
  sectionName?: SubmitLoanApplicationSteps,
) => {
  if (sectionName === SubmitLoanApplicationSteps.ProductInformation) {
    if (productType === CreditProducts.WorkingCapital) {
      return LABELS_TO_WORKING_CAPITAL_INPUTS_PRODUCT_INFORMATION;
    }

    if (productType === CreditProducts.InvoiceDiscounting) {
      return LABELS_TO_INVOICE_DISCOUNTING_INPUTS_PRODUCT_INFORMATION;
    }

    return LABELS_TO_COMMON_INPUTS_PRODUCT_INFORMATION;
  }

  if (sectionName === SubmitLoanApplicationSteps.BankAccount) {
    return LABELS_TO_INPUTS_BANK_ACCOUNT;
  }

  if (sectionName === SubmitLoanApplicationSteps.UploadDocument) {
    if (productType === CreditProducts.WorkingCapital) {
      return LABELS_TO_WORKING_CAPITAL_UPLOAD_DOCUMENT;
    }

    if (productType === CreditProducts.InvoiceDiscounting) {
      return LABELS_TO_INVOICE_DISCOUNTING_UPLOAD_DOCUMENT;
    }
    return LABELS_TO_COMMON_NAMES_UPLOAD_DOCUMENT;
  }

  return LABELS_TO_COMMON_INPUTS_PRODUCT_INFORMATION;
};

const getFormattedVariableValue = (
  sectionName: SubmitLoanApplicationSteps,
  variableName: string,
  value: VariableValue,
): TTableDataValue => {
  if (sectionName === SubmitLoanApplicationSteps.UploadDocument) {
    return {
      isFile: true,
      value: variableName,
    };
  }

  const getValue = () => {
    if (!value) {
      return '-';
    }

    return MONETARY_VARIABLE_INPUT_LABELS.includes(variableName as Variables)
      ? convertToMonetary(Number(value), DEFAULT_FRACTION_DIGITS)
      : value;
  };

  return {
    isFile: false,
    value: getValue(),
  };
};

export const getContentFromApplicationData = (
  variables: Record<string, VariableValue>,
  sectionName: SubmitLoanApplicationSteps,
): Record<string, TTableDataValue> => {
  const productType = variables[Variables.ProductCategory] as CreditProducts;

  const mappedLabelsToInputs: Record<string, string> = getLabelAndVariablesFromSectionName(
    productType,
    sectionName,
  );

  const formattedContent: Record<string, TTableDataValue> = {};

  Object.keys(mappedLabelsToInputs).map((label) => {
    const variableName = mappedLabelsToInputs[label];

    return (formattedContent[label] = getFormattedVariableValue(
      sectionName,
      variableName,
      variables[variableName],
    ));
  });

  return formattedContent;
};

import { DocumentsUploadFormVariables } from 'enums/DocumentsUploadFormVariables';
import { FileNames } from 'enums/FileNames';
import { Variables } from 'enums/Variables';

export const getFileNameByVariable = (inputName: Variables | DocumentsUploadFormVariables) => {
  const fileNameByVariableMap = {
    [Variables.BorrowerDirectorOneBvnImage]: FileNames.DirectorOnePassportPhoto,
    [Variables.BorrowerDirectorTwoBvnImage]: FileNames.DirectorTwoPassportPhoto,
    [Variables.BorrowerCompanyRepresentativeBvnImage]: FileNames.CompanyRepresentativePassportPhoto,
    [DocumentsUploadFormVariables.PurchaseOrder]: FileNames.PurchaseOrder,
    [DocumentsUploadFormVariables.ContractAgreement]: FileNames.TradeContractAgreement,
    [DocumentsUploadFormVariables.Invoice]: FileNames.Invoice,
    [DocumentsUploadFormVariables.FinancialStatement]: FileNames.AuditedFinancialStatement,
    [DocumentsUploadFormVariables.StatementOfAccount]: FileNames.StatementOfAccount,
    [DocumentsUploadFormVariables.CompanyOrManagementProfile]: FileNames.CompanyOrManagementProfile,
    [DocumentsUploadFormVariables.PreviouslyExecutedContracts]:
      FileNames.PreviouslyExecutedContracts,
    [DocumentsUploadFormVariables.ProFormaInvoice]: FileNames.ProFormaInvoice,
    [DocumentsUploadFormVariables.BankStatement]: FileNames.BankStatement,
    [DocumentsUploadFormVariables.FormalApplicationOrBoardResolution]:
      FileNames.FormalApplicationOrBoardResolution,
    [DocumentsUploadFormVariables.CashFlowProjection]: FileNames.CashFlowProjection,
    [DocumentsUploadFormVariables.InvoiceStampedByPrincipal]: FileNames.InvoiceStampedByPrincipal,
    [DocumentsUploadFormVariables.EvidenceOfPastSupplies]: FileNames.EvidenceOfPastSupplies,
    [DocumentsUploadFormVariables.CopyOfDeliveryNote]: FileNames.CopyOfDeliveryNote,
  } as Record<Variables | DocumentsUploadFormVariables, FileNames>;

  return fileNameByVariableMap[inputName] || inputName;
};

import { AuthContext } from 'providers/AuthProvider';
import React, { useContext } from 'react';
import { LogOut, SabiLogo } from 'static/images';
import styles from './Header.module.scss';

const Header = () => {
  const { handleLogout } = useContext(AuthContext);

  return (
    <div className={styles.container}>
      <SabiLogo className={styles.logo} />
      <div className={styles.logoutButton} onClick={handleLogout}>
        Log Out <LogOut className={styles.logout} />
      </div>
    </div>
  );
};

export default Header;

import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './App';
import React from 'react';
import { SecretKeys } from 'enums/SecretKeys';
import { getSecretValue } from 'helpers/getSecretValue';

import './index.scss';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: getSecretValue(SecretKeys.SentryDsn),
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);

import { DateTime } from 'luxon';

export const NON_DIGITS_PATTERN = /\D/g;

const defaultIntlDecimalOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const LOCALE = 'en-NG';
const DATE_FORMAT = 'dd MMM, yyyy, hh:mm a';
const DEFAULT_TERM_UNIT = 'day';
const CURRENCY = 'NGN';

const getFormatterByValueType = (value: number, options: Record<string, string | number>) => {
  return new Intl.NumberFormat(LOCALE, options).format(value);
};

export const convertToMonetary = (value: number, fractionDigits?: number) => {
  const getDecimal = () =>
    fractionDigits !== undefined
      ? { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
      : defaultIntlDecimalOptions;

  return getFormatterByValueType(value, {
    style: 'currency',
    currency: CURRENCY,
    currencyDisplay: 'symbol',
    ...getDecimal(),
  });
};

export const formatApplicationId = (id: string) => '#' + id;

export const convertToPercentage = (value: number) => {
  return getFormatterByValueType(value, {
    style: 'percent',
    ...defaultIntlDecimalOptions,
  });
};

export const termFormatter = (value: number) => {
  return getFormatterByValueType(value, {
    style: 'unit',
    unit: DEFAULT_TERM_UNIT,
    unitDisplay: 'long',
  });
};

export const formatIsoDate = (date: string = new Date().toISOString()) => {
  return DateTime.fromISO(date).toFormat(DATE_FORMAT);
};

export const convertMonetaryValueToIntegerNumber = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const formattedValue = value?.replace(NON_DIGITS_PATTERN, '');

  return Number(formattedValue);
};

export const convertDateToDigifiFormat = (date: string) => {
  const [day, month, year] = date.split('/');

  return `${month}/${day}/${year}`;
};

import React from 'react';
import { FCWithChildren } from 'types/common';
import { SunImage } from 'static/images';
import SupportEmail from 'components/SupportEmail';
import Header from './Header';
import Navigation from './Navigation';
import styles from './Layout.module.scss';
import { INavigation } from './Navigation/Navigation';
import PageTitle from 'components/PageTitle';
import Loader from 'components/Loader';
import { StatusLabelList } from 'enums/StatusLabels';

interface ILayoutProps {
  navData: INavigation;
  pageTitle?: string;
  loading?: boolean;
  verificationStatus?: StatusLabelList;
  customStatusLabel?: string;
  backButton?: boolean;
  onBackButtonClick?: () => void;
}

const Layout: FCWithChildren<ILayoutProps> = ({
  children,
  navData,
  loading,
  pageTitle,
  verificationStatus,
  customStatusLabel,
  backButton,
  onBackButtonClick,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Header />
        <Navigation
          title={navData.title}
          description={navData.description}
          links={navData.links}
          subLinks={navData.subLinks}
        />
        <SunImage className={styles.sabiIcon} />
        <div className={styles.childrenWrapper}>
          <div className={styles.childrenContainer}>
            {loading ? (
              <Loader />
            ) : (
              <div className={styles.childrenContent}>
                <PageTitle
                  title={pageTitle!}
                  verificationStatus={verificationStatus}
                  customStatusLabel={customStatusLabel}
                  backButton={backButton}
                  onBackButtonClick={onBackButtonClick}
                />
                {children}
              </div>
            )}
          </div>
        </div>
        <SupportEmail className={styles.email} />
      </div>
    </div>
  );
};

export default Layout;

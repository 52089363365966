import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import {
  getBankAccountNumberValidationRules,
  getRequiredStringValidationRules,
} from 'helpers/validation';
import * as Yup from 'yup';

export const companyInformationFormValidationSchema = Yup.object().shape({
  [Variables.BankAccountName]: getRequiredStringValidationRules(InputLabels.BankAccountName),
  [Variables.BankAccountNumber]: getBankAccountNumberValidationRules(),
  [Variables.BankName]: getRequiredStringValidationRules(InputLabels.BankName),
});

import { CompanyRepresentativeInputValue } from 'pages/CreateAccount/types';

export const getCompanyRepresentativeValues = (secondDirectorExist: boolean) => {
  const companyRepresentativeValues = [
    CompanyRepresentativeInputValue.DirectorOne,
    secondDirectorExist ? CompanyRepresentativeInputValue.DirectorTwo : undefined,
    CompanyRepresentativeInputValue.Other,
  ].filter(Boolean);

  return companyRepresentativeValues as string[];
};

import {
  TAuthorizationData,
  TFirstDirectorData,
  TRepresentativeData,
  TSecondDirectorData,
} from './types';

import { Variables } from 'enums/Variables';

export const DEFAULT_AUTHORIZATION_INFORMATION_VALUES: TAuthorizationData = {
  [Variables.BorrowerCompanyName]: '',
  [Variables.BorrowerCompanyRegistrationNumber]: '',
  [Variables.BorrowerDirectorOneFirstName]: '',
  [Variables.BorrowerDirectorOneLastName]: '',
};

export const DEFAULT_COMPANY_INFORMATION_VALUES = {
  [Variables.BorrowerCompanyName]: '',
  [Variables.BorrowerCompanyRegistrationNumber]: '',
  [Variables.BorrowerRegisteredBusinessAddress]: '',
  [Variables.BorrowerCountry]: 'Nigeria',
  [Variables.BorrowerBusinessType]: '',
  [Variables.BorrowerNatureOfBusiness]: '',
  [Variables.BorrowerOperatingAddress]: '',
  [Variables.BorrowerEmail]: '',
  [Variables.BorrowerPhoneNumber]: '',
  [Variables.BorrowerTin]: '',
};

export const DEFAULT_DIRECTOR_ONE_INFORMATION_VALUES = {
  [Variables.BorrowerDirectorOneFirstName]: '',
  [Variables.BorrowerDirectorOneMiddleName]: '',
  [Variables.BorrowerDirectorOneLastName]: '',
  [Variables.BorrowerDirectorOneDateOfBirth]: '',
  [Variables.BorrowerDirectorOneBankVerificationNumber]: '',
  [Variables.BorrowerDirectorOneEmailAddress]: '',
  [Variables.BorrowerDirectorOnePhoneNumber]: '',
  [Variables.BorrowerDirectorOneResidentialAddress]: '',
  [Variables.BorrowerDirectorOneIdDocumentType]: 'National ID',
  [Variables.BorrowerDirectorOneNationalIdNumber]: '',
  [Variables.BorrowerDirectorOneBvnImage]: [],
  [Variables.BorrowerDirectorOneVirtualNationalIdNumber]: '',
} as TFirstDirectorData;

export const DEFAULT_DIRECTOR_TWO_INFORMATION_VALUES = {
  [Variables.BorrowerDirectorTwoFirstName]: '',
  [Variables.BorrowerDirectorTwoMiddleName]: '',
  [Variables.BorrowerDirectorTwoLastName]: '',
  [Variables.BorrowerDirectorTwoDateOfBirth]: '',
  [Variables.BorrowerDirectorTwoBankVerificationNumber]: '',
  [Variables.BorrowerDirectorTwoEmailAddress]: '',
  [Variables.BorrowerDirectorTwoPhoneNumber]: '',
  [Variables.BorrowerDirectorTwoResidentialAddress]: '',
  [Variables.BorrowerDirectorTwoIdDocumentType]: 'National ID',
  [Variables.BorrowerDirectorTwoNationalIdNumber]: '',
  [Variables.BorrowerDirectorTwoBvnImage]: [],
  [Variables.BorrowerDirectorTwoVirtualNationalIdNumber]: '',
} as TSecondDirectorData;

export const REPRESENTATIVE_INFORMATION_VALUES = {
  [Variables.BorrowerCompanyRepresentativeFirstName]: '',
  [Variables.BorrowerCompanyRepresentativeMiddleName]: '',
  [Variables.BorrowerCompanyRepresentativeLastName]: '',
  [Variables.BorrowerCompanyRepresentativeDateOfBirth]: '',
  [Variables.BorrowerCompanyRepresentativeBvn]: '',
  [Variables.BorrowerCompanyRepresentativeEmail]: '',
  [Variables.BorrowerCompanyRepresentativePhoneNumber]: '',
  [Variables.BorrowerCompanyRepresentativeResidentialAddress]: '',
  [Variables.BorrowerCompanyRepresentativeIdDocumentType]: 'National ID',
  [Variables.BorrowerCompanyRepresentativeBvnImage]: [],
  [Variables.BorrowerCompanyRepresentativeNationalIdNumber]: '',
  [Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber]: '',
};

export const REPRESENTATIVE_DIRECTOR_FORM_INPUT_VARIABLES = {
  firstName: Variables.BorrowerCompanyRepresentativeFirstName,
  phone: Variables.BorrowerCompanyRepresentativePhoneNumber,
  middleName: Variables.BorrowerCompanyRepresentativeMiddleName,
  lastName: Variables.BorrowerCompanyRepresentativeLastName,
  dateOfBirth: Variables.BorrowerCompanyRepresentativeDateOfBirth,
  bankVerificationNumber: Variables.BorrowerCompanyRepresentativeBvn,
  email: Variables.BorrowerCompanyRepresentativeEmail,
  residentialAddress: Variables.BorrowerCompanyRepresentativeResidentialAddress,
  documentType: Variables.BorrowerCompanyRepresentativeIdDocumentType,
  bvnImage: Variables.BorrowerCompanyRepresentativeBvnImage,
  nationalIdNumber: Variables.BorrowerCompanyRepresentativeNationalIdNumber,
  virtualNationalIdNumber: Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber,
} as Record<string, keyof TRepresentativeData>;

export const FIRST_DIRECTOR_FORM_INPUT_VARIABLES = {
  firstName: Variables.BorrowerDirectorOneFirstName,
  phone: Variables.BorrowerDirectorOnePhoneNumber,
  middleName: Variables.BorrowerDirectorOneMiddleName,
  lastName: Variables.BorrowerDirectorOneLastName,
  dateOfBirth: Variables.BorrowerDirectorOneDateOfBirth,
  bankVerificationNumber: Variables.BorrowerDirectorOneBankVerificationNumber,
  email: Variables.BorrowerDirectorOneEmailAddress,
  residentialAddress: Variables.BorrowerDirectorOneResidentialAddress,
  documentType: Variables.BorrowerDirectorOneIdDocumentType,
  bvnImage: Variables.BorrowerDirectorOneBvnImage,
  nationalIdNumber: Variables.BorrowerDirectorOneNationalIdNumber,
  virtualNationalIdNumber: Variables.BorrowerDirectorOneVirtualNationalIdNumber,
} as Record<string, keyof TFirstDirectorData>;

export const SECOND_DIRECTOR_FORM_INPUT_VARIABLES = {
  firstName: Variables.BorrowerDirectorTwoFirstName,
  phone: Variables.BorrowerDirectorTwoPhoneNumber,
  middleName: Variables.BorrowerDirectorTwoMiddleName,
  lastName: Variables.BorrowerDirectorTwoLastName,
  dateOfBirth: Variables.BorrowerDirectorTwoDateOfBirth,
  bankVerificationNumber: Variables.BorrowerDirectorTwoBankVerificationNumber,
  email: Variables.BorrowerDirectorTwoEmailAddress,
  residentialAddress: Variables.BorrowerDirectorTwoResidentialAddress,
  documentType: Variables.BorrowerDirectorTwoIdDocumentType,
  bvnImage: Variables.BorrowerDirectorTwoBvnImage,
  nationalIdNumber: Variables.BorrowerDirectorTwoNationalIdNumber,
  virtualNationalIdNumber: Variables.BorrowerDirectorTwoVirtualNationalIdNumber,
} as Record<string, keyof TSecondDirectorData>;

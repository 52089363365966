import { FC, PropsWithChildren } from 'react';

export type FCWithChildren<P = {}> = FC<PropsWithChildren<P>>;

export type VariableValue = number | string | boolean | null | undefined | File[] | File;

export type TOptionalString = string | undefined;

export enum BorrowerType {
  Person = 'person',
  Company = 'company',
}

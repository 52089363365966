import React from 'react';
import { TBankAccountData } from 'pages/SubmitLoanApplication/types';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';
import BankAccountForm from 'components/BankAccountForm';

interface IProductInformationProps {
  data: TBankAccountData;
  onNextStep: (step: SubmitLoanApplicationSteps) => void;
  onPrevStep: (step: SubmitLoanApplicationSteps) => void;
  onDataUpdate: (data: TBankAccountData) => void;
}

const BankAccount = ({ data, onNextStep, onPrevStep, onDataUpdate }: IProductInformationProps) => {
  const handleSubmit = (productInformation: TBankAccountData) => {
    onDataUpdate(productInformation);

    onNextStep(SubmitLoanApplicationSteps.UploadDocument);
  };

  const handlePrevStep = () => {
    onPrevStep(SubmitLoanApplicationSteps.ProductInformation);
  };

  return <BankAccountForm data={data} onPrevStep={handlePrevStep} onSubmit={handleSubmit} />;
};

export default BankAccount;

import React, { FC } from 'react';
import styles from './Table.module.scss';
import { DocumentIcon } from 'static/images';
import { VariableValue } from 'types/common';

export type TTableDataValue = {
  isFile: boolean;
  value: VariableValue;
};

interface ITable {
  data: Record<string, TTableDataValue>;
  title?: string;
}

const Table: FC<ITable> = ({ data, title }) => {
  return (
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      {Object.keys(data).map((key) => {
        if (data[key].value) {
          return (
            <div key={key} className={styles.row}>
              <div className={styles.name}>{key}</div>
              <div className={styles.value}>
                {data[key].isFile && <DocumentIcon className={styles.icon} />}
                {data[key].value}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Table;

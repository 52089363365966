import * as Yup from 'yup';

import { DocumentsUploadFormVariables } from 'enums/DocumentsUploadFormVariables';
import { InputLabels } from 'enums/InputLabels';
import { getRequiredFileInputErrorMessage } from 'helpers/validation';

export const commonDocumentsUploadFormValidationSchema = Yup.object().shape({
  [DocumentsUploadFormVariables.PurchaseOrder]: getRequiredFileInputErrorMessage(
    InputLabels.PurchaseOrder,
  ),
  [DocumentsUploadFormVariables.ContractAgreement]: getRequiredFileInputErrorMessage(
    InputLabels.ContractAgreement,
  ),
  [DocumentsUploadFormVariables.Invoice]: getRequiredFileInputErrorMessage(InputLabels.Invoice),
  [DocumentsUploadFormVariables.FinancialStatement]: getRequiredFileInputErrorMessage(
    InputLabels.FinancialStatement,
  ),
  [DocumentsUploadFormVariables.StatementOfAccount]: getRequiredFileInputErrorMessage(
    InputLabels.StatementOfAccount,
  ),
  [DocumentsUploadFormVariables.CompanyOrManagementProfile]: getRequiredFileInputErrorMessage(
    InputLabels.CompanyOrManagementProfile,
  ),
  [DocumentsUploadFormVariables.PreviouslyExecutedContracts]: getRequiredFileInputErrorMessage(
    InputLabels.PreviouslyExecutedContracts,
  ),
});

export const workingCapitalDocumentsUploadFormValidationSchema = Yup.object().shape({
  [DocumentsUploadFormVariables.ProFormaInvoice]: getRequiredFileInputErrorMessage(
    InputLabels.ProFormaInvoice,
  ),
  [DocumentsUploadFormVariables.FinancialStatement]: getRequiredFileInputErrorMessage(
    InputLabels.FinancialStatement,
  ),
  [DocumentsUploadFormVariables.BankStatement]: getRequiredFileInputErrorMessage(
    InputLabels.BankStatement,
  ),
  [DocumentsUploadFormVariables.CompanyOrManagementProfile]: getRequiredFileInputErrorMessage(
    InputLabels.CompanyOrManagementProfile,
  ),
  [DocumentsUploadFormVariables.PreviouslyExecutedContracts]: getRequiredFileInputErrorMessage(
    InputLabels.PreviouslyExecutedContracts,
  ),
  [DocumentsUploadFormVariables.FormalApplicationOrBoardResolution]:
    getRequiredFileInputErrorMessage(InputLabels.FormalApplicationOrBoardResolution),
  [DocumentsUploadFormVariables.CashFlowProjection]: getRequiredFileInputErrorMessage(
    InputLabels.CashFlowProjection,
  ),
});

export const invoiceDiscountingDocumentsUploadFormValidationSchema = Yup.object().shape({
  [DocumentsUploadFormVariables.InvoiceStampedByPrincipal]: getRequiredFileInputErrorMessage(
    InputLabels.InvoiceStampedByPrincipal,
  ),
  [DocumentsUploadFormVariables.FinancialStatement]: getRequiredFileInputErrorMessage(
    InputLabels.FinancialStatement,
  ),
  [DocumentsUploadFormVariables.CompanyOrManagementProfile]: getRequiredFileInputErrorMessage(
    InputLabels.CompanyOrManagementProfile,
  ),
  [DocumentsUploadFormVariables.EvidenceOfPastSupplies]: getRequiredFileInputErrorMessage(
    InputLabels.EvidenceOfPastSupplies,
  ),
  [DocumentsUploadFormVariables.FormalApplicationOrBoardResolution]:
    getRequiredFileInputErrorMessage(InputLabels.FormalApplicationOrBoardResolution),
  [DocumentsUploadFormVariables.CopyOfDeliveryNote]: getRequiredFileInputErrorMessage(
    InputLabels.CopyOfDeliveryNote,
  ),
  [DocumentsUploadFormVariables.BankStatement]: getRequiredFileInputErrorMessage(
    InputLabels.BankStatement,
  ),
});

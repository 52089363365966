import Button from 'components/Button';
import { IButtonProps } from 'components/Button/Button';
import React, { FC } from 'react';
import { ArrowRight } from 'static/images';
import styles from './NextButton.module.scss';

const DEFAULT_TITLE = 'Next';

const NextButton: FC<IButtonProps> = ({ title = DEFAULT_TITLE, ...props }) => {
  const buttonContent = (
    <div className={styles.buttonContent}>
      {title}
      <ArrowRight />
    </div>
  );

  return <Button {...props}>{buttonContent}</Button>;
};

export default NextButton;

import {
  CommodityFinanceIcon,
  InventoryFinancingIcon,
  InvoiceDiscountingIcon,
  PrincipalTradingIcon,
  WorkingCapitalIcon,
} from 'static/images';

import CreditProductButton from 'components/CreditProductButton';
import { CreditProducts } from 'enums/CreditProducts';
import NextButton from 'components/NextButton';
import React from 'react';
import styles from './CreditProductsForm.module.scss';

interface ICreditProductsFormProps {
  selectProduct: (product: CreditProducts) => void;
  selectedCreditProduct: CreditProducts | null;
  handleNextStep: () => void;
}

const creditProductsIconMap: Record<CreditProducts, React.ReactElement> = {
  [CreditProducts.CommodityFinancing]: <CommodityFinanceIcon />,
  [CreditProducts.InventoryFinancing]: <InventoryFinancingIcon />,
  [CreditProducts.PrincipalTrading]: <PrincipalTradingIcon />,
  [CreditProducts.WorkingCapital]: <WorkingCapitalIcon />,
  [CreditProducts.InvoiceDiscounting]: <InvoiceDiscountingIcon />,
};

const creditProductsDescriptionMap: Record<CreditProducts, string> = {
  [CreditProducts.CommodityFinancing]:
    'Need help financing the aggregation, transportation, or processing of agricultural grains, solid minerals for export or local trade? Our Commodity Finance helps to provide short-term facilities to a range of actors in the value chain, including Processors, Aggregators, Commodity traders and Exporters.',
  [CreditProducts.InventoryFinancing]:
    'Need to stock up on inventory but short on cash? Our Inventory financing product is a short-term facility that helps businesses purchase inventory, such as finished goods, or raw materials to meet immediate customer or seasonal demands.',
  [CreditProducts.PrincipalTrading]:
    'Do you want us to be your aggregator and supplier of your product directly? Our Principal Trading is the short term aggregation and supply of the direct contracted product pending payment.',
  [CreditProducts.WorkingCapital]:
    'Our working capital financing product is designed to support businesses to meet their short-term liquidity and operational requirements.',
  [CreditProducts.InvoiceDiscounting]:
    'Get paid faster with Invoice Discounting. Turn unpaid invoices into immediate cash to cover expenses, invest in growth, or simply improve your cash flow.',
};

const CreditProductsForm = ({
  selectProduct,
  selectedCreditProduct,
  handleNextStep,
}: ICreditProductsFormProps) => {
  return (
    <div className={styles.wrapper}>
      {Object.values(CreditProducts).map((product) => (
        <CreditProductButton
          onClick={() => selectProduct(product)}
          isSelected={selectedCreditProduct === product}
          productName={product}
          icon={creditProductsIconMap[product]}
          key={product}
          description={creditProductsDescriptionMap[product]}
        />
      ))}
      <div className={styles.buttonContainer}>
        <NextButton onClick={handleNextStep} disabled={!selectedCreditProduct} />
      </div>
    </div>
  );
};

export default CreditProductsForm;

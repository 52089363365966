import * as Yup from 'yup';

import { MAX_L0AN_AMOUNT_VALUE, ProductTypes } from './constants';
import {
  getDateValidationRules,
  getEmailValidationRules,
  getOptionalEmailValidationRules,
  getOptionalStringValidationRules,
  getRequiredStringValidationRules,
} from 'helpers/validation';

import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import { convertMonetaryValueToIntegerNumber } from 'helpers/formatters';

export const commonProductInformationFormValidationSchema = Yup.object().shape({
  [Variables.ProductTypeList]: getRequiredStringValidationRules(InputLabels.ProductType),
  [Variables.ProductTypeOther]: Yup.string().when(Variables.ProductTypeList, {
    is: (productType: ProductTypes) => productType === ProductTypes.Other,
    then: () => getRequiredStringValidationRules(InputLabels.OtherProductType),
    otherwise: () => Yup.string(),
  }),
  [Variables.TransactionType]: getRequiredStringValidationRules(InputLabels.TransactionType),
  [Variables.ProductList]: Yup.string().when(Variables.ProductTypeList, {
    is: (productType: ProductTypes) => productType !== ProductTypes.Other,
    then: () => getRequiredStringValidationRules(InputLabels.Product),
    otherwise: () => Yup.string(),
  }),
  [Variables.ProductOther]: Yup.string().when([Variables.ProductList, Variables.ProductTypeList], {
    is: (product: string, productType: string) => product === 'Other' || productType === 'Other',
    then: () => getRequiredStringValidationRules(InputLabels.OtherProduct),
    otherwise: () => Yup.string(),
  }),
  [Variables.ProductUnits]: getRequiredStringValidationRules(InputLabels.ProductUnits),
  [Variables.ProductQuantity]: getRequiredStringValidationRules(InputLabels.ProductQuantity),
  [Variables.ProductUnitPurchasePrice]: getRequiredStringValidationRules(
    InputLabels.ProductUnitPurchasePrice,
  ),
  [Variables.ProductUnitSellingPrice]: getRequiredStringValidationRules(
    InputLabels.ProductUnitSellingPrice,
  ),
  [Variables.LogisticsCostPerTruck]: getRequiredStringValidationRules(
    InputLabels.LogisticsCostPerTruck,
  ),
  [Variables.LogisticsNumberOfTrucks]: getRequiredStringValidationRules(
    InputLabels.LogisticsNumberOfTrucks,
  ),
  [Variables.LoanAmount]: getRequiredStringValidationRules(InputLabels.RequestAmount).test(
    'isLoanAmountInRange',
    'The value entered is outside the allowable range',
    (value) => {
      const numberValue = convertMonetaryValueToIntegerNumber(value)!;

      return numberValue <= MAX_L0AN_AMOUNT_VALUE;
    },
  ),
  [Variables.SupplierAddress]: getRequiredStringValidationRules(InputLabels.SupplierAddress),
  [Variables.PaymentTenorInDays]: getRequiredStringValidationRules(InputLabels.PaymentTenorDays),
  [Variables.OfftakerName]: getRequiredStringValidationRules(InputLabels.OfftakersName),
  [Variables.OfftakerAddress]: getRequiredStringValidationRules(InputLabels.OfftakersAddress),
  [Variables.OfftakerEmail]: getEmailValidationRules(InputLabels.OfftakersEmail),
});

export const workingCapitalProductInformationFormValidationSchema = Yup.object().shape({
  [Variables.LoanAmount]: getRequiredStringValidationRules(InputLabels.RequestAmount).test(
    'isLoanAmountInRange',
    'The value entered is outside the allowable range',
    (value) => {
      const numberValue = convertMonetaryValueToIntegerNumber(value)!;

      return numberValue <= MAX_L0AN_AMOUNT_VALUE;
    },
  ),
  [Variables.PaymentTenorInDays]: getRequiredStringValidationRules(InputLabels.PaymentTenorDays),
  [Variables.FacilityPurpose]: getRequiredStringValidationRules(InputLabels.FacilityPurpose),
});

export const invoiceDiscountingProductInformationFormValidationSchema = Yup.object().shape({
  [Variables.LoanAmount]: getRequiredStringValidationRules(InputLabels.RequestAmount).test(
    'isLoanAmountInRange',
    'The value entered is outside the allowable range',
    (value) => {
      const numberValue = convertMonetaryValueToIntegerNumber(value)!;

      return numberValue <= MAX_L0AN_AMOUNT_VALUE;
    },
  ),
  [Variables.SupplierAddress]: getOptionalStringValidationRules(),
  [Variables.PaymentTenorInDays]: getRequiredStringValidationRules(InputLabels.PaymentTenorDays),
  [Variables.OfftakerName]: getOptionalStringValidationRules(),
  [Variables.OfftakerAddress]: getOptionalStringValidationRules(),
  [Variables.OfftakerEmail]: getOptionalEmailValidationRules(InputLabels.OfftakersEmail),
  [Variables.DateInvoice]: getDateValidationRules(InputLabels.DateInvoice),
});

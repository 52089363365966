import React, { FC } from 'react';
import { AccordionItem as Item, AccordionItemProps } from '@szhsin/react-accordion';
import { ArrowDown } from 'static/images';
import styles from './AccordionItem.module.scss';

const AccordionItem: FC<AccordionItemProps> = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <ArrowDown className={styles.icon} />
      </>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) => `${styles.itemBtn} ${isEnter && styles.expanded}`,
    }}
    contentProps={{ className: styles.itemContent }}
  />
);

export default AccordionItem;

import { DEFAULT_CURRENCY } from 'helpers/constants';
import { Formik } from 'formik';
import Input from 'components/Input';
import { InputLabels } from 'enums/InputLabels';
import { MAX_L0AN_AMOUNT_VALUE } from './constants';
import NextButton from 'components/NextButton';
import NumberInput from 'components/NumberInput';
import PrevButton from 'components/PrevButton';
import { TInvoiceDiscountingProductInformationData } from 'pages/SubmitLoanApplication/types';
import { Variables } from 'enums/Variables';
import { invoiceDiscountingProductInformationFormValidationSchema } from './validationSchema';
import styles from './ProductInformationForm.module.scss';
import DateInput from 'components/DateInput';

interface IInvoiceDiscountingProductInformationFormProps {
  data: TInvoiceDiscountingProductInformationData;
  onSubmit: (values: TInvoiceDiscountingProductInformationData) => void;
  onPrevStep: () => void;
}

const InvoiceDiscountingProductInformationForm = ({
  data,
  onSubmit,
  onPrevStep,
}: IInvoiceDiscountingProductInformationFormProps) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={invoiceDiscountingProductInformationFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, handleBlur, submitForm }) => {
        const getErrorMessage = (name: keyof TInvoiceDiscountingProductInformationData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleNextClick = async () => {
          await submitForm();
        };

        return (
          <>
            <div className={styles.container}>
              <div className={styles.title}>Product Information</div>
              <NumberInput
                label={InputLabels.RequestAmount}
                value={values[Variables.LoanAmount]}
                onChange={handleChange}
                thousandSeparator
                prefix={DEFAULT_CURRENCY}
                name={Variables.LoanAmount}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.LoanAmount)}
                max={MAX_L0AN_AMOUNT_VALUE}
              />
              <Input
                label={InputLabels.SupplierAddress}
                value={values[Variables.SupplierAddress]}
                onChange={handleChange}
                name={Variables.SupplierAddress}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.SupplierAddress)}
              />
              <NumberInput
                label={InputLabels.PaymentTenorDays}
                value={values[Variables.PaymentTenorInDays]}
                onChange={handleChange}
                name={Variables.PaymentTenorInDays}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.PaymentTenorInDays)}
              />
              <Input
                label={InputLabels.OfftakersName}
                value={values[Variables.OfftakerName]}
                onChange={handleChange}
                name={Variables.OfftakerName}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OfftakerName)}
              />
              <Input
                label={InputLabels.OfftakersAddress}
                value={values[Variables.OfftakerAddress]}
                onChange={handleChange}
                name={Variables.OfftakerAddress}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OfftakerAddress)}
              />
              <Input
                label={InputLabels.OfftakersEmail}
                value={values[Variables.OfftakerEmail]}
                onChange={handleChange}
                name={Variables.OfftakerEmail}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OfftakerEmail)}
              />
              <DateInput
                label={InputLabels.DateInvoice}
                value={values[Variables.DateInvoice]}
                onChange={handleChange}
                onBlur={handleBlur}
                name={Variables.DateInvoice}
                error={getErrorMessage(Variables.DateInvoice)}
              />
            </div>

            <div className={styles.buttonContainer}>
              <PrevButton onClick={onPrevStep} />

              <NextButton onClick={handleNextClick} />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default InvoiceDiscountingProductInformationForm;

import React, { useContext } from 'react';

import { ApiContext } from 'providers/ApiProvider';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import Loader from 'components/Loader';
import { SecretKeys } from 'enums/SecretKeys';
import { getSecretValue } from 'helpers/getSecretValue';
import styles from './Redirect.module.scss';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const Redirect = () => {
  const { instance } = useMsal();
  const acc = instance.getActiveAccount();
  const { applicationsApi } = useContext(ApiContext);

  const hasAcc = !!acc;
  const navigate = useNavigate();

  const handleRedirect = async () => {
    try {
      const getNavigationPath = async () => {
        const [verificationApplication] = await applicationsApi.find(
          acc!.localAccountId,
          getSecretValue(SecretKeys.BorrowerVerificationProductId),
        );

        if (verificationApplication?.borrowerId) {
          const loanApplications = await applicationsApi.find(
            acc!.localAccountId,
            getSecretValue(SecretKeys.BusinessLoanProductId),
          );

          if (loanApplications.length) {
            return ApplicationRoutes.Applications;
          }

          const verificationApplications = await applicationsApi.find(
            acc!.localAccountId,
            getSecretValue(SecretKeys.BorrowerVerificationProductId),
          );

          if (verificationApplications.length) {
            if (
              verificationApplications[0].status.id ===
              getSecretValue(SecretKeys.BorrowerVerificationProductApprovedStatusId)
            ) {
              return ApplicationRoutes.Applications;
            }

            return ApplicationRoutes.Verification;
          }
        }

        return ApplicationRoutes.CreateAccount;
      };

      const path = await getNavigationPath();

      navigate(path);
    } catch {
      navigate(ApplicationRoutes.SomethingWentWrong);
    }
  };

  useEffect(() => {
    if (hasAcc) {
      handleRedirect();
    }
  }, [hasAcc]);

  return (
    <div className={styles.container}>
      <Loader />
    </div>
  );
};

export default Redirect;

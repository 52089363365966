export enum DocumentsUploadFormVariables {
  PurchaseOrder = 'purchaseOrder',
  ContractAgreement = 'contractAgreement',
  Invoice = 'invoice',
  FinancialStatement = 'financialStatement',
  StatementOfAccount = 'statementOfAccount',
  CompanyOrManagementProfile = 'companyOrManagementProfile',
  PreviouslyExecutedContracts = 'previouslyExecutedContracts',
  // Working Capital
  ProFormaInvoice = 'proFormaInvoice',
  BankStatement = 'bankStatement',
  FormalApplicationOrBoardResolution = 'formalApplicationOrBoardResolution',
  CashFlowProjection = 'cashFlowProjection',
  // Invoice Discounting
  InvoiceStampedByPrincipal = 'invoiceStampedByPrincipal',
  EvidenceOfPastSupplies = 'evidenceOfPastSupplies',
  CopyOfDeliveryNote = 'copyOfDeliveryNote',
}

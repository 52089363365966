import React from 'react';
import { Formik } from 'formik';
import Input from 'components/Input';
import NextButton from 'components/NextButton';
import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import PrevButton from 'components/PrevButton';
import { companyInformationFormValidationSchema } from './validationSchema';
import NumberInput from 'components/NumberInput';
import { TBankAccountData } from 'pages/SubmitLoanApplication/types';
import styles from './BankAccountForm.module.scss';

interface IBankAccountFormProps {
  data: TBankAccountData;
  onPrevStep: () => void;
  onSubmit: (values: TBankAccountData) => void;
}

const BankAccountForm = ({ data, onPrevStep, onSubmit }: IBankAccountFormProps) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={companyInformationFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({ values, touched, errors, handleChange, handleBlur, submitForm, isValid }) => {
        const getErrorMessage = (name: keyof TBankAccountData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleNextClick = async () => {
          await submitForm();
        };

        return (
          <>
            <div className={styles.container}>
              <div className={styles.title}>Bank Account</div>
              <Input
                label={InputLabels.BankAccountName}
                value={values[Variables.BankAccountName]}
                onChange={handleChange}
                name={Variables.BankAccountName}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BankAccountName)}
              />
              <NumberInput
                label={InputLabels.BankAccountNumber}
                value={values[Variables.BankAccountNumber]}
                onChange={handleChange}
                name={Variables.BankAccountNumber}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BankAccountNumber)}
              />
              <Input
                label={InputLabels.BankName}
                value={values[Variables.BankName]}
                onChange={handleChange}
                name={Variables.BankName}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BankName)}
              />
            </div>

            <div className={styles.buttonsContainer}>
              <PrevButton onClick={onPrevStep} />
              <NextButton
                className={styles.nextButton}
                disabled={!isValid}
                onClick={handleNextClick}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default BankAccountForm;

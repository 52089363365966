import clsx from 'clsx';
import Input from 'components/Input';
import NumberInput from 'components/NumberInput';
import { InputLabels } from 'enums/InputLabels';
import React, { ChangeEvent, FocusEventHandler } from 'react';
import styles from './ContactInfoFields.module.scss';

interface IContactInfoFieldsProps {
  onChange: (event: ChangeEvent) => void;
  onBlur: FocusEventHandler;
  phoneInputName: string;
  emailInputName: string;
  phoneNumberValue: string;
  emailValue: string;
  phoneInputErrorMessage?: string;
  emailInputErrorMessage?: string;
  disableInputs?: boolean;
}

const PHONE_NUMBER_INPUT_FORMAT = '###-###-#####';
const PHONE_NUMBER_INPUT_MASK = 'X';
const PHONE_NUMBER_INPUT_PLACEHOLDER = 'XXX-XXX-XXXXX';

const ContactInfoFields = ({
  onChange,
  onBlur,
  emailInputName,
  phoneInputName,
  phoneInputErrorMessage,
  emailInputErrorMessage,
  phoneNumberValue,
  emailValue,
  disableInputs,
}: IContactInfoFieldsProps) => {
  return (
    <div className={styles.container}>
      <Input
        label={InputLabels.CorrespondentEmailAddress}
        className={styles.inputWidth}
        onChange={onChange}
        onBlur={onBlur}
        name={emailInputName}
        error={emailInputErrorMessage}
        value={emailValue}
        disabled={disableInputs}
      />
      <NumberInput
        label={InputLabels.PhoneNumber}
        className={clsx(styles.inputWidth, styles.phoneNumberPlaceholder)}
        maxLength={14}
        format={PHONE_NUMBER_INPUT_FORMAT}
        mask={PHONE_NUMBER_INPUT_MASK}
        placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
        name={phoneInputName}
        onChange={onChange}
        onBlur={onBlur}
        error={phoneInputErrorMessage}
        value={phoneNumberValue}
        allowNegative={false}
        disabled={disableInputs}
      />
    </div>
  );
};

export default ContactInfoFields;

import { VerificationPageContentSections } from 'enums/VerificationPageContentSections';
import { VariableValue } from 'types/common';
import {
  LABELS_TO_INPUTS_COMPANY_INFORMATION,
  LABELS_TO_INPUTS_DIRECTOR_ONE_INFORMATION,
  LABELS_TO_INPUTS_DIRECTOR_TWO_INFORMATION,
  LABELS_TO_INPUTS_RESPRESENTATIVE_INFORMATION,
  VARIABLES_TO_FILENAMES,
} from './constants';
import { TTableDataValue } from 'components/Table/Table';
import { Variables } from 'enums/Variables';

const getLabelAndVariablesFromSectionName = (
  isRepresentativeExists: boolean,
  isFirstRepresentative: boolean,
  sectionName?: VerificationPageContentSections,
) => {
  if (sectionName === VerificationPageContentSections.CompanyInformation) {
    return LABELS_TO_INPUTS_COMPANY_INFORMATION;
  }

  if (sectionName === VerificationPageContentSections.DirectorsInformation) {
    return LABELS_TO_INPUTS_DIRECTOR_ONE_INFORMATION;
  }

  if (sectionName === VerificationPageContentSections.RepresentativeInformation) {
    if (isRepresentativeExists) {
      return LABELS_TO_INPUTS_RESPRESENTATIVE_INFORMATION;
    } else {
      return isFirstRepresentative
        ? LABELS_TO_INPUTS_DIRECTOR_ONE_INFORMATION
        : LABELS_TO_INPUTS_DIRECTOR_TWO_INFORMATION;
    }
  }

  return LABELS_TO_INPUTS_DIRECTOR_TWO_INFORMATION;
};

const getFormattedVariableValue = (variableName: string, value: VariableValue): TTableDataValue => {
  return {
    isFile: !!VARIABLES_TO_FILENAMES[variableName],
    value: VARIABLES_TO_FILENAMES[variableName] || value,
  };
};

export const getContentFromApplicationData = (
  variables: Record<string, VariableValue>,
  sectionName?: VerificationPageContentSections,
): Record<string, TTableDataValue> => {
  const isRepresentativeExists = variables[
    Variables.BorrowerCompanyRepresentativeExists
  ] as boolean;

  const isFirstRepresentative = variables[Variables.BorrowerDirectorOneIsRepresentative] as boolean;

  const mappedLabelsToInputs: Record<string, string> = getLabelAndVariablesFromSectionName(
    isRepresentativeExists,
    isFirstRepresentative,
    sectionName,
  );

  const formattedContent: Record<string, TTableDataValue> = {};

  Object.keys(mappedLabelsToInputs).map((label) => {
    const variableName = mappedLabelsToInputs[label];

    return (formattedContent[label] = getFormattedVariableValue(
      variableName,
      variables[variableName],
    ));
  });

  return formattedContent;
};

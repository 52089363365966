import Button, { IButtonProps } from 'components/Button/Button';
import React, { FC } from 'react';
import { ArrowLeft } from 'static/images';
import styles from './PrevButton.module.scss';

const DEFAULT_TITLE = 'Previous Step';

const PrevButton: FC<IButtonProps> = ({ title = DEFAULT_TITLE, ...props }) => {
  const buttonContent = (
    <div className={styles.buttonContent}>
      <ArrowLeft />
      <span>{title}</span>
    </div>
  );

  return (
    <Button {...props} className={styles.button}>
      {buttonContent}
    </Button>
  );
};

export default PrevButton;

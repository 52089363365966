import {
  convertToMonetary,
  convertToPercentage,
  formatApplicationId,
  formatIsoDate,
  termFormatter,
} from 'helpers/formatters';

import { BusinessLoanProductApplicationStatuses } from 'enums/BusinessLoanProductApplicationStatuses';
import BusinessLoanStatusLabel from 'components/BusinessLoanStatusLabel';
import { CreditProducts } from 'enums/CreditProducts';
import React from 'react';
import styles from './ApplicationCard.module.scss';

interface IApplicationCardProps {
  id: string;
  displayId: string;
  status: BusinessLoanProductApplicationStatuses;
  loanRate: number;
  loanTerm: number;
  createdAt: string;
  loanAmount: number;
  creditProduct: CreditProducts;
  onClick: () => void;
}

const LOAN_AMOUNT_FRACTION_DIGITS = 0;
const EMPTY_VALUE = '-';

const ApplicationCard = ({
  loanAmount,
  createdAt,
  status,
  loanRate,
  loanTerm,
  displayId,
  onClick,
  creditProduct,
}: IApplicationCardProps) => {
  const getLoanRateDisplayValue = () => {
    return loanRate ? convertToPercentage(loanRate) : EMPTY_VALUE;
  };

  const getLoanTermDisplayValue = () => {
    return loanTerm ? termFormatter(loanTerm) : EMPTY_VALUE;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>{formatApplicationId(displayId)}</span>
        <span>{convertToMonetary(loanAmount, LOAN_AMOUNT_FRACTION_DIGITS)}</span>
      </div>
      <div className={styles.statusInfo}>
        <span>{formatIsoDate(createdAt)}</span>
        <BusinessLoanStatusLabel statusName={status} />
      </div>
      <div className={styles.loanInfo}>
        <div>Credit Product</div>
        <div>{creditProduct}</div>
      </div>
      <div className={styles.loanInfo}>
        <div>Margin</div>
        <div>{getLoanRateDisplayValue()}</div>
      </div>
      <div className={styles.loanInfo}>
        <div>Payment Tenor</div>
        <div>{getLoanTermDisplayValue()}</div>
      </div>

      <div className={styles.button} onClick={onClick}>
        <span>View details</span>
      </div>
    </div>
  );
};

export default ApplicationCard;

import Popup from 'components/Popup';
import React, { useState } from 'react';
import { CloseIcon } from 'static/images';
import styles from './FileUploadPopup.module.scss';
import accepts from 'attr-accept';
import {
  ACCEPTED_FILE_FORMATS,
  MAX_FILE_SIZE,
  MIN_FILE_SIZE,
  getFileNames,
  renameFile,
} from 'helpers/files';
import DraggableField from './DraggableField';
import FilesList from './FilesList';
import Button from 'components/Button';
import Recommendations from './Recommendations';
import { getFileNameByVariable } from 'helpers/getFileNameByVariable';
import { Variables } from 'enums/Variables';

interface IFileUploadPopupProps {
  title: string;
  onFileChange: (files: File[]) => void;
  onCLose: () => void;
  name: string;
  files: File[];
  limit?: number | false;
}

const INPUT_ID = 'fileInput';
const BUTTON_TITLE = 'Upload File';

const FileUploadPopup = ({
  title,
  onFileChange,
  onCLose,
  name,
  files: derivedFiles,
  limit = 1,
}: IFileUploadPopupProps) => {
  const [isError, setIsError] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [files, setFiles] = useState<File[]>(derivedFiles);

  const validateFilesSize = (currentFiles: File[]) => {
    return currentFiles.every(
      (currentFile) => currentFile.size <= MAX_FILE_SIZE && currentFile.size >= MIN_FILE_SIZE,
    );
  };

  const validateFiles = (currentFiles: File[]) => {
    const isAcceptable = currentFiles.every((currentFile) =>
      accepts(currentFile, ACCEPTED_FILE_FORMATS),
    );

    if (!isAcceptable || !validateFilesSize(currentFiles)) {
      setIsError(true);
      return;
    }

    if (isError) {
      setIsError(false);
    }

    const filesToUpload = limit
      ? [...files, ...currentFiles].slice(0, limit)
      : [...files, ...currentFiles];

    setFiles(filesToUpload);
  };

  const renameFiles = (currentFiles: File[]) => {
    const newFiles = currentFiles.map((file) => {
      const newFileName = getFileNameByVariable(name as Variables);

      return renameFile(file, newFileName);
    });

    return newFiles;
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentFiles = Array.from(e.target!.files!);
    const renamedFiles = renameFiles(currentFiles);
    validateFiles(renamedFiles);
  };

  const handleFileDrag = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const currentFiles = Array.from(e.dataTransfer.files);
    const renamedFiles = renameFiles(currentFiles);

    validateFiles(renamedFiles);
    setIsDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(false);
  };

  const fileNames = getFileNames(files);

  const handleFileRemove = (fileName: string) => {
    const newFilesList = files.filter((file) => file.name !== fileName);
    setFiles(newFilesList);
  };

  const handleButtonClick = () => {
    if (isError) {
      return;
    }

    onFileChange(files);
    onCLose();
  };

  return (
    <Popup>
      <div className={styles.popupContent}>
        <div className={styles.header}>
          <span>{title}</span>
          <CloseIcon onClick={onCLose} />
        </div>
        <div className={styles.body}>
          <DraggableField
            id={INPUT_ID}
            isDragOver={isDragOver}
            isError={isError}
            onFileDrag={handleFileDrag}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onUploadFile={handleUploadFile}
            limit={limit}
          />
          {Boolean(fileNames.length) && (
            <FilesList fileNames={fileNames} onFileRemove={handleFileRemove} />
          )}
          <Recommendations />
        </div>
        <div className={styles.controls}>
          <Button onClick={handleButtonClick}>{BUTTON_TITLE}</Button>
        </div>
      </div>
    </Popup>
  );
};

export default FileUploadPopup;

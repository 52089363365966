import React, { useRef, useState } from 'react';
import { TFirstDirectorData, TSecondDirectorData } from 'pages/CreateAccount/types';
import { CreateAccountSteps } from 'enums/CreateAccountSteps';
import NextButton from 'components/NextButton';
import PrevButton from 'components/PrevButton';
import { FormikProps } from 'formik';
import PersonalInformationForm from 'components/PersonalInformationForm';
import {
  firstDirectorInformationValidationSchema,
  secondDirectorInformationValidationSchema,
} from './validationSchemas';
import styles from 'pages/CreateAccount/CreateAccount.module.scss';
import {
  DEFAULT_DIRECTOR_TWO_INFORMATION_VALUES,
  FIRST_DIRECTOR_FORM_INPUT_VARIABLES,
  SECOND_DIRECTOR_FORM_INPUT_VARIABLES,
} from 'pages/CreateAccount/constants';
import DirectorFormHeaderContent from 'components/DirectorFormHeaderContent';

import { ExpansionList, ExpansionPanel, usePanels } from '@react-md/expansion-panel';

interface IDirectorsInformationProps {
  directorOneData: TFirstDirectorData;
  directorTwoData: TSecondDirectorData;
  onNextStep: (step: CreateAccountSteps) => void;
  onPrevStep: (step: CreateAccountSteps) => void;
  onDirectorOneDataUpdate: (data: TFirstDirectorData) => void;
  onDirectorTwoDataUpdate: (data: TSecondDirectorData) => void;
}

const DirectorsInformation = ({
  directorOneData,
  directorTwoData,
  onDirectorOneDataUpdate,
  onDirectorTwoDataUpdate,
  onNextStep,
  onPrevStep,
}: IDirectorsInformationProps) => {
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'simple-panels',
    count: 2,
    defaultExpandedIndex: 0,
  });

  const [panel1Props, panel2Props] = panels;

  const [isDirectorTwoFormOpen, setIsDirectorTwoFormOpen] = useState(false);
  const [isDirectorOneFormOpen, setIsDirectorOneFormOpen] = useState(true);

  const directorOneFormRef = useRef<FormikProps<Partial<TFirstDirectorData>>>(null);
  const directorTwoFormRef = useRef<FormikProps<Partial<TSecondDirectorData>>>(null);

  const handleNextClick = async () => {
    if (isDirectorOneFormOpen) {
      await directorOneFormRef.current?.submitForm();
      const isFirstDirectorFormValid = directorOneFormRef.current?.isValid;

      if (!isFirstDirectorFormValid) {
        return;
      }

      onDirectorOneDataUpdate(directorOneFormRef!.current!.values as TFirstDirectorData);
    }

    if (isDirectorTwoFormOpen) {
      await directorTwoFormRef.current?.submitForm();

      const isSecondDirectorFormValid = directorTwoFormRef.current?.isValid;

      if (!isSecondDirectorFormValid) {
        return;
      }

      onDirectorTwoDataUpdate(directorTwoFormRef!.current!.values as TSecondDirectorData);
    }

    if (isDirectorTwoFormOpen) {
    }

    onNextStep(CreateAccountSteps.RepresentativeInformation);
  };

  const handleDirectorTwoFormExpand = async () => {
    await directorOneFormRef.current?.submitForm();

    const isFirstDirectorFormValid = directorOneFormRef.current?.isValid;

    if (!isFirstDirectorFormValid) {
      return;
    }

    onDirectorOneDataUpdate(directorOneFormRef!.current!.values as TFirstDirectorData);
    setIsDirectorOneFormOpen(false);
    setIsDirectorTwoFormOpen(true);
  };

  const handleDirectorTwoFormCollapse = async () => {
    await directorTwoFormRef.current?.submitForm();
    const isSecondDirectorFormValid = directorTwoFormRef.current?.isValid;

    if (!isSecondDirectorFormValid) {
      return;
    }

    onDirectorTwoDataUpdate(directorTwoFormRef!.current!.values as TSecondDirectorData);
    setIsDirectorTwoFormOpen(false);
  };

  const handleDeleteDirectorTwoData = () => {
    onDirectorTwoDataUpdate(DEFAULT_DIRECTOR_TWO_INFORMATION_VALUES);
    setIsDirectorTwoFormOpen(false);
  };

  const handleDirectorOneFormCollapse = async () => {
    await directorOneFormRef.current?.submitForm();
    const isFirstDirectorFormValid = directorOneFormRef.current?.isValid;

    if (!isFirstDirectorFormValid) {
      return;
    }

    setIsDirectorOneFormOpen(false);
  };

  return (
    <>
      <ExpansionList onKeyDown={onKeyDown}>
        <ExpansionPanel
          {...panel1Props}
          expanded={isDirectorOneFormOpen}
          persistent
          contentClassName={styles.transition}
          customHeader={
            <DirectorFormHeaderContent
              isFormOpen={isDirectorOneFormOpen}
              onExpandForm={() => setIsDirectorOneFormOpen(true)}
              onCollapseForm={handleDirectorOneFormCollapse}
              name="Director 1"
            />
          }
          disablePadding
        >
          <PersonalInformationForm
            data={directorOneData}
            formRef={directorOneFormRef}
            validationSchema={firstDirectorInformationValidationSchema}
            variablesMap={FIRST_DIRECTOR_FORM_INPUT_VARIABLES}
            key="Director 1"
          />
        </ExpansionPanel>
        <ExpansionPanel
          {...panel2Props}
          expanded={isDirectorTwoFormOpen}
          contentClassName={styles.transition}
          customHeader={
            <DirectorFormHeaderContent
              isFormOpen={isDirectorTwoFormOpen}
              onExpandForm={handleDirectorTwoFormExpand}
              onCollapseForm={handleDirectorTwoFormCollapse}
              name="Director 2"
              onDelete={handleDeleteDirectorTwoData}
            />
          }
          disablePadding
        >
          <PersonalInformationForm
            data={directorTwoData}
            formRef={directorTwoFormRef}
            validationSchema={secondDirectorInformationValidationSchema}
            variablesMap={SECOND_DIRECTOR_FORM_INPUT_VARIABLES}
            key="Director 2"
          />
        </ExpansionPanel>
      </ExpansionList>
      <div className={styles.buttonsContainer}>
        <PrevButton onClick={() => onPrevStep(CreateAccountSteps.CompanyInformation)} />
        <NextButton onClick={handleNextClick} />
      </div>
    </>
  );
};

export default DirectorsInformation;

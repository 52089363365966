import { CreditProducts } from 'enums/CreditProducts';
import CreditProductsForm from 'components/CreditProductsForm';
import React from 'react';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';

interface ISelectCreditProductProps {
  selectedCreditProduct: CreditProducts | null;
  selectProduct: (product: CreditProducts) => void;
  onNextStep: (nextStep: SubmitLoanApplicationSteps) => void;
}

const SelectCreditProduct = ({
  selectProduct,
  selectedCreditProduct,
  onNextStep,
}: ISelectCreditProductProps) => {
  return (
    <CreditProductsForm
      selectProduct={selectProduct}
      selectedCreditProduct={selectedCreditProduct}
      handleNextStep={() => onNextStep(SubmitLoanApplicationSteps.ProductInformation)}
    />
  );
};

export default SelectCreditProduct;

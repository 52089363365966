import { getFileFormat, getFileName } from 'helpers/files';
import React from 'react';
import { CloseIcon, FileIcon } from 'static/images';
import styles from './FilesList.module.scss';

interface IFileItemProps {
  fileName: string;
  onFileRemove: (fileName: string) => void;
}

const FileItem = ({ fileName, onFileRemove }: IFileItemProps) => {
  return (
    <div className={styles.fileItemContainer}>
      <FileIcon />
      <div className={styles.fileData}>
        <div className={styles.fileName}>{getFileName(fileName)}</div>
        <div className={styles.fileFormat}>{getFileFormat(fileName)}</div>
      </div>
      <CloseIcon onClick={() => onFileRemove(fileName)} className={styles.closeIcon} />
    </div>
  );
};

interface IFilesListProps {
  fileNames: string[];
  onFileRemove: (fileName: string) => void;
}

const FilesList = ({ fileNames, onFileRemove }: IFilesListProps) => {
  return (
    <div className={styles.container}>
      {fileNames.map((fileName, key) => (
        <FileItem key={fileName + key} onFileRemove={onFileRemove} fileName={fileName} />
      ))}
    </div>
  );
};

export default FilesList;

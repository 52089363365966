import PersonalInformationForm from 'components/PersonalInformationForm';
import { CreateAccountSteps } from 'enums/CreateAccountSteps';
import React, { useRef } from 'react';
import { CompanyRepresentativeInputValue, TRepresentativeData } from 'pages/CreateAccount/types';
import SelectInput from 'components/SelectInput';
import PrevButton from 'components/PrevButton';
import NextButton from 'components/NextButton';
import styles from 'pages/CreateAccount/CreateAccount.module.scss';
import { representativeInformationValidationSchema } from './validationSchemas';
import { REPRESENTATIVE_DIRECTOR_FORM_INPUT_VARIABLES } from 'pages/CreateAccount/constants';
import { FormikProps } from 'formik';
import { getSelectInputOptionsFromValueArray } from 'helpers/getSelectInputOptionsFromValueArray';
import { getSelectedOptionByValue } from 'helpers/getSelectedOptionByValue';
import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';

interface IRepresentativeInformationProps {
  data: TRepresentativeData;
  onPrevStep: (step: CreateAccountSteps) => void;
  onAccountCreate: (data: TRepresentativeData) => void;
  companyRepresentativeValues: string[];
  selectedCompanyRepresentativeValue: CompanyRepresentativeInputValue | null;
  setSelectedCompanyRepresentativeValue: (value: CompanyRepresentativeInputValue) => void;
  isCompanyRepresentativeInputError: boolean;
  setIsCompanyRepresentativeInputError: (value: boolean) => void;
}

const RepresentativeInformation = ({
  data,
  companyRepresentativeValues,
  selectedCompanyRepresentativeValue,
  setSelectedCompanyRepresentativeValue,
  onAccountCreate,
  onPrevStep,
}: IRepresentativeInformationProps) => {
  const formRef = useRef<FormikProps<Partial<TRepresentativeData>>>(null);

  const handleCreateAccount = async () => {
    await formRef.current?.submitForm();
    if (selectedCompanyRepresentativeValue === CompanyRepresentativeInputValue.Other) {
      const isFirstDirectorFormValid = formRef.current?.isValid;

      if (!isFirstDirectorFormValid) {
        return;
      }

      onAccountCreate(formRef!.current!.values as TRepresentativeData);

      return;
    }

    onAccountCreate(data);
  };

  const companyRepresentativeOptions = getSelectInputOptionsFromValueArray(
    companyRepresentativeValues,
  );

  const disableInputs = [
    CompanyRepresentativeInputValue.DirectorOne,
    CompanyRepresentativeInputValue.DirectorTwo,
  ].includes(selectedCompanyRepresentativeValue as CompanyRepresentativeInputValue);

  return (
    <>
      <SelectInput
        options={companyRepresentativeOptions}
        selectedOption={getSelectedOptionByValue(
          companyRepresentativeOptions,
          selectedCompanyRepresentativeValue,
        )}
        onValuePicked={(value) => {
          setSelectedCompanyRepresentativeValue(value as CompanyRepresentativeInputValue);
        }}
        placeholder={InputLabels.CompanyRepresentative}
        name={Variables.BorrowerCompanyRepresentative}
        className={styles.representativeInputMarginBottom}
      />
      <PersonalInformationForm
        data={data}
        formRef={formRef}
        validationSchema={representativeInformationValidationSchema}
        variablesMap={REPRESENTATIVE_DIRECTOR_FORM_INPUT_VARIABLES}
        disableInputs={disableInputs}
      />
      <div className={styles.buttonsContainer}>
        <PrevButton onClick={() => onPrevStep(CreateAccountSteps.DirectorsInformation)} />
        <NextButton title="Create Account" onClick={handleCreateAccount} />
      </div>
    </>
  );
};

export default RepresentativeInformation;

import {
  TCommonUploadDocumentData,
  TInvoiceDiscountingUploadDocumentData,
  TUploadDocumentData,
  TWorkingCapitalUploadDocumentData,
} from 'pages/SubmitLoanApplication/types';

import CommonDocumentsUploadForm from './CommonDocumentsUploadForm';
import { CreditProducts } from 'enums/CreditProducts';
import InvoiceDiscountingDocumentsUploadForm from './InvoiceDiscountingDocumentsUploadForm';
import WorkingCapitalDocumentsUploadForm from './WorkingCapitalDocumentsUploadForm';

interface IDocumentsUploadFormProps {
  data: TUploadDocumentData;
  selectedCreditProduct: CreditProducts;
  onPrevStep: () => void;
  onSubmit: (data: TUploadDocumentData) => void;
}

const DocumentsUploadForm = ({
  data,
  onPrevStep,
  onSubmit,
  selectedCreditProduct,
}: IDocumentsUploadFormProps) => {
  const renderUploadDocumentsFormByCreditProduct = () => {
    switch (selectedCreditProduct) {
      case CreditProducts.CommodityFinancing:
      case CreditProducts.InventoryFinancing:
      case CreditProducts.PrincipalTrading:
        return (
          <CommonDocumentsUploadForm
            data={data as TCommonUploadDocumentData}
            onPrevStep={onPrevStep}
            onSubmit={onSubmit}
          />
        );
      case CreditProducts.WorkingCapital:
        return (
          <WorkingCapitalDocumentsUploadForm
            data={data as TWorkingCapitalUploadDocumentData}
            onPrevStep={onPrevStep}
            onSubmit={onSubmit}
          />
        );
      case CreditProducts.InvoiceDiscounting:
        return (
          <InvoiceDiscountingDocumentsUploadForm
            onPrevStep={onPrevStep}
            onSubmit={onSubmit}
            data={data as TInvoiceDiscountingUploadDocumentData}
          />
        );
      default:
        return null as never;
    }
  };

  return renderUploadDocumentsFormByCreditProduct();
};

export default DocumentsUploadForm;

import clsx from 'clsx';
import ErrorMessage from 'components/ErrorMessage';
import FileUploadPopup from 'components/FileUploadPopup';
import React, { useState } from 'react';
import { CloseIcon, Upload } from 'static/images';
import styles from './FileUploadInput.module.scss';
import { TOptionalString } from 'types/common';
import { getFileNames } from 'helpers/files';

interface IFileUploadInputProps {
  label: string;
  onFileChange: (value: File[]) => void;
  error: TOptionalString;
  name: string;
  files: File[];
  disabled?: boolean;
  limit?: number | false;
}

const FileUploadInput = ({
  label,
  onFileChange,
  error,
  files,
  disabled,
  name,
  limit,
}: IFileUploadInputProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileNames = getFileNames(files);

  const hasValues = !!fileNames.length;
  const hasError = !!error;

  const labelCLassName = clsx(
    hasValues ? styles.labelFloated : styles.label,
    hasError && styles.labelError,
  );

  const containerClassName = clsx(
    styles.container,
    hasError && styles.containerError,
    disabled && styles.disabledContainer,
  );

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const inputValue = fileNames.join(', ');

  return (
    <>
      <div className={styles.wrapper}>
        <div className={containerClassName}>
          <span className={labelCLassName}>{label}</span>
          <span className={styles.valuesContainer} onClick={handleOpenPopup}>
            {hasValues && <span>{inputValue}</span>}
          </span>
          {hasValues ? (
            <CloseIcon onClick={() => onFileChange([])} className={styles.closeIcon} />
          ) : (
            <span className={styles.upload} onClick={handleOpenPopup}>
              <Upload />
              <span>Upload</span>
            </span>
          )}
        </div>
        {hasError && <ErrorMessage message={error} />}
      </div>

      {isPopupOpen && (
        <FileUploadPopup
          files={files}
          title={label}
          onFileChange={onFileChange}
          onCLose={handleClosePopup}
          name={name}
          limit={limit}
        />
      )}
    </>
  );
};

export default FileUploadInput;

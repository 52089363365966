import AuthorizationInformationForm from 'components/AuthorizationInformationForm';
import React from 'react';
import { TAuthorizationData } from 'pages/CreateAccount/types';

interface IAuthorizationInformationProps {
  data: TAuthorizationData;
  onNextStep: (data: TAuthorizationData) => Promise<void>;
}

const AuthorizationInformation = ({ data, onNextStep }: IAuthorizationInformationProps) => {
  return <AuthorizationInformationForm data={data} onSubmit={onNextStep} />;
};

export default AuthorizationInformation;

export enum BusinessLoanProductApplicationStatuses {
  CreditAssessment = 'Credit Assessment',
  HeadCreditApproval = 'Head Credit Approval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  NewApplication = 'New Application',
  CreditReview = 'Credit Review',
  ChiefCommercialOfficerApproval = 'Chief Commercial Officer Approval',
  DirectorFinancialServicesApproval = 'Director Financial Services Approval',
  ExecutionOfPreDisbursementDocuments = 'Execution of Pre Disbursement Documents',
  DisbursementOfFunds = 'Disbursement of Funds',
  CollateralManagerVisit = 'Collateral Manager Visit',
}

export enum BusinessLoanProductApplicationLabels {
  ApplicationSubmitted = 'Application Submitted',
  CreditReview = 'Credit Review',
  CreditAssessment = 'Credit Assessment',
  CreditApproval = 'Credit Approval',
  SigningOfPreDisbursmentDocuments = 'Signing of Pre Disbursment Documents',
  CollateralManagerVisit = 'Collateral Manager Visit',
  DisbursementOfFunds = 'Disbursement of Funds',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

import { Variables } from 'enums/Variables';

export type TAuthorizationData = {
  [Variables.BorrowerCompanyName]: string;
  [Variables.BorrowerCompanyRegistrationNumber]: string;
  [Variables.BorrowerDirectorOneFirstName]: string;
  [Variables.BorrowerDirectorOneLastName]: string;
};

export type TCompanyData = {
  [Variables.BorrowerCompanyName]: string;
  [Variables.BorrowerCompanyRegistrationNumber]: string;
  [Variables.BorrowerRegisteredBusinessAddress]: string;
  [Variables.BorrowerCountry]: string;
  [Variables.BorrowerBusinessType]: string;
  [Variables.BorrowerNatureOfBusiness]: string;
  [Variables.BorrowerOperatingAddress]: string;
  [Variables.BorrowerEmail]: string;
  [Variables.BorrowerPhoneNumber]: string;
  [Variables.BorrowerTin]: string;
};

export type TFirstDirectorData = {
  [Variables.BorrowerDirectorOneFirstName]: string;
  [Variables.BorrowerDirectorOneMiddleName]: string;
  [Variables.BorrowerDirectorOneLastName]: string;
  [Variables.BorrowerDirectorOneDateOfBirth]: string;
  [Variables.BorrowerDirectorOneBankVerificationNumber]: string;
  [Variables.BorrowerDirectorOneEmailAddress]: string;
  [Variables.BorrowerDirectorOnePhoneNumber]: string;
  [Variables.BorrowerDirectorOneResidentialAddress]: string;
  [Variables.BorrowerDirectorOneIdDocumentType]: string;
  [Variables.BorrowerDirectorOneNationalIdNumber]: string;
  [Variables.BorrowerDirectorOneBvnImage]: File[];
  [Variables.BorrowerDirectorOneVirtualNationalIdNumber]: string;
};

export type TSecondDirectorData = {
  [Variables.BorrowerDirectorTwoFirstName]: string;
  [Variables.BorrowerDirectorTwoMiddleName]: string;
  [Variables.BorrowerDirectorTwoLastName]: string;
  [Variables.BorrowerDirectorTwoDateOfBirth]: string;
  [Variables.BorrowerDirectorTwoBankVerificationNumber]: string;
  [Variables.BorrowerDirectorTwoEmailAddress]: string;
  [Variables.BorrowerDirectorTwoPhoneNumber]: string;
  [Variables.BorrowerDirectorTwoResidentialAddress]: string;
  [Variables.BorrowerDirectorTwoIdDocumentType]: string;
  [Variables.BorrowerDirectorTwoNationalIdNumber]: string;
  [Variables.BorrowerDirectorTwoBvnImage]: File[];
  [Variables.BorrowerDirectorTwoVirtualNationalIdNumber]: string;
};

export type TRepresentativeData = {
  [Variables.BorrowerCompanyRepresentativeFirstName]: string;
  [Variables.BorrowerCompanyRepresentativeMiddleName]: string;
  [Variables.BorrowerCompanyRepresentativeLastName]: string;
  [Variables.BorrowerCompanyRepresentativeDateOfBirth]: '';
  [Variables.BorrowerCompanyRepresentativeBvn]: string;
  [Variables.BorrowerCompanyRepresentativeEmail]: string;
  [Variables.BorrowerCompanyRepresentativePhoneNumber]: string;
  [Variables.BorrowerCompanyRepresentativeResidentialAddress]: string;
  [Variables.BorrowerCompanyRepresentativeIdDocumentType]: string;
  [Variables.BorrowerCompanyRepresentativeBvnImage]: File[];
  [Variables.BorrowerCompanyRepresentativeNationalIdNumber]: string;
  [Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber]: string;
};

export type TCreateAccountData =
  | TCompanyData
  | TFirstDirectorData
  | TSecondDirectorData
  | TRepresentativeData
  | TAuthorizationData;

export enum CompanyRepresentativeInputValue {
  DirectorOne = 'Director 1',
  DirectorTwo = 'Director 2',
  Other = 'Other Authorized Representative',
}

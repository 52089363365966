import React from 'react';
import { AcceptIcon } from 'static/images';
import styles from './Recommendations.module.scss';
import { ACCEPTED_FILE_FORMATS } from 'helpers/files';

const RECOMMENDATION_LIST = [
  'The following file formats are accepted: ' + ACCEPTED_FILE_FORMATS,
  'The maximum file size is 1.5MB',
];

const Recommendations = () => {
  return (
    <div className={styles.container}>
      {RECOMMENDATION_LIST.map((recommendation, index) => {
        return (
          <div className={styles.recommendation} key={index}>
            <AcceptIcon />
            <span>{recommendation}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Recommendations;

import React, { useState, FocusEvent } from 'react';
import { PatternFormat } from 'react-number-format';
import InputWrapper, { IInputWrapperProps } from 'components/InputWrapper/InputWrapper';
import styles from './DateInput.module.scss';
import clsx from 'clsx';

const DATE_INPUT_FORMAT = '##/##/####';
const DATE_INPUT_MASK = ['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y'];
const DATE_INPUT_PLACEHOLDER = 'DD/MM/YYYY';

const DateInput = ({
  error,
  label,
  value,
  onChange,
  name,
  onBlur,
  disabled,
}: IInputWrapperProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    setFocused(false);
  };

  return (
    <InputWrapper
      error={error}
      label={label}
      className={clsx(focused && styles.placeholderOpacity)}
    >
      <PatternFormat
        format={DATE_INPUT_FORMAT}
        mask={DATE_INPUT_MASK}
        value={value as string}
        onChange={onChange}
        placeholder={DATE_INPUT_PLACEHOLDER}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
      />
    </InputWrapper>
  );
};

export default DateInput;

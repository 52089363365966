import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './StatusLabel.module.scss';
import { CheckmarkWhite } from 'static/images';
import { StatusLabelList } from 'enums/StatusLabels';

interface IStatusLabel {
  type: StatusLabelList;
  className?: string;
  isTransparent?: boolean;
  label?: string;
}

const mapStyleToType = {
  [StatusLabelList.InProgress]: styles.inProgress,
  [StatusLabelList.Verified]: styles.verified,
  [StatusLabelList.Rejected]: styles.rejected,
};

const StatusLabel: FC<IStatusLabel> = ({ className, isTransparent = false, type, label }) => {
  return (
    <>
      <div
        className={clsx(className, styles.container, mapStyleToType[type], {
          [styles.transparent]: isTransparent,
        })}
      >
        {type === StatusLabelList.Verified && <CheckmarkWhite className={styles.icon} />}
        {label || type}
      </div>
    </>
  );
};

export default StatusLabel;

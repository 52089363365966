import { CreditProducts } from 'enums/CreditProducts';
import React from 'react';
import clsx from 'clsx';
import styles from './CreditProductButton.module.scss';

interface ICreditProductButtonProps {
  onClick: () => void;
  productName: CreditProducts;
  isSelected: boolean;
  icon: React.ReactElement;
  description: string;
}

const CreditProductButton = ({
  onClick,
  productName,
  icon,
  isSelected,
  description,
}: ICreditProductButtonProps) => {
  return (
    <button className={clsx(styles.button, isSelected && styles.active)} onClick={onClick}>
      {icon}
      <div>
        <h5 className={styles.productName}>{productName}</h5>
        <p className={styles.productDescription}>{description}</p>
      </div>
    </button>
  );
};

export default CreditProductButton;

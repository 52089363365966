import { SecretKeys } from 'enums/SecretKeys';

const getAzureClientIdSecret = () => {
  return process.env[SecretKeys.AzureClientId]!;
};

export const getAzureRedirectUri = () => {
  return process.env[SecretKeys.AzureRedirectUri]!;
};

export const getAzureClientConfiguration = () => {
  const clientId = getAzureClientIdSecret();
  const tenantName = process.env[SecretKeys.AzureClientTenantName]!;
  const redirectUri = getAzureRedirectUri();
  const policy = process.env[SecretKeys.AzureClientPolicy]!;

  const b2cLoginUrl = `${tenantName}.b2clogin.com`;

  const authority = `https://${b2cLoginUrl}/${tenantName}.onmicrosoft.com/${policy}`;

  return {
    auth: {
      clientId,
      authority,
      redirectUri,
      knownAuthorities: [b2cLoginUrl],
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };
};

export const getAzureRequestData = () => {
  return {
    scopes: [getAzureClientIdSecret()],
  };
};

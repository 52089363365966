export enum InputLabels {
  Country = 'Country',
  RegisteredBusinessName = 'Registered Business Name',
  RcNumber = 'RC Number',
  RegisteredBusinessAddress = 'Registered Business Address',
  BusinessType = 'Business Type',
  NatureOfBusiness = 'Nature of Business',
  OperatingAddress = 'Operating Address',
  CorrespondentEmailAddress = 'Correspondent Email Address',
  PhoneNumber = 'Phone Number',
  TaxIdentificationNumber = 'Tax Identification Number',
  FirstName = 'First Name',
  MiddleName = 'Middle Name',
  LastName = 'Last Name',
  DateOfBirth = 'Date of Birth',
  DateInvoice = 'Date Invoice was issued',
  BankVerificationNumber = 'Bank Verification Number',
  EmailAddress = 'Email Address',
  ResidentialAddress = 'Residential Address',
  NationalIdType = 'National ID Type',
  PassportPhoto = 'Passport Photo',
  NationalIdNumber = 'National ID Number',
  CompanyRepresentative = 'Company Representative',
  ProductType = 'Product Type',
  OtherProductType = 'Other Product Type',
  TransactionType = 'Transaction Type',
  Product = 'Product',
  OtherProduct = 'Other Product',
  ProductUnits = 'Product Units',
  ProductQuantity = 'Product Quantity',
  ProductUnitPurchasePrice = 'Product Unit Purchase Price',
  ProductUnitSellingPrice = 'Product Unit Selling Price',
  LogisticsCostPerTruck = 'Logistics Cost Per Truck',
  LogisticsNumberOfTrucks = 'Logistics Number of Trucks',
  OtherCostsAmount = 'Other Costs Amount',
  OtherCostsDescription = 'Other Costs Description',
  RequestAmount = 'Request Amount',
  SupplierAddress = 'Supplier Address',
  PaymentTenorDays = 'Payment Tenor (days)',
  OfftakersName = 'Offtaker’s Name',
  OfftakersAddress = 'Offtaker’s Address',
  OfftakersEmail = 'Offtaker’s Email',
  BankAccountName = 'Bank Account Name',
  BankAccountNumber = 'Bank Account Number',
  BankName = 'Bank Name',
  PurchaseOrder = 'Purchase Order',
  ContractAgreement = 'Trade Contract Agreement',
  Invoice = 'Invoice',
  FinancialStatement = 'Audited Financial Statement',
  StatementOfAccount = '12-month Statement of Account',
  CompanyOrManagementProfile = 'Company or Management Profile',
  PreviouslyExecutedContracts = 'Previously Executed Contracts',
  VirtualNationalIdNumber = 'Virtual National ID Number',

  // Working Capital
  ProFormaInvoice = 'Pro-Forma Invoice',
  BankStatement = 'Bank Statement (6 - 12 months)',
  FormalApplicationOrBoardResolution = 'Formal Application/Board Resolution',
  CashFlowProjection = 'Cash Flow Projection (with assumption)',
  FacilityPurpose = 'Facility Purpose',

  // Invoice Discounting
  InvoiceStampedByPrincipal = 'Invoice Stamped by Principal ',
  EvidenceOfPastSupplies = 'Evidence of Past Supplies',
  CopyOfDeliveryNote = 'Copy of Delivery Note',
}

import { FileNames } from 'enums/FileNames';
import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';

export const LABELS_TO_INPUTS_COMPANY_INFORMATION = {
  [InputLabels.RegisteredBusinessName]: Variables.BorrowerCompanyName,
  [InputLabels.RcNumber]: Variables.BorrowerCompanyRegistrationNumber,
  [InputLabels.TaxIdentificationNumber]: Variables.BorrowerTin,
  [InputLabels.BusinessType]: Variables.BorrowerBusinessType,
  [InputLabels.NatureOfBusiness]: Variables.BorrowerNatureOfBusiness,
  [InputLabels.RegisteredBusinessAddress]: Variables.BorrowerRegisteredBusinessAddress,
  [InputLabels.OperatingAddress]: Variables.BorrowerOperatingAddress,
  [InputLabels.Country]: Variables.BorrowerCountry,
  [InputLabels.CorrespondentEmailAddress]: Variables.BorrowerEmail,
  [InputLabels.PhoneNumber]: Variables.BorrowerPhoneNumber,
};

export const LABELS_TO_INPUTS_DIRECTOR_ONE_INFORMATION = {
  [InputLabels.FirstName]: Variables.BorrowerDirectorOneFirstName,
  [InputLabels.MiddleName]: Variables.BorrowerDirectorOneMiddleName,
  [InputLabels.LastName]: Variables.BorrowerDirectorOneLastName,
  [InputLabels.DateOfBirth]: Variables.BorrowerDirectorOneDateOfBirth,
  [InputLabels.BankVerificationNumber]: Variables.BorrowerDirectorOneBankVerificationNumber,
  [InputLabels.EmailAddress]: Variables.BorrowerDirectorOneEmailAddress,
  [InputLabels.PhoneNumber]: Variables.BorrowerDirectorOnePhoneNumber,
  [InputLabels.ResidentialAddress]: Variables.BorrowerDirectorOneResidentialAddress,
  [InputLabels.NationalIdType]: Variables.BorrowerDirectorOneIdDocumentType,
  [InputLabels.PassportPhoto]: Variables.BorrowerDirectorOneBvnImage,
  [InputLabels.NationalIdNumber]: Variables.BorrowerDirectorOneNationalIdNumber,
  [InputLabels.VirtualNationalIdNumber]: Variables.BorrowerDirectorOneVirtualNationalIdNumber,
};

export const LABELS_TO_INPUTS_DIRECTOR_TWO_INFORMATION = {
  [InputLabels.FirstName]: Variables.BorrowerDirectorTwoFirstName,
  [InputLabels.MiddleName]: Variables.BorrowerDirectorTwoMiddleName,
  [InputLabels.LastName]: Variables.BorrowerDirectorTwoLastName,
  [InputLabels.DateOfBirth]: Variables.BorrowerDirectorTwoDateOfBirth,
  [InputLabels.BankVerificationNumber]: Variables.BorrowerDirectorTwoBankVerificationNumber,
  [InputLabels.EmailAddress]: Variables.BorrowerDirectorTwoEmailAddress,
  [InputLabels.PhoneNumber]: Variables.BorrowerDirectorTwoPhoneNumber,
  [InputLabels.ResidentialAddress]: Variables.BorrowerDirectorTwoResidentialAddress,
  [InputLabels.NationalIdType]: Variables.BorrowerDirectorTwoIdDocumentType,
  [InputLabels.PassportPhoto]: Variables.BorrowerDirectorTwoBvnImage,
  [InputLabels.NationalIdNumber]: Variables.BorrowerDirectorTwoNationalIdNumber,
  [InputLabels.VirtualNationalIdNumber]: Variables.BorrowerDirectorTwoVirtualNationalIdNumber,
};

export const LABELS_TO_INPUTS_RESPRESENTATIVE_INFORMATION = {
  [InputLabels.FirstName]: Variables.BorrowerCompanyRepresentativeFirstName,
  [InputLabels.MiddleName]: Variables.BorrowerCompanyRepresentativeMiddleName,
  [InputLabels.LastName]: Variables.BorrowerCompanyRepresentativeLastName,
  [InputLabels.DateOfBirth]: Variables.BorrowerCompanyRepresentativeDateOfBirth,
  [InputLabels.BankVerificationNumber]: Variables.BorrowerCompanyRepresentativeBvn,
  [InputLabels.EmailAddress]: Variables.BorrowerCompanyRepresentativeEmail,
  [InputLabels.PhoneNumber]: Variables.BorrowerCompanyRepresentativePhoneNumber,
  [InputLabels.ResidentialAddress]: Variables.BorrowerCompanyRepresentativeResidentialAddress,
  [InputLabels.NationalIdType]: Variables.BorrowerCompanyRepresentativeIdDocumentType,
  [InputLabels.PassportPhoto]: Variables.BorrowerCompanyRepresentativeBvnImage,
  [InputLabels.NationalIdNumber]: Variables.BorrowerCompanyRepresentativeNationalIdNumber,
  [InputLabels.VirtualNationalIdNumber]:
    Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber,
};

export const VARIABLES_TO_FILENAMES: Record<string, string> = {
  [Variables.BorrowerDirectorOneBvnImage]: FileNames.DirectorOnePassportPhoto,
  [Variables.BorrowerDirectorTwoBvnImage]: FileNames.DirectorTwoPassportPhoto,
  [Variables.BorrowerCompanyRepresentativeBvnImage]: FileNames.CompanyRepresentativePassportPhoto,
};

import ErrorLayout from 'components/ErrorLayout/ErrorLayout';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TITLE = '400';
const SUBTITLE = 'Ooops! Borrower is Locked';
const DESCRIPTION = 'Please, contact Sabi to clarify the details';

const LockedBorrower = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ApplicationRoutes.Welcome);
  };

  return (
    <ErrorLayout
      onClick={handleClick}
      title={TITLE}
      subTitle={SUBTITLE}
      description={DESCRIPTION}
    />
  );
};

export default LockedBorrower;

import ApiProvider from 'providers/ApiProvider';
import { AuthProvider } from 'providers/AuthProvider';
import React from 'react';
import AppRoutes from './routes';
import {
  PublicClientApplication,
  EventType,
  SsoSilentRequest,
  EventMessage,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getAzureClientConfiguration } from 'helpers/getAzureClientConfiguration';

export const msalConfig = getAzureClientConfiguration();

export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    (event.payload as SsoSilentRequest).account
  ) {
    msalInstance.setActiveAccount((event.payload as SsoSilentRequest).account!);
  }
});

const App = () => {
  return (
    <ApiProvider>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MsalProvider>
    </ApiProvider>
  );
};

export default App;

import { StatusLabelList } from 'enums/StatusLabels';
import { createContext, useState } from 'react';

interface IVerificationApplicationProviderProps {
  setVerificationApplicationStatus: (status: StatusLabelList) => void;
  verificationStatus?: StatusLabelList;
}

export const VerificationApplicationContext = createContext<IVerificationApplicationProviderProps>(
  {} as IVerificationApplicationProviderProps,
);

export const VerificationApplicationProvider = ({ children }: { children: any }) => {
  const [verificationStatus, setVerificationStatus] = useState<StatusLabelList | undefined>();

  const setVerificationApplicationStatus = (newStatus: StatusLabelList) => {
    setVerificationStatus(newStatus);
  };

  const verificationApplicationProviderValues = {
    setVerificationApplicationStatus,
    verificationStatus,
  };

  return (
    <VerificationApplicationContext.Provider value={verificationApplicationProviderValues}>
      {children}
    </VerificationApplicationContext.Provider>
  );
};

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion } from '@szhsin/react-accordion';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import { NAVIGATION_WITH_ACCOUNT_DATA } from 'helpers/constants';
import StatusLabel from 'components/StatusLabel/StatusLabel';
import { StatusLabelList } from 'enums/StatusLabels';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import Table from 'components/Table';
import { ApiContext } from 'providers/ApiProvider';
import { VariableValue } from 'types/common';
import { getContentFromApplicationData } from './helpers';
import { VerificationPageContentSections } from 'enums/VerificationPageContentSections';
import { useMsal } from '@azure/msal-react';
import { Variables } from 'enums/Variables';
import AccordionItem from 'components/AccordionItem';
import { getSecretValue } from 'helpers/getSecretValue';
import { SecretKeys } from 'enums/SecretKeys';
import { VerificationApplicationContext } from 'providers/VerificationApplicationProvider';

const PAGE_TITLE = 'Verification';

const DIRECTOR_ONE_TITLE = 'Director 1';
const DIRECTOR_TWO_TITLE = 'Director 2';

const FETCH_DATA_INTERVAL = 30000;

const Verification = () => {
  const { instance } = useMsal();

  const { setVerificationApplicationStatus, verificationStatus } = useContext(
    VerificationApplicationContext,
  );

  const { applicationsApi } = useContext(ApiContext);

  const [applicationData, setApplicationData] = useState<Record<string, VariableValue>>({});
  const [loading, setLoading] = useState(false);

  const acc = instance.getActiveAccount();
  const azureId = acc?.localAccountId;

  const intervalIdRef = useRef<NodeJS.Timeout>();

  const navigate = useNavigate();

  const getApplicationData = async () => {
    if (azureId) {
      const [application] = await applicationsApi.find(
        azureId,
        getSecretValue(SecretKeys.BorrowerVerificationProductId),
      );

      const status = application.variables[Variables.BorrowerVerificationStatus] as StatusLabelList;
      setApplicationData(application.variables);
      setVerificationApplicationStatus(status);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getApplicationData();
      setLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    intervalIdRef.current = setInterval(async () => {
      await getApplicationData();
    }, FETCH_DATA_INTERVAL);

    return () => clearInterval(intervalIdRef.current);
  }, []);

  useEffect(() => {
    if (
      verificationStatus &&
      [StatusLabelList.Verified, StatusLabelList.Rejected].includes(verificationStatus) &&
      intervalIdRef.current
    ) {
      clearInterval(intervalIdRef.current);
    }
  }, [verificationStatus]);

  const secondDirectorFilled = Boolean(applicationData.borrower_director_2_bvn);

  const navData = useMemo(() => {
    const links = NAVIGATION_WITH_ACCOUNT_DATA.links?.map(({ name, route }) => {
      const isVerificationStep = route === ApplicationRoutes.Verification;

      return {
        name,
        isActive: isVerificationStep,
        status: isVerificationStep && verificationStatus && (
          <StatusLabel isTransparent type={verificationStatus} />
        ),
        route,
        onClick: () => navigate(route),
      };
    });

    return {
      ...NAVIGATION_WITH_ACCOUNT_DATA,
      subLinks: [],
      links,
    };
  }, [verificationStatus]);

  const renderDirectorsSection = () => {
    const section = VerificationPageContentSections.DirectorsInformation;

    return (
      <AccordionItem key={section} header={section}>
        <Table
          title={DIRECTOR_ONE_TITLE}
          data={getContentFromApplicationData(applicationData, section)}
        />
        {secondDirectorFilled && (
          <Table title={DIRECTOR_TWO_TITLE} data={getContentFromApplicationData(applicationData)} />
        )}
      </AccordionItem>
    );
  };

  return (
    <Layout
      navData={navData}
      verificationStatus={verificationStatus}
      loading={loading}
      pageTitle={PAGE_TITLE}
    >
      <Accordion transition transitionTimeout={400}>
        {Object.values(VerificationPageContentSections).map((section, index) => {
          if (section === VerificationPageContentSections.DirectorsInformation) {
            return renderDirectorsSection();
          }

          return (
            <AccordionItem key={section} header={section} initialEntered={index === 0}>
              <Table data={getContentFromApplicationData(applicationData, section)} />
            </AccordionItem>
          );
        })}
      </Accordion>
    </Layout>
  );
};

export default Verification;

import { BUSINESS_LOAN_STATUSES_TO_LABEL, BUSINESS_STATUS_LABEL_TO_STATUS_TYPE } from './constants';
import {
  BusinessLoanProductApplicationLabels,
  BusinessLoanProductApplicationStatuses,
} from 'enums/BusinessLoanProductApplicationStatuses';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Accordion } from '@szhsin/react-accordion';
import AccordionItem from 'components/AccordionItem';
import { ApiContext } from 'providers/ApiProvider';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import Layout from 'components/Layout';
import { NAVIGATION_WITH_ACCOUNT_DATA } from 'helpers/constants';
import { SecretKeys } from 'enums/SecretKeys';
import StatusLabel from 'components/StatusLabel/StatusLabel';
import { StatusLabelList } from 'enums/StatusLabels';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';
import Table from 'components/Table';
import { VariableValue } from 'types/common';
import { Variables } from 'enums/Variables';
import { getContentFromApplicationData } from './helpers';
import { getSecretValue } from 'helpers/getSecretValue';
import { useMsal } from '@azure/msal-react';

const PAGE_TITLE = 'Application View';

const ApplicationView = () => {
  const { instance } = useMsal();

  const { applicationsApi } = useContext(ApiContext);

  const [applicationData, setApplicationData] = useState<Record<string, VariableValue>>({});
  const [applicationStatus, setApplicationStatus] = useState<
    BusinessLoanProductApplicationStatuses | string
  >('');
  const [verificationStatus, setVerificationStatus] = useState<StatusLabelList | null>(null);
  const [loading, setLoading] = useState(false);

  const acc = instance.getActiveAccount();
  const azureId = acc?.localAccountId;

  const navigate = useNavigate();
  const params = useParams();

  const getApplicationData = async () => {
    if (azureId) {
      const [borrowerVerificationApplication] = await applicationsApi.find(
        azureId,
        getSecretValue(SecretKeys.BorrowerVerificationProductId),
      );

      const status = borrowerVerificationApplication.variables[
        Variables.BorrowerVerificationStatus
      ] as StatusLabelList;

      setVerificationStatus(status);

      const registrationNumber = borrowerVerificationApplication.variables[
        Variables.BorrowerCompanyRegistrationNumber
      ] as string;

      const commodityFinancingApplications = await applicationsApi.findByRegistrationNumber(
        registrationNumber,
        getSecretValue(SecretKeys.BusinessLoanProductId),
      );

      const selectedApplication = commodityFinancingApplications.find(
        ({ displayId }) => displayId === params.applicationId,
      );

      if (selectedApplication) {
        setApplicationData(selectedApplication.variables);
        setApplicationStatus(
          selectedApplication.status.name as BusinessLoanProductApplicationStatuses,
        );
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getApplicationData();
      setLoading(false);
    };
    getData();
  }, []);

  const statusLabel: BusinessLoanProductApplicationLabels =
    BUSINESS_LOAN_STATUSES_TO_LABEL[applicationStatus as BusinessLoanProductApplicationStatuses];

  const navData = useMemo(() => {
    const links = NAVIGATION_WITH_ACCOUNT_DATA.links?.map(({ name, route }) => {
      const isApplicationsPage = route === ApplicationRoutes.Applications;
      const isVerificationStep = route === ApplicationRoutes.Verification;

      return {
        name,
        isActive: isApplicationsPage,
        status: isVerificationStep && verificationStatus && (
          <StatusLabel isTransparent type={verificationStatus} />
        ),
        route,
        onClick: () => navigate(route),
      };
    });

    return {
      ...NAVIGATION_WITH_ACCOUNT_DATA,
      subLinks: [],
      links,
    };
  }, [verificationStatus]);

  return (
    <Layout
      backButton
      onBackButtonClick={() => navigate(ApplicationRoutes.Applications)}
      verificationStatus={BUSINESS_STATUS_LABEL_TO_STATUS_TYPE[statusLabel]}
      customStatusLabel={statusLabel}
      navData={navData}
      loading={loading}
      pageTitle={PAGE_TITLE}
    >
      <Accordion transition transitionTimeout={400}>
        {[
          SubmitLoanApplicationSteps.ProductInformation,
          SubmitLoanApplicationSteps.BankAccount,
          SubmitLoanApplicationSteps.UploadDocument,
        ].map((step, index) => (
          <AccordionItem key={step} header={step} initialEntered={index === 0}>
            <Table data={getContentFromApplicationData(applicationData, step)} />
          </AccordionItem>
        ))}
      </Accordion>
    </Layout>
  );
};

export default ApplicationView;

import { useMsal } from '@azure/msal-react';
import { getAzureRedirectUri, getAzureRequestData } from 'helpers/getAzureClientConfiguration';
import { createContext } from 'react';
import { FCWithChildren } from 'types/common';

interface IAuthProviderProps {
  handleAuthorize: () => void;
  handleLogout: () => void;
}

export const AuthContext = createContext<IAuthProviderProps>({} as IAuthProviderProps);

export const AuthProvider: FCWithChildren = ({ children }) => {
  const { instance } = useMsal();

  const handleAuthorize = async () => {
    await instance.loginRedirect({
      ...getAzureRequestData(),
      redirectStartPage: getAzureRedirectUri(),
    });
  };

  const handleLogout = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const authContextValues = {
    handleAuthorize,
    handleLogout,
  };

  return <AuthContext.Provider value={authContextValues}>{children}</AuthContext.Provider>;
};

import { InputLabels } from 'enums/InputLabels';
import * as Yup from 'yup';

export const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;
export const DATE_MATCH_REGEXP = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;
export const PHONE_MATCH_REGEXP = /^0[7-9]{1}[0-9]{1}-[0-9]{3}-[0-9]{5}$/;

const NATIONAL_ID_VALUE_LENGTH = 11;
const PHONE_NUMBER_VALUE_LENGTH = 11;
const BVN_VALUE_LENGTH = 11;
const VIRTUAL_NATIONAL_ID_VALUE_LENGTH = 16;
const BANK_ACCOUNT_NUMBER_VALUE_LENGTH = 10;

const NOT_NIGERIAN_PHONE_NUMBER_ERROR_MESSAGE = 'Must be a Nigerian phone number';
const NATIONAL_ID_LENGTH_ERROR_MESSAGE = 'Must include 11 characters';
const MUST_INCLUDE_ELEVEN_DIGITS_ERROR_MESSAGE = 'Must include 11 digits';
const BVN_MUST_START_WITH_TWO_ERROR_MESSAGE = 'Must start with the digit 2';
const VIRTUAL_NATIONAL_ID_LENGTH_ERROR_MESSAGE = 'Must include 16 characters';
const BANK_ACCOUNT_NUMBER_LENGTH_ERROR_MESSAGE = 'Invalid bank account number';

const getRequiredErrorMessage = (inputLabel: InputLabels) => `The ${inputLabel} is required`;

export const getRequiredStringValidationRules = (inputLabel: InputLabels) => {
  return Yup.string().required(getRequiredErrorMessage(inputLabel));
};

export const getOptionalStringValidationRules = () => {
  return Yup.string().optional();
};

export const getRequiredFileInputErrorMessage = (inputLabel: InputLabels) => {
  return Yup.array().test('isBvnValid', getRequiredErrorMessage(inputLabel), (value) => {
    return !!value?.length;
  });
};

export const getPhoneNumberValidationRules = () => {
  return getRequiredStringValidationRules(InputLabels.PhoneNumber)
    .test('isPhoneValid', MUST_INCLUDE_ELEVEN_DIGITS_ERROR_MESSAGE, (val) =>
      isCompletedPhoneNumber(val),
    )
    .matches(PHONE_MATCH_REGEXP, NOT_NIGERIAN_PHONE_NUMBER_ERROR_MESSAGE);
};

export const getEmailValidationRules = (inputLabel = InputLabels.CorrespondentEmailAddress) => {
  return getRequiredStringValidationRules(inputLabel).matches(
    EMAIL_VALIDATION_REGEX,
    getInvalidErrorMessage(inputLabel),
  );
};

export const getOptionalEmailValidationRules = (
  inputLabel = InputLabels.CorrespondentEmailAddress,
) => {
  return getOptionalStringValidationRules().matches(
    EMAIL_VALIDATION_REGEX,
    getInvalidErrorMessage(inputLabel),
  );
};

export const getDateOfBirthValidationRules = () => {
  return getRequiredStringValidationRules(InputLabels.DateOfBirth).matches(
    DATE_MATCH_REGEXP,
    getInvalidErrorMessage(InputLabels.DateOfBirth),
  );
};

export const getDateValidationRules = (inputLabel: InputLabels) => {
  return getRequiredStringValidationRules(inputLabel).matches(
    DATE_MATCH_REGEXP,
    getInvalidErrorMessage(inputLabel),
  );
};

export const getNationalIdValidationRules = () => {
  return getRequiredStringValidationRules(InputLabels.NationalIdNumber).test(
    'isNationalIdValid',
    NATIONAL_ID_LENGTH_ERROR_MESSAGE,
    (val) => val.length === NATIONAL_ID_VALUE_LENGTH,
  );
};

export const getBankAccountNumberValidationRules = () => {
  return getRequiredStringValidationRules(InputLabels.BankAccountNumber).test(
    'isBankAccountValid',
    BANK_ACCOUNT_NUMBER_LENGTH_ERROR_MESSAGE,
    (val) => val.length === BANK_ACCOUNT_NUMBER_VALUE_LENGTH,
  );
};

export const getVirtualNationalIdValidationRules = () => {
  return getRequiredStringValidationRules(InputLabels.VirtualNationalIdNumber).test(
    'isVirtualNationalIdValid',
    VIRTUAL_NATIONAL_ID_LENGTH_ERROR_MESSAGE,
    (val) => val.length === VIRTUAL_NATIONAL_ID_VALUE_LENGTH,
  );
};

export const getBankVerificationNumberValidationRules = () => {
  return getRequiredStringValidationRules(InputLabels.BankVerificationNumber)
    .test('isBvnStartedWIthTwo', BVN_MUST_START_WITH_TWO_ERROR_MESSAGE, (val) =>
      val.startsWith('2'),
    )
    .test(
      'isBvnValid',
      MUST_INCLUDE_ELEVEN_DIGITS_ERROR_MESSAGE,
      (val) => val.length === BVN_VALUE_LENGTH,
    );
};

export const getInvalidErrorMessage = (fieldName: string) => `Invalid ${fieldName}`;

export const isCompletedPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '').length === PHONE_NUMBER_VALUE_LENGTH;
};

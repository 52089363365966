export enum FileNames {
  DirectorOnePassportPhoto = 'Director 1 Passport Photo',
  DirectorTwoPassportPhoto = 'Director 2 Passport Photo',
  CompanyRepresentativePassportPhoto = 'Company Representative Passport Photo',
  PurchaseOrder = 'Purchase Order',
  TradeContractAgreement = 'Trade Contract Agreement',
  Invoice = 'Invoice',
  AuditedFinancialStatement = 'Audited Financial Statement',
  StatementOfAccount = '12-month Statement of Account',
  CompanyOrManagementProfile = 'Company or Management Profile',
  PreviouslyExecutedContracts = 'Previously Executed Contracts',
  ProFormaInvoice = 'Pro-Forma Invoice',
  BankStatement = 'Bank Statement (6 - 12 months)',
  FormalApplicationOrBoardResolution = 'Formal Application/Board Resolution',
  CashFlowProjection = 'Cash Flow Projection (with assumption)',
  InvoiceStampedByPrincipal = 'Invoice Stamped by Principal ',
  EvidenceOfPastSupplies = 'Evidence of Past Supplies',
  CopyOfDeliveryNote = 'Copy of Delivery Note',
}

import React, { useContext } from 'react';
import WelcomePageForm from 'components/WelcomePageForm';
import WelcomePageWrapper from 'components/WelcomePageWrapper';
import { AuthContext } from 'providers/AuthProvider';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from 'providers/ApiProvider';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';

const WelcomePage = () => {
  const { handleAuthorize } = useContext(AuthContext);
  const isAuth = useIsAuthenticated();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const acc = instance.getActiveAccount();
  const { borrowersApi } = useContext(ApiContext);

  const handleContinue = async () => {
    if (isAuth) {
      const borrower = await borrowersApi.findByAzureUserId(acc!.localAccountId);

      const path = borrower?.id ? ApplicationRoutes.Verification : ApplicationRoutes.CreateAccount;

      navigate(path);
    } else {
      handleAuthorize();
    }
  };

  return (
    <WelcomePageWrapper>
      <WelcomePageForm onSubmit={handleContinue} />
    </WelcomePageWrapper>
  );
};

export default WelcomePage;

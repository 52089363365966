import { FetchMethodType } from 'enums/FetchMethodTypes';
import BaseApi from './BaseApi';

export interface IDocumentsApi {
  upload(applicationId: string, files: File[]): Promise<void>;
}

export default class DocumentsApi extends BaseApi implements IDocumentsApi {
  private endpointBase = '/documents';

  public async upload(applicationId: string, files: File[]): Promise<void> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    formData.append('applicationId', applicationId);

    return this.fetch(`${this.endpointBase}/upload`, {
      method: FetchMethodType.POST,
      body: formData,
      resetDefaultHeaders: true,
    });
  }
}

export const MAX_FILE_SIZE = 1024 * 1024 * 1.5; // 1.5 MB

export const MIN_FILE_SIZE = 1024 * 50; // 50 KB

export const getFileName = (fileName: string): string => fileName.split('.').slice(0, -1).join('.');

export const getFileFormat = (fileName: string): string => fileName.split('.').slice(-1).join();

export const getFileNames = (files: File[]) => {
  return files.map((file) => file.name);
};

export const ACCEPTED_FILE_FORMATS = '.pdf, .png, .jpg, .jpeg';

export const renameFile = (file: File, newName: string) => {
  const fileName = `${newName}.${getFileFormat(file.name)}`;

  return new File([file], fileName, { type: `${file.type}`, lastModified: file.lastModified });
};

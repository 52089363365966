import React from 'react';
import { ArrowDown, DeleteIcon } from 'static/images';
import styles from './DirectorFormHeaderContent.module.scss';
import clsx from 'clsx';

interface IDirectorFormHeaderContentProps {
  isFormOpen: boolean;
  onExpandForm: () => void;
  onCollapseForm: () => void;
  name: string;
  onDelete?: () => void;
}

const DirectorFormHeaderContent = ({
  isFormOpen,
  onExpandForm,
  onCollapseForm,
  name,
  onDelete,
}: IDirectorFormHeaderContentProps) => {
  const isDirectorOne = name === 'Director 1';

  return isFormOpen ? (
    <div className={styles.expandedHeader}>
      <span>
        <span>{name}</span> {onDelete && <DeleteIcon onClick={onDelete} />}
      </span>
      <ArrowDown onClick={onCollapseForm} />
    </div>
  ) : (
    <div
      className={clsx(
        styles.collapsedHeader,
        isDirectorOne && styles.directorTwoCollapsedFontColor,
      )}
    >
      <span onClick={onExpandForm}>
        {isDirectorOne ? name : `+ Add ${name}`}
        <ArrowDown onClick={onExpandForm} />
      </span>
    </div>
  );
};

export default DirectorFormHeaderContent;

import CompanyInformationForm from 'components/CompanyInformationForm';
import React from 'react';
import { TCompanyData } from 'pages/CreateAccount/types';
import { CreateAccountSteps } from 'enums/CreateAccountSteps';

interface ICompanyInformationProps {
  data: TCompanyData;
  onNextStep: (step: CreateAccountSteps) => void;
  onDataUpdate: (data: TCompanyData) => void;
}

const CompanyInformation = ({ data, onNextStep, onDataUpdate }: ICompanyInformationProps) => {
  const handleSubmit = (companyInfo: TCompanyData) => {
    onDataUpdate(companyInfo);

    onNextStep(CreateAccountSteps.DirectorsInformation);
  };

  return <CompanyInformationForm data={data} onSubmit={handleSubmit} />;
};

export default CompanyInformation;

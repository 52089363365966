import clsx from 'clsx';
import React from 'react';
import styles from './ErrorMessage.module.scss';

interface IErrorMessageProps {
  message: string | React.ReactNode;
  className?: string;
}

const ErrorMessage = ({ message, className }: IErrorMessageProps) => {
  return <div className={clsx(styles.message, className)}>{message}</div>;
};

export default ErrorMessage;

/* eslint-disable react-hooks/rules-of-hooks */
import ApplicationsApi, { IApplicationsApi } from 'api/ApplicationsApi';
import BorrowersApi, { IBorrowersApi } from 'api/BorrowersApi';
import DocumentsApi, { IDocumentsApi } from 'api/DocumentsApi';
import { createContext, useContext } from 'react';
import { FCWithChildren } from 'types/common';

export enum ApiContexts {
  Applications = 'applicationsApi',
  Borrowers = 'borrowersApi',
  Documents = 'documentsApi',
}

export interface IApiProviderValue {
  applicationsApi: IApplicationsApi;
  borrowersApi: IBorrowersApi;
  documentsApi: IDocumentsApi;
}

const useService = (serviceName: ApiContexts) => () => {
  const services = useContext(ApiContext);

  return services[serviceName];
};

export const useApplicationApi = useService(ApiContexts.Applications);
export const useBorrowerApi = useService(ApiContexts.Borrowers);

export const ApiContext = createContext<IApiProviderValue>({} as IApiProviderValue);

const applicationsApi = new ApplicationsApi();
const borrowersApi = new BorrowersApi();
const documentsApi = new DocumentsApi();

export const apiValue: IApiProviderValue = {
  applicationsApi,
  borrowersApi,
  documentsApi,
};

const ApiProvider: FCWithChildren = ({ children }) => {
  return <ApiContext.Provider value={apiValue}>{children}</ApiContext.Provider>;
};

export default ApiProvider;

import { VerificationApplicationProvider } from 'providers/VerificationApplicationProvider';
import React from 'react';
import { Outlet } from 'react-router-dom';

const VerificationApplicationContextLayout = () => {
  return (
    <VerificationApplicationProvider>
      <Outlet />
    </VerificationApplicationProvider>
  );
};

export default VerificationApplicationContextLayout;

import React, { ComponentType, FC, useMemo, useState } from 'react';
import Select, { components, ControlProps, OptionProps } from 'react-select';
import styles from './SelectInput.module.scss';
import clsx from 'clsx';
import { VariableValue } from 'types/common';
import getCustomStyles from './customDropdownStyles';
import { AcceptIcon, ArrowDown } from 'static/images';
import ErrorMessage from 'components/ErrorMessage';

enum DropdownIndicatorTypes {
  Close = 'close',
  Open = 'open',
}

export interface IOptionType {
  label: string;
  value: string;
}

export type IsMulti = false;

interface IDropDownProps {
  selectedOption: IOptionType | null;
  onValuePicked: (value: VariableValue) => void;
  options: IOptionType[];
  placeholder: string;
  name: string;
  isSearchable?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: () => void;
  invalid?: boolean;
  className?: string;
  disabled?: boolean;
  derivedMenuStatus?: DropdownIndicatorTypes;
  errorMessage?: string;
  containerClassName?: string;
}

const NewDropdownIndicator = ({
  dropdownIndicatorType,
}: {
  dropdownIndicatorType: DropdownIndicatorTypes;
}) => (
  <ArrowDown
    className={clsx(
      styles.arrowIcon,
      dropdownIndicatorType === DropdownIndicatorTypes.Open && styles.arrowIconRotate,
    )}
  />
);

const NewOption: ComponentType<OptionProps<IOptionType, IsMulti>> = ({ data, ...props }) => (
  <components.Option data={data} {...props}>
    <div className={styles.optionContainer}>
      <span className={styles.option}>{data.label}</span>
      {props.isSelected && <AcceptIcon />}
    </div>
  </components.Option>
);

const DropDown: FC<IDropDownProps> = ({
  options,
  selectedOption,
  placeholder,
  onValuePicked,
  onBlur,
  onFocus,
  name,
  className,
  disabled,
  onChange,
  derivedMenuStatus = DropdownIndicatorTypes.Close,
  errorMessage,
  containerClassName,
}) => {
  const [menuStatus, setMenuStatus] = useState(derivedMenuStatus);
  const hasError = !!errorMessage;

  const NewControl = useMemo(() => {
    const ControlComponent: ComponentType<ControlProps<IOptionType, IsMulti>> = ({ ...props }) => {
      return (
        <>
          <label
            className={clsx(styles.label, {
              [styles.labelFloating]: props.isFocused || props.hasValue,
              [styles.errorLabel]: hasError,
            })}
          >
            {props.selectProps.placeholder}
          </label>
          <components.Control {...props}>{props.children}</components.Control>
        </>
      );
    };

    return ControlComponent;
  }, [hasError]);

  return (
    <div className={clsx(styles.container, containerClassName)}>
      <Select
        className={clsx(styles.input, className)}
        name={name}
        id={name}
        value={selectedOption}
        options={options}
        styles={getCustomStyles({ hasError })}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => <NewDropdownIndicator dropdownIndicatorType={menuStatus} />,
          Option: NewOption,
          Control: NewControl as unknown as ComponentType<ControlProps<IOptionType, IsMulti>>,
        }}
        onMenuOpen={() =>
          menuStatus === DropdownIndicatorTypes.Close && setMenuStatus(DropdownIndicatorTypes.Open)
        }
        onMenuClose={() => setMenuStatus(DropdownIndicatorTypes.Close)}
        onChange={(obj) => {
          onChange?.();
          return obj && onValuePicked(obj.value);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        isDisabled={disabled}
        isSearchable={false}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default DropDown;

import { getSelectInputOptionsFromValueArray } from 'helpers/getSelectInputOptionsFromValueArray';

export enum ProductTypes {
  AgricCommodities = 'Agric Commodities',
  Fuel = 'Fuel',
  Metal = 'Metal',
  Chemical = 'Chemical',
  SolidMinerals = 'Solid Minerals',
  ConsumerElectronics = 'Consumer Electronics',
  Pharmaceutical = 'Pharmaceutical',
  Other = 'Other',
}

const AGRIC_COMMODITIES_PRODUCTS = [
  'Beef',
  'Bird Eye Chili Peppers',
  'Cashew Nut',
  'Cassava Cloves',
  'Cocoa',
  'Cowpea Ginger',
  'Groundnut',
  'Hibiscus',
  'Leather Hides',
  'Maize',
  'Palm Oil',
  'Paddy Rice',
  'Sesame Seeds',
  'Shea Nuts',
  'Soy Beans',
  'Sunflowers Seeds',
  'Sweet Potatoes',
  'Tumeric',
  'Other',
];

const FUEL_PRODUCTS = [
  'Biofuels',
  'Coal',
  'Diesel',
  'Fossil Fuels',
  'Gasoline',
  'Natural Gases',
  'Other',
];

const METAL_PRODUCTS = [
  'Aluminium',
  'Brass',
  'Cobalt',
  'Copper',
  'Iron',
  'Magnesium',
  'Nickel',
  'Steel',
  'Tin',
  'Titanium',
  'Zinc',
  'Other',
];

const CHEMICAL_PRODUCTS = [
  'Chemical products',
  'Ethanol',
  'Isopropyl Alcohol',
  'Pool coat',
  'Universal Alkyd',
  'Urea',
  'Other',
];

const MINERALS_PRODUCTS = [
  'Coal',
  'Gold',
  'Iron Ore',
  'Lead',
  'Lime Stone',
  'Marble',
  'Tin Ore',
  'Uranium',
  'Other',
];

const CONSUMER_ELECTRONICS_PRODUCTS = [
  'Laptops',
  'Printers',
  'Power Banks',
  'Wireless Printers',
  'Smart/Mobile Phones',
  'Televisions',
  'Tablets',
  'Cameras',
  'Other',
];

const PHARMACEUTICAL_PRODUCTS = [
  'Medicine and drugs',
  'Beauty products',
  'Wellness products',
  'Body care products',
  'Health and personal care devices',
  'Natural and organic products',
  'Specialty products',
  'Aromatherapy and spa products',
  'Travel sized and convinience products',
  'Medical and healthcare eqiupments',
  'Other',
];

const PRODUCT_TYPES_TO_PRODUCTS = {
  [ProductTypes.AgricCommodities]: AGRIC_COMMODITIES_PRODUCTS,
  [ProductTypes.Fuel]: FUEL_PRODUCTS,
  [ProductTypes.Metal]: METAL_PRODUCTS,
  [ProductTypes.Chemical]: CHEMICAL_PRODUCTS,
  [ProductTypes.SolidMinerals]: MINERALS_PRODUCTS,
  [ProductTypes.Other]: [],
  [ProductTypes.ConsumerElectronics]: CONSUMER_ELECTRONICS_PRODUCTS,
  [ProductTypes.Pharmaceutical]: PHARMACEUTICAL_PRODUCTS,
};

const PRODUCT_TYPE_LIST = Object.values(ProductTypes);
const TRANSACTION_TYPE_LIST = ['Domestic', 'Export'];
const PRODUCT_UNIT_LIST = [
  'Barrels',
  'Cubic Feet',
  'Gallons',
  'Grams',
  'Kilograms',
  'Litres',
  'Pounds',
  'Metric Tonnes',
];

export const PRODUCT_TYPE_OPTIONS_LIST = getSelectInputOptionsFromValueArray(PRODUCT_TYPE_LIST);
export const TRANSACTION_TYPE_OPTIONS_LIST =
  getSelectInputOptionsFromValueArray(TRANSACTION_TYPE_LIST);
export const PRODUCT_UNITS_OPTION_LIST = getSelectInputOptionsFromValueArray(PRODUCT_UNIT_LIST);

export const getProductList = (productType: ProductTypes) =>
  getSelectInputOptionsFromValueArray(PRODUCT_TYPES_TO_PRODUCTS[productType]);

export const MAX_L0AN_AMOUNT_VALUE = 1_000_000_000;

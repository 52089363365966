import {
  DEFAULT_BANK_ACCOUNT_VALUES,
  DEFAULT_COMMON_DOCUMENT_UPLOAD_VALUES,
  DEFAULT_COMMON_PRODUCT_INFORMATION_VALUES,
  DEFAULT_INVOICE_DISCOUNTING_DOCUMENT_UPLOAD_VALUES,
  DEFAULT_INVOICE_DISCOUNTING_PRODUCT_INFORMATION_VALUES,
  DEFAULT_WORKING_CAPITAL_DOCUMENT_UPLOAD_VALUES,
  DEFAULT_WORKING_CAPITAL_PRODUCT_INFORMATION_VALUES,
} from './constants';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TProductInformationData, TUploadDocumentData } from './types';

import { ApiContext } from 'providers/ApiProvider';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import BankAccount from './Steps/BankAccount';
import { CreditProducts } from 'enums/CreditProducts';
import Layout from 'components/Layout';
import { NAVIGATION_WITH_ACCOUNT_DATA } from 'helpers/constants';
import ProductInformation from './Steps/ProductInformation';
import { SecretKeys } from 'enums/SecretKeys';
import SelectCreditProduct from './Steps/SelectCreditProduct';
import StatusLabel from 'components/StatusLabel/StatusLabel';
import { StatusLabelList } from 'enums/StatusLabels';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';
import UploadDocument from './Steps/UploadDocument';
import { Variables } from 'enums/Variables';
import { getSecretValue } from 'helpers/getSecretValue';
import { transformData } from 'helpers/transformers';
import { trimStringValues } from 'helpers/trimStringValues';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Submit Application';
const APPLICATION_SOURCE = 'Sabi Application Portal';

const SubmitLoanApplication = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { applicationsApi, borrowersApi, documentsApi } = useContext(ApiContext);

  const acc = instance.getActiveAccount();

  const [selectedCreditProduct, setSelectedCreditProduct] = useState<CreditProducts | null>(null);

  const [productInformationData, setProductInformationData] =
    useState<TProductInformationData>(null);
  const [bankAccountData, setBankAccountData] = useState(DEFAULT_BANK_ACCOUNT_VALUES);
  const [uploadDocumentData, setUploadDocumentData] = useState<TUploadDocumentData>(null);

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(SubmitLoanApplicationSteps.SelectCreditProduct);
  const [completedSteps, setCompletedSteps] = useState<SubmitLoanApplicationSteps[]>([]);

  const handleNextStep = (newStep: SubmitLoanApplicationSteps) => {
    setCompletedSteps([...completedSteps, step]);
    setStep(newStep);
  };

  const handlePrevStep = (newStep: SubmitLoanApplicationSteps) => {
    setStep(newStep);
  };

  const handleApplicationCreate = async (data: TUploadDocumentData) => {
    setLoading(true);

    const [verificationApplication] = await applicationsApi.find(
      acc!.localAccountId,
      getSecretValue(SecretKeys.BorrowerVerificationProductId),
    );

    const registrationNumber = verificationApplication.variables[
      Variables.BorrowerCompanyRegistrationNumber
    ] as string;

    const borrower = await borrowersApi.findByCompanyRegistrationNumber(registrationNumber);

    if (borrower?.locked) {
      navigate(ApplicationRoutes.LockedBorrower);
      return;
    }

    const applicationData = trimStringValues({
      ...productInformationData,
      ...bankAccountData,
      [Variables.ProductCategory]: selectedCreditProduct,
      [Variables.ApplicationSource]: APPLICATION_SOURCE,
    });

    const application = await applicationsApi.create({
      variables: transformData(applicationData),
      borrower: borrower!.id,
      productId: getSecretValue(SecretKeys.BusinessLoanProductId),
      coBorrowers: [],
      labelsIds: [getSecretValue(SecretKeys.SabiApplicationPortalLabelId)],
    });

    const filesToUpload: File[] = Object.values(data!)
      .map((fileArray) => fileArray)
      .flat();

    await documentsApi.upload(application.id, filesToUpload);

    setLoading(false);

    navigate(ApplicationRoutes.Applications);
  };

  useEffect(() => {
    if (!acc?.localAccountId) {
      navigate(ApplicationRoutes.Verification);
    }
  }, []);

  const handleSelectCreditProduct = (product: CreditProducts) => {
    setSelectedCreditProduct(product);
    switch (product) {
      case CreditProducts.CommodityFinancing:
      case CreditProducts.InventoryFinancing:
      case CreditProducts.PrincipalTrading: {
        setProductInformationData(DEFAULT_COMMON_PRODUCT_INFORMATION_VALUES);
        setUploadDocumentData(DEFAULT_COMMON_DOCUMENT_UPLOAD_VALUES);
        return;
      }
      case CreditProducts.WorkingCapital: {
        setProductInformationData(DEFAULT_WORKING_CAPITAL_PRODUCT_INFORMATION_VALUES);
        setUploadDocumentData(DEFAULT_WORKING_CAPITAL_DOCUMENT_UPLOAD_VALUES);
        return;
      }
      case CreditProducts.InvoiceDiscounting: {
        setProductInformationData(DEFAULT_INVOICE_DISCOUNTING_PRODUCT_INFORMATION_VALUES);
        setUploadDocumentData(DEFAULT_INVOICE_DISCOUNTING_DOCUMENT_UPLOAD_VALUES);
        return;
      }
      default:
        return null as never;
    }
  };

  const renderCurrentPage = () => {
    switch (step) {
      case SubmitLoanApplicationSteps.SelectCreditProduct:
        return (
          <SelectCreditProduct
            selectProduct={handleSelectCreditProduct}
            selectedCreditProduct={selectedCreditProduct}
            onNextStep={handleNextStep}
          />
        );
      case SubmitLoanApplicationSteps.ProductInformation:
        return (
          <ProductInformation
            selectedCreditProduct={selectedCreditProduct!}
            data={productInformationData}
            onDataUpdate={setProductInformationData}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case SubmitLoanApplicationSteps.BankAccount:
        return (
          <BankAccount
            data={bankAccountData}
            onDataUpdate={setBankAccountData}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
          />
        );

      case SubmitLoanApplicationSteps.UploadDocument:
        return (
          <UploadDocument
            selectedCreditProduct={selectedCreditProduct!}
            data={uploadDocumentData}
            onPrevStep={handlePrevStep}
            onApplicationCreate={handleApplicationCreate}
          />
        );
    }
  };

  const navData = useMemo(() => {
    const subLinks = NAVIGATION_WITH_ACCOUNT_DATA.subLinks?.map(({ name }) => {
      const isCompleted = completedSteps.includes(name as SubmitLoanApplicationSteps);
      const isActive = name === step;

      const disabled = !isCompleted && !isActive;

      return {
        name,
        isCompleted,
        isActive,
        disabled,
        onClick: () => {
          setStep(name as SubmitLoanApplicationSteps);
        },
      };
    });

    const links = NAVIGATION_WITH_ACCOUNT_DATA.links?.map(({ name, route }) => ({
      name,
      isActive: route === ApplicationRoutes.Applications,
      status: route === ApplicationRoutes.Verification && (
        <StatusLabel isTransparent type={StatusLabelList.Verified} />
      ),
      route,
      onClick: () => navigate(route),
    }));

    return {
      ...NAVIGATION_WITH_ACCOUNT_DATA,
      links,
      subLinks,
    };
  }, [step]);

  return (
    <Layout
      backButton={step === SubmitLoanApplicationSteps.SelectCreditProduct}
      onBackButtonClick={() => navigate(ApplicationRoutes.Applications)}
      loading={loading}
      navData={navData}
      pageTitle={PAGE_TITLE}
    >
      {renderCurrentPage()}
    </Layout>
  );
};

export default SubmitLoanApplication;

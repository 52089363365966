import { StylesConfig } from 'react-select';
import { IsMulti } from './SelectInput';

type MyOptionType = {
  label: string;
  value: string;
};

interface ICustomStylesProps {
  hasError: boolean;
}

const getCustomStyles = ({ hasError }: ICustomStylesProps) => {
  const customDropDownStyles: StylesConfig<MyOptionType, IsMulti> = {
    menu: () => ({
      position: 'absolute',
      width: '100%',
      zIndex: 998,
      boxSizing: 'border-box',
      borderRadius: 4,
      boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.12)',
    }),
    menuList: (base) => ({
      borderRadius: '4px',
      background: '#ffffff',
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#ffffff',
        borderRadius: '2px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#D1D1D1',
        borderRadius: '2px',
        width: '4px',
        height: '82px',
      },
    }),

    option: (provided, { isFocused, isSelected }) => {
      const getBackGroundColor = () => {
        if (isFocused) {
          return 'rgba(255, 198, 144, 0.2)';
        }

        return '#ffffff';
      };

      const getFontColor = () => {
        if (isSelected) {
          return '#FF7F36';
        }

        return '#252631';
      };

      return {
        ...provided,
        height: '44px',
        position: 'relative',
        backgroundColor: getBackGroundColor(),
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
        color: getFontColor(),
      };
    },

    control: (provided, { menuIsOpen, isDisabled }) => {
      const getBorderColor = () => {
        if (hasError) {
          return '#f53c27';
        }

        if (menuIsOpen) {
          return '#9FC8EA';
        }

        return '#D2D5DD';
      };

      const getBackgroundColor = () => {
        if (hasError) {
          return 'rgba(245, 60, 39, 0.12)';
        }

        if (isDisabled) {
          return '#f6f7f9';
        }

        return '#ffffff';
      };

      const styles = {
        ...provided,
        alignItems: 'stretch',
        cursor: 'pointer',
        height: 56,
        minWidth: '155px',
        outline: 'none',
        boxShadow: 'none',
        border: `1px solid ${getBorderColor()}`,
        backgroundColor: getBackgroundColor(),

        ':hover': {
          border: '1px solid #A8ACC3',
        },
      };

      return styles;
    },

    indicatorsContainer: (provided) => ({
      ...provided,
      alignSelf: 'center',
      width: 24,
      height: 24,
      marginRight: 16,
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      top: 24,
      left: 16,
      margin: 0,
      position: 'absolute',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#252631',
      fontFamily: '"DM Sans", sans-serif',
      opacity: isDisabled ? 0.8 : 1,
    }),

    valueContainer: (provided) => ({
      ...provided,
      alignItems: 'stretch',
      overflow: 'visible',
      fontSize: '14px',
      lineHeight: '18px',
      position: 'relative',
      padding: 0,
    }),

    input: (provided) => ({
      ...provided,
      position: 'absolute',
      left: 24,
      top: 20,
    }),

    placeholder: () => ({
      display: 'none',
    }),
  };

  return customDropDownStyles;
};

export default getCustomStyles;

import clsx from 'clsx';
import ErrorMessage from 'components/ErrorMessage';
import { ACCEPTED_FILE_FORMATS } from 'helpers/files';
import React from 'react';
import styles from './DraggableField.module.scss';

const DRAGGABLE_FIELD_ERROR_MESSAGE = 'Invalid file format or size';

interface IDraggableFieldProps {
  id: string;
  isDragOver: boolean;
  isError: boolean;
  onFileDrag: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDragOver: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDragEnter: (e: React.DragEvent<HTMLLabelElement>) => void;
  onDragLeave: (e: React.DragEvent<HTMLLabelElement>) => void;
  onUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  limit: number | false;
}

const DraggableField = ({
  id,
  isDragOver,
  isError,
  onFileDrag,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onUploadFile,
  limit,
}: IDraggableFieldProps) => {
  const labelClassName = clsx(
    styles.inputWrapper,
    isDragOver ? styles.dragOverInputWrapper : styles.inputWrapper,
    isError && styles.errorInputWrapper,
  );

  const getLimitNotification = () => {
    if (limit === 3) {
      return 'Maximum 3 documents';
    }

    return 'Only 1 document';
  };

  return (
    <div className={styles.container}>
      <label
        className={labelClassName}
        onDrop={onFileDrag}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        <div className={styles.textContainer}>
          <div>
            Drag a file here or <span className={styles.browse}>browse</span> for a file to upload.
          </div>
          {limit && <div>{getLimitNotification()}</div>}
        </div>
        <input
          type="file"
          id={id}
          className={styles.fileInput}
          onChange={onUploadFile}
          accept={ACCEPTED_FILE_FORMATS}
        />
      </label>
      {isError && <ErrorMessage message={DRAGGABLE_FIELD_ERROR_MESSAGE} />}
    </div>
  );
};

export default DraggableField;

export enum SecretKeys {
  ApiUrl = 'REACT_APP_API_URL',
  AzureClientId = 'REACT_APP_AZURE_CLIENT_ID',
  AzureClientTenantName = 'REACT_APP_AZURE_CLIENT_TENANT_NAME',
  AzureClientPolicy = 'REACT_APP_AZURE_CLIENT_POLICY',
  AzureRedirectUri = 'REACT_APP_AZURE_REDIRECT_URI',
  SentryDsn = 'REACT_APP_SENTRY_DSN',
  BorrowerVerificationProductId = 'REACT_APP_BORROWER_VERIFICATION_PRODUCT_ID',
  BusinessLoanProductId = 'REACT_APP_BUSINESS_LOAN_PRODUCT_ID',
  BorrowerVerificationProductApprovedStatusId = 'REACT_APP_BORROWER_VERIFICATION_PRODUCT_APPROVED_STATUS_ID',
  SuspiciousLabelId = 'REACT_APP_SUSPICIOUS_LABEL_ID',
  SabiApplicationPortalLabelId = 'REACT_APP_SABI_APPLICATION_PORTAL_LABEL_ID',
}

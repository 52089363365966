import React from 'react';
import styles from './AddApplicationButton.module.scss';

const BUTTON_TITLE = 'Submit Application';

interface IAddApplicationButtonProps {
  onClick: () => void;
}

const AddApplicationButton = ({ onClick }: IAddApplicationButtonProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      {BUTTON_TITLE}
    </div>
  );
};

export default AddApplicationButton;

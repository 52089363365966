import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import {
  getBankVerificationNumberValidationRules,
  getDateOfBirthValidationRules,
  getEmailValidationRules,
  getNationalIdValidationRules,
  getPhoneNumberValidationRules,
  getRequiredFileInputErrorMessage,
  getRequiredStringValidationRules,
  getVirtualNationalIdValidationRules,
} from 'helpers/validation';
import * as Yup from 'yup';

export const firstDirectorInformationValidationSchema = Yup.object().shape({
  [Variables.BorrowerDirectorOneFirstName]: getRequiredStringValidationRules(InputLabels.FirstName),
  [Variables.BorrowerDirectorOneLastName]: getRequiredStringValidationRules(InputLabels.LastName),
  [Variables.BorrowerDirectorOneDateOfBirth]: getDateOfBirthValidationRules(),
  [Variables.BorrowerDirectorOneBankVerificationNumber]: getBankVerificationNumberValidationRules(),
  [Variables.BorrowerDirectorOneEmailAddress]: getEmailValidationRules(),
  [Variables.BorrowerDirectorOnePhoneNumber]: getPhoneNumberValidationRules(),
  [Variables.BorrowerDirectorOneResidentialAddress]: getRequiredStringValidationRules(
    InputLabels.ResidentialAddress,
  ),
  [Variables.BorrowerDirectorOneIdDocumentType]: getRequiredStringValidationRules(
    InputLabels.NationalIdType,
  ),
  [Variables.BorrowerDirectorOneBvnImage]: getRequiredFileInputErrorMessage(
    InputLabels.PassportPhoto,
  ),
  [Variables.BorrowerDirectorOneNationalIdNumber]: getNationalIdValidationRules(),
  [Variables.BorrowerDirectorOneVirtualNationalIdNumber]: getVirtualNationalIdValidationRules(),
});

export const secondDirectorInformationValidationSchema = Yup.object().shape({
  [Variables.BorrowerDirectorTwoFirstName]: getRequiredStringValidationRules(InputLabels.FirstName),
  [Variables.BorrowerDirectorTwoLastName]: getRequiredStringValidationRules(InputLabels.LastName),
  [Variables.BorrowerDirectorTwoDateOfBirth]: getDateOfBirthValidationRules(),
  [Variables.BorrowerDirectorTwoBankVerificationNumber]: getBankVerificationNumberValidationRules(),
  [Variables.BorrowerDirectorTwoEmailAddress]: getEmailValidationRules(),
  [Variables.BorrowerDirectorTwoPhoneNumber]: getPhoneNumberValidationRules(),
  [Variables.BorrowerDirectorTwoResidentialAddress]: getRequiredStringValidationRules(
    InputLabels.ResidentialAddress,
  ),
  [Variables.BorrowerDirectorTwoIdDocumentType]: getRequiredStringValidationRules(
    InputLabels.NationalIdType,
  ),
  [Variables.BorrowerDirectorTwoBvnImage]: getRequiredFileInputErrorMessage(
    InputLabels.PassportPhoto,
  ),
  [Variables.BorrowerDirectorTwoNationalIdNumber]: getNationalIdValidationRules(),
  [Variables.BorrowerDirectorTwoVirtualNationalIdNumber]: getVirtualNationalIdValidationRules(),
});

export const representativeInformationValidationSchema = Yup.object().shape({
  [Variables.BorrowerCompanyRepresentativeFirstName]: getRequiredStringValidationRules(
    InputLabels.FirstName,
  ),
  [Variables.BorrowerCompanyRepresentativeLastName]: getRequiredStringValidationRules(
    InputLabels.LastName,
  ),
  [Variables.BorrowerCompanyRepresentativeDateOfBirth]: getDateOfBirthValidationRules(),
  [Variables.BorrowerCompanyRepresentativeBvn]: getBankVerificationNumberValidationRules(),
  [Variables.BorrowerCompanyRepresentativeEmail]: getEmailValidationRules(),
  [Variables.BorrowerCompanyRepresentativePhoneNumber]: getPhoneNumberValidationRules(),
  [Variables.BorrowerCompanyRepresentativeResidentialAddress]: getRequiredStringValidationRules(
    InputLabels.ResidentialAddress,
  ),
  [Variables.BorrowerCompanyRepresentativeIdDocumentType]: getRequiredStringValidationRules(
    InputLabels.NationalIdType,
  ),
  [Variables.BorrowerCompanyRepresentativeBvnImage]: getRequiredFileInputErrorMessage(
    InputLabels.PassportPhoto,
  ),
  [Variables.BorrowerCompanyRepresentativeNationalIdNumber]: getNationalIdValidationRules(),
  [Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber]:
    getVirtualNationalIdValidationRules(),
});

import BaseApi from 'api/BaseApi';
import { FetchMethodType } from 'enums/FetchMethodTypes';
import { BorrowerType, VariableValue } from 'types/common';
import { IBorrower } from './types';

interface ICreateBorrowerData {
  variables: Record<string, VariableValue>;
  type: BorrowerType;
}

export interface IBorrowersApi {
  update(id: string, variables: Record<string, VariableValue>): Promise<IBorrower>;
  create(data: ICreateBorrowerData): Promise<IBorrower>;
  findByAzureUserId(azureUserId: string): Promise<IBorrower | null>;
  findByCompanyRegistrationNumber(companyRegistrationNUmber: string): Promise<IBorrower | null>;
}

export default class BorrowersApi extends BaseApi implements IBorrowersApi {
  private endpointBase = '/borrowers';

  public update(id: string, variables: Record<string, VariableValue>): Promise<IBorrower> {
    return this.fetch(this.endpointBase, {
      method: FetchMethodType.PUT,
      body: {
        params: { variables },
        id,
      },
    });
  }

  public create(data: ICreateBorrowerData): Promise<IBorrower> {
    return this.fetch(this.endpointBase, {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  public async findByAzureUserId(azureId: string): Promise<IBorrower | null> {
    const response = await this.fetch<IBorrower | null>(`${this.endpointBase}/azure-id/${azureId}`);

    return response;
  }

  public async findByCompanyRegistrationNumber(
    companyRegistrationNumber: string,
  ): Promise<IBorrower | null> {
    const response = await this.fetch<IBorrower | null>(
      `${this.endpointBase}/company-id/${companyRegistrationNumber}`,
    );

    return response;
  }
}

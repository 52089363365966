import NextButton from 'components/NextButton';
import React, { MouseEventHandler } from 'react';
import styles from './WelcomePageForm.module.scss';

const TITLE = 'Sabi Application Portal';
const DESCRIPTION =
  'Welcome to the Sabi Application Portal. Please click on the Continue button to proceed in creating your account.';

interface IPopupProps {
  onSubmit: MouseEventHandler<HTMLButtonElement>;
}

const WelcomePageForm = ({ onSubmit }: IPopupProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{TITLE}</div>
      <div className={styles.description}>{DESCRIPTION}</div>
      <div className={styles.buttonsContainer}>
        <NextButton onClick={onSubmit} title="Continue" className={styles.button} />
      </div>
    </div>
  );
};

export default WelcomePageForm;

import ErrorLayout from 'components/ErrorLayout/ErrorLayout';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TITLE = '404';
const SUBTITLE = 'Ooops! Page Not Found';
const DESCRIPTION = 'This page doesn’t exist or was removed! We suggest you back to home';

const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ApplicationRoutes.Redirect);
  };

  return (
    <ErrorLayout
      onClick={handleClick}
      title={TITLE}
      subTitle={SUBTITLE}
      description={DESCRIPTION}
    />
  );
};

export default NotFound;

import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './InputWrapper.module.scss';
import ErrorMessage from 'components/ErrorMessage';

export interface IInputWrapperProps {
  label?: string | null;
  error?: string | React.ReactNode;
  inputIcon?: React.ReactElement | React.ReactNode;
  className?: string;
  optional?: boolean;
  inputDescription?: string | React.ReactNode;
}

const InputWrapper: FC<IInputWrapperProps> = ({
  children,
  inputIcon,
  label,
  error,
  className,
  optional,
  inputDescription,
}) => {
  return (
    <div className={clsx(styles.inputContainer, className)}>
      <div className={clsx(styles.inputWrapper, error && styles.error)}>
        {children} <label>{label}</label>
        {inputIcon}
        {optional && <span className={styles.optional}>Optional</span>}
      </div>
      {error && <ErrorMessage message={error} />}
      {inputDescription}
    </div>
  );
};

export default InputWrapper;

import clsx from 'clsx';
import React, { FC, MouseEventHandler, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ArrowDown, Checkmark, LongArrowRight } from 'static/images';
import styles from './Navigation.module.scss';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';

interface ISubLink {
  name: string;
  isCompleted: boolean;
  isActive: boolean;
  disabled: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}

interface ILink {
  name: string;
  isActive: boolean;
  route: ApplicationRoutes;
  onClick: MouseEventHandler<HTMLDivElement>;
  status?: React.ReactNode;
}

export interface INavigation {
  links?: Array<ILink>;
  subLinks?: Array<ISubLink>;
  title?: string;
  description?: string;
}

const Navigation: FC<INavigation> = ({ title, description, links = [], subLinks = [] }) => {
  const activeLink = links.find(({ isActive }) => isActive) || links[0];
  const notActiveLinks = links.filter(({ name }) => activeLink.name !== name);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleLinkClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    onClick: MouseEventHandler<HTMLDivElement>,
    isActive: Boolean,
  ) => {
    if (isActive) {
      setIsExpanded(!isExpanded);

      return null;
    }

    onClick(event);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>

      {!!links.length && (
        <div className={styles.linksContainerMobile}>
          <div
            className={clsx(styles.link, { [styles.linkActive]: activeLink.isActive })}
            onClick={(event) => handleLinkClick(event, activeLink.onClick, activeLink.isActive)}
            key={activeLink.name}
          >
            {activeLink.name}
            <ArrowDown className={clsx(styles.linkIcon, { [styles.expanded]: isExpanded })} />
          </div>

          <CSSTransition
            in={isExpanded}
            timeout={500}
            unmountOnExit
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
          >
            <div>
              {notActiveLinks.map(({ name, onClick }) => (
                <div className={styles.link} onClick={onClick} key={name}>
                  {name}
                </div>
              ))}
            </div>
          </CSSTransition>
        </div>
      )}

      {!!links.length && (
        <div className={styles.linksContainer}>
          {links.map(({ name, isActive, status, onClick }) => (
            <div
              className={clsx(styles.link, { [styles.linkActive]: isActive })}
              onClick={onClick}
              key={name}
            >
              {name}
              {!!status && <div className={styles.status}>{status}</div>}
            </div>
          ))}
        </div>
      )}

      {!!subLinks.length && (
        <div className={styles.sublinksContainer}>
          {subLinks.map(({ name, isActive, isCompleted, disabled, onClick }) => (
            <div
              className={clsx(
                styles.sublink,
                { [styles.sublinkActive]: isActive },
                { [styles.sublinkCompleted]: isCompleted },
                { [styles.disabled]: disabled },
              )}
              onClick={onClick}
              key={name}
            >
              {isActive && <LongArrowRight className={styles.icon} />}
              {isCompleted && !isActive && <Checkmark className={styles.icon} />}
              {name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navigation;

import { DocumentsUploadFormVariables } from 'enums/DocumentsUploadFormVariables';
import FileUploadInput from 'components/FileUploadInput';
import { Formik } from 'formik';
import { InputLabels } from 'enums/InputLabels';
import NextButton from 'components/NextButton';
import PrevButton from 'components/PrevButton';
import React from 'react';
import { TCommonUploadDocumentData } from 'pages/SubmitLoanApplication/types';
import { TOptionalString } from 'types/common';
import { commonDocumentsUploadFormValidationSchema } from './validationSchema';
import styles from './DocumentsUploadForm.module.scss';

interface ICommonDocumentsUploadFormProps {
  data: TCommonUploadDocumentData;
  onPrevStep: () => void;
  onSubmit: (data: TCommonUploadDocumentData) => void;
}

const CommonDocumentsUploadForm = ({
  data,
  onPrevStep,
  onSubmit,
}: ICommonDocumentsUploadFormProps) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={commonDocumentsUploadFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue, touched, errors, submitForm }) => {
        const getErrorMessage = (name: keyof TCommonUploadDocumentData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleNextClick = async () => {
          await submitForm();
        };

        return (
          <>
            <div className={styles.container}>
              <FileUploadInput
                label={InputLabels.PurchaseOrder}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.PurchaseOrder, value);
                }}
                error={
                  getErrorMessage(DocumentsUploadFormVariables.PurchaseOrder) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.PurchaseOrder]}
                name={DocumentsUploadFormVariables.PurchaseOrder}
              />
              <FileUploadInput
                label={InputLabels.ContractAgreement}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.ContractAgreement, value);
                }}
                error={
                  getErrorMessage(DocumentsUploadFormVariables.ContractAgreement) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.ContractAgreement]}
                name={DocumentsUploadFormVariables.ContractAgreement}
              />
              <FileUploadInput
                label={InputLabels.Invoice}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.Invoice, value);
                }}
                error={getErrorMessage(DocumentsUploadFormVariables.Invoice) as TOptionalString}
                files={values[DocumentsUploadFormVariables.Invoice]}
                name={DocumentsUploadFormVariables.Invoice}
              />
              <FileUploadInput
                label={InputLabels.FinancialStatement}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.FinancialStatement, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.FinancialStatement,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.FinancialStatement]}
                name={DocumentsUploadFormVariables.FinancialStatement}
              />
              <FileUploadInput
                label={InputLabels.StatementOfAccount}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.StatementOfAccount, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.StatementOfAccount,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.StatementOfAccount]}
                name={DocumentsUploadFormVariables.StatementOfAccount}
              />
              <FileUploadInput
                label={InputLabels.CompanyOrManagementProfile}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.CompanyOrManagementProfile, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.CompanyOrManagementProfile,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.CompanyOrManagementProfile]}
                name={DocumentsUploadFormVariables.CompanyOrManagementProfile}
              />
              <FileUploadInput
                label={InputLabels.PreviouslyExecutedContracts}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.PreviouslyExecutedContracts, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.PreviouslyExecutedContracts,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.PreviouslyExecutedContracts]}
                name={DocumentsUploadFormVariables.PreviouslyExecutedContracts}
                limit={3}
              />
            </div>
            <div className={styles.buttonsContainer}>
              <PrevButton onClick={onPrevStep} />
              <NextButton title="Submit Application" onClick={handleNextClick} />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CommonDocumentsUploadForm;

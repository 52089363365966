import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import {
  getEmailValidationRules,
  getPhoneNumberValidationRules,
  getRequiredStringValidationRules,
} from 'helpers/validation';
import * as Yup from 'yup';

export const companyInformationFormValidationSchema = Yup.object().shape({
  [Variables.BorrowerCompanyName]: getRequiredStringValidationRules(
    InputLabels.RegisteredBusinessName,
  ),
  [Variables.BorrowerCompanyRegistrationNumber]: getRequiredStringValidationRules(
    InputLabels.RcNumber,
  ),
  [Variables.BorrowerRegisteredBusinessAddress]: getRequiredStringValidationRules(
    InputLabels.RegisteredBusinessAddress,
  ),
  [Variables.BorrowerCountry]: getRequiredStringValidationRules(InputLabels.Country),
  [Variables.BorrowerBusinessType]: getRequiredStringValidationRules(InputLabels.BusinessType),
  [Variables.BorrowerNatureOfBusiness]: getRequiredStringValidationRules(
    InputLabels.NatureOfBusiness,
  ),
  [Variables.BorrowerOperatingAddress]: getRequiredStringValidationRules(
    InputLabels.OperatingAddress,
  ),
  [Variables.BorrowerEmail]: getEmailValidationRules(),
  [Variables.BorrowerPhoneNumber]: getPhoneNumberValidationRules(),
  [Variables.BorrowerTin]: getRequiredStringValidationRules(InputLabels.TaxIdentificationNumber),
});

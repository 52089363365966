import { CreditProducts } from 'enums/CreditProducts';
import ProductInformationForm from 'components/ProductInformationForm';
import React from 'react';
import { SubmitLoanApplicationSteps } from 'enums/SubmitLoanApplicationSteps';
import { TProductInformationData } from 'pages/SubmitLoanApplication/types';

interface IProductInformationProps {
  data: TProductInformationData;
  selectedCreditProduct: CreditProducts;
  onNextStep: (step: SubmitLoanApplicationSteps) => void;
  onPrevStep: (step: SubmitLoanApplicationSteps) => void;
  onDataUpdate: (data: TProductInformationData) => void;
}

const ProductInformation = ({
  data,
  onNextStep,
  onDataUpdate,
  onPrevStep,
  selectedCreditProduct,
}: IProductInformationProps) => {
  const handleSubmit = (productInformation: TProductInformationData) => {
    onDataUpdate(productInformation);

    onNextStep(SubmitLoanApplicationSteps.BankAccount);
  };

  const handlePrevStep = () => {
    onPrevStep(SubmitLoanApplicationSteps.SelectCreditProduct);
  };

  return (
    <ProductInformationForm
      selectedProduct={selectedCreditProduct}
      data={data}
      onSubmit={handleSubmit}
      onPrevStep={handlePrevStep}
    />
  );
};

export default ProductInformation;

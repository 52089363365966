import { DocumentsUploadFormVariables } from 'enums/DocumentsUploadFormVariables';
import { Variables } from 'enums/Variables';
import {
  TBankAccountData,
  TCommonProductInformationData,
  TCommonUploadDocumentData,
  TInvoiceDiscountingProductInformationData,
  TInvoiceDiscountingUploadDocumentData,
  TWorkingCapitalProductInformationData,
  TWorkingCapitalUploadDocumentData,
} from './types';

export const DEFAULT_COMMON_PRODUCT_INFORMATION_VALUES: TCommonProductInformationData = {
  [Variables.ProductTypeList]: '',
  [Variables.ProductTypeOther]: '',
  [Variables.TransactionType]: '',
  [Variables.ProductList]: '',
  [Variables.ProductOther]: '',
  [Variables.ProductUnits]: '',
  [Variables.ProductQuantity]: '',
  [Variables.ProductUnitPurchasePrice]: '',
  [Variables.ProductUnitSellingPrice]: '',
  [Variables.LogisticsCostPerTruck]: '',
  [Variables.LogisticsNumberOfTrucks]: '',
  [Variables.OtherCostsAmount]: '',
  [Variables.OtherCostsDescription]: '',
  [Variables.LoanAmount]: '',
  [Variables.SupplierAddress]: '',
  [Variables.PaymentTenorInDays]: '',
  [Variables.OfftakerName]: '',
  [Variables.OfftakerAddress]: '',
  [Variables.OfftakerEmail]: '',
};

export const DEFAULT_WORKING_CAPITAL_PRODUCT_INFORMATION_VALUES: TWorkingCapitalProductInformationData =
  {
    [Variables.LoanAmount]: '',
    [Variables.PaymentTenorInDays]: '',
    [Variables.FacilityPurpose]: '',
  };

export const DEFAULT_INVOICE_DISCOUNTING_PRODUCT_INFORMATION_VALUES: TInvoiceDiscountingProductInformationData =
  {
    [Variables.LoanAmount]: '',
    [Variables.SupplierAddress]: '',
    [Variables.PaymentTenorInDays]: '',
    [Variables.OfftakerName]: '',
    [Variables.OfftakerAddress]: '',
    [Variables.OfftakerEmail]: '',
    [Variables.DateInvoice]: '',
  };

export const DEFAULT_BANK_ACCOUNT_VALUES: TBankAccountData = {
  [Variables.BankAccountName]: '',
  [Variables.BankAccountNumber]: '',
  [Variables.BankName]: '',
};

export const DEFAULT_COMMON_DOCUMENT_UPLOAD_VALUES: TCommonUploadDocumentData = {
  [DocumentsUploadFormVariables.PurchaseOrder]: [],
  [DocumentsUploadFormVariables.ContractAgreement]: [],
  [DocumentsUploadFormVariables.Invoice]: [],
  [DocumentsUploadFormVariables.FinancialStatement]: [],
  [DocumentsUploadFormVariables.StatementOfAccount]: [],
  [DocumentsUploadFormVariables.CompanyOrManagementProfile]: [],
  [DocumentsUploadFormVariables.PreviouslyExecutedContracts]: [],
};

export const DEFAULT_WORKING_CAPITAL_DOCUMENT_UPLOAD_VALUES: TWorkingCapitalUploadDocumentData = {
  [DocumentsUploadFormVariables.ProFormaInvoice]: [],
  [DocumentsUploadFormVariables.FinancialStatement]: [],
  [DocumentsUploadFormVariables.BankStatement]: [],
  [DocumentsUploadFormVariables.CompanyOrManagementProfile]: [],
  [DocumentsUploadFormVariables.PreviouslyExecutedContracts]: [],
  [DocumentsUploadFormVariables.FormalApplicationOrBoardResolution]: [],
  [DocumentsUploadFormVariables.CashFlowProjection]: [],
};

export const DEFAULT_INVOICE_DISCOUNTING_DOCUMENT_UPLOAD_VALUES: TInvoiceDiscountingUploadDocumentData =
  {
    [DocumentsUploadFormVariables.InvoiceStampedByPrincipal]: [],
    [DocumentsUploadFormVariables.FinancialStatement]: [],
    [DocumentsUploadFormVariables.CompanyOrManagementProfile]: [],
    [DocumentsUploadFormVariables.EvidenceOfPastSupplies]: [],
    [DocumentsUploadFormVariables.FormalApplicationOrBoardResolution]: [],
    [DocumentsUploadFormVariables.CopyOfDeliveryNote]: [],
    [DocumentsUploadFormVariables.BankStatement]: [],
  };

import React, { FC } from 'react';
import clsx from 'clsx';
import { SUPPORT_EMAIL } from 'helpers/constants';
import styles from './SupportEmail.module.scss';

interface ISupportEmail {
  className?: string;
}

const SupportEmail: FC<ISupportEmail> = ({ className }) => (
  <a className={clsx(styles.email, className)} href={`mailto: ${SUPPORT_EMAIL}`}>
    {SUPPORT_EMAIL}
  </a>
);

export default SupportEmail;

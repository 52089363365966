import BaseApi from 'api/BaseApi';
import { FetchMethodType } from 'enums/FetchMethodTypes';
import { VariableValue } from 'types/common';
import { IApplication } from './types';

export interface IUpdateApplicationData {
  status?: string;
  declineReasons?: string[];
  intermediaryId?: string;
  teamMembers?: string[];
  labels?: string[];
  variables?: Record<string, VariableValue>;
}

export interface ICreateApplicationData {
  variables: Record<string, VariableValue>;
  borrowerVariables?: Record<string, VariableValue>;
  productId: string;
  borrower: string;
  coBorrowers: Array<string>;
  status?: string;
  labelsIds?: string[];
}

export interface IApplicationsApi {
  update(id: string, params: IUpdateApplicationData): Promise<IApplication>;
  create(body: ICreateApplicationData): Promise<IApplication>;
  find(id: string, productId: string): Promise<IApplication[]>;
  findByRegistrationNumber(registrationNumber: string, productId: string): Promise<IApplication[]>;
}

export default class ApplicationsApi extends BaseApi implements IApplicationsApi {
  private endpointBase = '/applications';

  public update(id: string, params: IUpdateApplicationData): Promise<IApplication> {
    return this.fetch(this.endpointBase, {
      method: FetchMethodType.POST,
      body: { id, params },
    });
  }

  public async create(body: ICreateApplicationData): Promise<IApplication> {
    return this.fetch(this.endpointBase, { method: FetchMethodType.POST, body });
  }

  public async find(azureId: string, productId: string): Promise<IApplication[]> {
    const params = new URLSearchParams();

    params.append('productId', productId);
    params.append('azureId', azureId);

    return this.fetch(`${this.endpointBase}?${params}`);
  }

  public async findByRegistrationNumber(
    registrationNumber: string,
    productId: string,
  ): Promise<IApplication[]> {
    const params = new URLSearchParams();

    params.append('productId', productId);
    params.append('registrationNumber', registrationNumber);

    return this.fetch(`${this.endpointBase}/find-by-registration-number?${params}`);
  }
}

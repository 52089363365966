import React from 'react';
import { FCWithChildren } from 'types/common';
import styles from './Popup.module.scss';

const Popup: FCWithChildren = ({ children }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Popup;

import React from 'react';
import ContactInfoFields from 'components/ContactInfoFields';
import Input from 'components/Input';
import NextButton from 'components/NextButton';
import NumberInput from 'components/NumberInput';
import SelectInput from 'components/SelectInput';
import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import { Formik } from 'formik';
import { getSelectedOptionByValue } from 'helpers/getSelectedOptionByValue';
import { TCompanyData } from 'pages/CreateAccount/types';
import styles from './CompanyInformationForm.module.scss';
import { BUSINESS_TYPE_OPTIONS_LIST, COUNTRY_OPTIONS_LIST } from './constants';
import { companyInformationFormValidationSchema } from './validationSchema';

interface ICompanyInformationFormProps {
  data: TCompanyData;
  onSubmit: (values: TCompanyData) => void;
}

const CompanyInformationForm = ({ data, onSubmit }: ICompanyInformationFormProps) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={companyInformationFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        submitForm,
      }) => {
        const getErrorMessage = (name: keyof TCompanyData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleNextClick = async () => {
          await submitForm();

          if (!isValid) {
            return;
          }

          onSubmit(values);
        };

        return (
          <>
            <div className={styles.container}>
              <Input
                label={InputLabels.RegisteredBusinessName}
                value={values[Variables.BorrowerCompanyName]}
                onChange={handleChange}
                name={Variables.BorrowerCompanyName}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerCompanyName)}
              />
              <NumberInput
                label={InputLabels.RcNumber}
                value={values[Variables.BorrowerCompanyRegistrationNumber]}
                onChange={handleChange}
                name={Variables.BorrowerCompanyRegistrationNumber}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerCompanyRegistrationNumber)}
              />
              <NumberInput
                label={InputLabels.TaxIdentificationNumber}
                value={values[Variables.BorrowerTin]}
                onChange={handleChange}
                name={Variables.BorrowerTin}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerTin)}
              />
              <SelectInput
                selectedOption={getSelectedOptionByValue(
                  BUSINESS_TYPE_OPTIONS_LIST,
                  values[Variables.BorrowerBusinessType],
                )}
                onValuePicked={(value) => {
                  setFieldValue(Variables.BorrowerBusinessType, value);
                }}
                options={BUSINESS_TYPE_OPTIONS_LIST}
                name={Variables.BorrowerBusinessType}
                placeholder={InputLabels.BusinessType}
                errorMessage={getErrorMessage(Variables.BorrowerBusinessType)}
              />
              <Input
                label={InputLabels.NatureOfBusiness}
                value={values[Variables.BorrowerNatureOfBusiness]}
                onChange={handleChange}
                name={Variables.BorrowerNatureOfBusiness}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerNatureOfBusiness)}
              />
              <Input
                label={InputLabels.RegisteredBusinessAddress}
                value={values[Variables.BorrowerRegisteredBusinessAddress]}
                onChange={handleChange}
                name={Variables.BorrowerRegisteredBusinessAddress}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerRegisteredBusinessAddress)}
              />
              <Input
                label={InputLabels.OperatingAddress}
                value={values[Variables.BorrowerOperatingAddress]}
                onChange={handleChange}
                name={Variables.BorrowerOperatingAddress}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.BorrowerOperatingAddress)}
              />
              <SelectInput
                selectedOption={getSelectedOptionByValue(
                  COUNTRY_OPTIONS_LIST,
                  values[Variables.BorrowerCountry],
                )}
                onValuePicked={(value) => {
                  setFieldValue(Variables.BorrowerCountry, value);
                }}
                options={COUNTRY_OPTIONS_LIST}
                name={Variables.BorrowerCountry}
                placeholder={InputLabels.Country}
                errorMessage={getErrorMessage(Variables.BorrowerCountry)}
              />
              <ContactInfoFields
                onChange={handleChange}
                onBlur={handleBlur}
                phoneInputName={Variables.BorrowerPhoneNumber}
                emailInputName={Variables.BorrowerEmail}
                phoneInputErrorMessage={getErrorMessage(Variables.BorrowerPhoneNumber)}
                emailInputErrorMessage={getErrorMessage(Variables.BorrowerEmail)}
                phoneNumberValue={values[Variables.BorrowerPhoneNumber]}
                emailValue={values[Variables.BorrowerEmail]}
              />
            </div>

            <div className={styles.buttonContainer}>
              <NextButton onClick={handleNextClick} />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CompanyInformationForm;

import { msalInstance } from 'App';
import { FetchMethodType } from 'enums/FetchMethodTypes';
import { RequestError } from 'errors/RequestError';
import {
  RequestBody,
  getBasicHeaders,
  stringifyRequestBody,
  getResponseErrorData,
} from 'helpers/fetch';
import { getAzureRequestData } from 'helpers/getAzureClientConfiguration';

const FILENAME_FROM_HEADER_PATTERN = /filename="([^"]+)"/;

interface IFetchOptions {
  headers?: Headers;
  method?: FetchMethodType;
  body?: RequestBody;
  resetDefaultHeaders?: boolean;
  checkAuthorization?: boolean;
  responseType?: string;
  includePasswordValidationToken?: boolean;
}

enum ResponseStatuses {
  NotFound = 404,
  Unauthorized = 401,
}

class BaseApi {
  protected async fetch<Body>(url: string, options?: IFetchOptions): Promise<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
    } = options || {};

    const { accessToken } = await msalInstance.acquireTokenSilent(getAzureRequestData());

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    const bearer = `Bearer ${accessToken}`;
    headers.append('Authorization', bearer);

    const response = await fetch(`/api${url}`, {
      method,
      headers,
      body: stringifyRequestBody(body),
    });

    return this.processResponse(response, url, headers, options);
  }

  protected async download(url: string) {
    const response = await this.fetch<Response>(url, {
      responseType: 'blob',
    });

    const [, filename] =
      response.headers.get('Content-Disposition')?.match(FILENAME_FROM_HEADER_PATTERN) || [];

    return {
      file: await response.blob(),
      filename,
    };
  }

  private async processResponse(
    response: Response,
    url: string,
    headers: Headers,
    options?: IFetchOptions,
  ) {
    if (response.ok) {
      if (options?.responseType === 'blob') {
        return response;
      }

      return response.json();
    }

    return this.handleResponseError(response);
  }

  private async handleResponseError(response: Response) {
    if (response.status === ResponseStatuses.Unauthorized) {
      return msalInstance.logout();
    }

    const { message } = await getResponseErrorData(response);

    if (response.status === ResponseStatuses.NotFound) {
      return null;
    }

    throw new RequestError(message, response.status);
  }
}

export default BaseApi;

import React from 'react';
import styles from './PageTitle.module.scss';
import StatusLabel from 'components/StatusLabel';
import { StatusLabelList } from 'enums/StatusLabels';
import { ArrowBack } from 'static/images';

interface IPageTitleProps {
  title: string;
  verificationStatus?: StatusLabelList;
  customStatusLabel?: string;
  backButton?: boolean;
  onBackButtonClick?: () => void;
}

const PageTitle = ({
  title,
  verificationStatus,
  customStatusLabel,
  backButton,
  onBackButtonClick,
}: IPageTitleProps) => {
  return (
    <div className={styles.container}>
      {backButton && (
        <div className={styles.backButton} onClick={onBackButtonClick}>
          <ArrowBack />
          <div className={styles.label}>Back</div>
        </div>
      )}
      <div className={styles.titleContainer}>
        {title}
        {verificationStatus && (
          <StatusLabel
            label={customStatusLabel}
            className={styles.status}
            type={verificationStatus}
          />
        )}
      </div>
    </div>
  );
};

export default PageTitle;

import { CompanyRepresentativeInputValue, TAuthorizationData, TRepresentativeData } from './types';
import {
  DEFAULT_AUTHORIZATION_INFORMATION_VALUES,
  DEFAULT_COMPANY_INFORMATION_VALUES,
  DEFAULT_DIRECTOR_ONE_INFORMATION_VALUES,
  DEFAULT_DIRECTOR_TWO_INFORMATION_VALUES,
  REPRESENTATIVE_INFORMATION_VALUES,
} from './constants';
import React, { useContext, useMemo, useState } from 'react';
import {
  setFirstDirectorDataToRepresentative,
  setSecondDirectorDataToRepresentative,
} from 'helpers/setDirectorsDataToRepresentative';

import { ApiContext } from 'providers/ApiProvider';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import AuthorizationInformation from './Steps/AuthorizationInformation';
import { BorrowerType } from 'types/common';
import { CREATE_ACCOUNT_NAVIGATION_DATA } from 'helpers/constants';
import CompanyInformation from './Steps/CompanyInformation';
import { CreateAccountSteps } from 'enums/CreateAccountSteps';
import DirectorsInformation from './Steps/DirectorsInformation';
import { IBorrower } from 'api/types';
import Layout from 'components/Layout';
import RepresentativeInformation from './Steps/RepresentativeInformation';
import { SecretKeys } from 'enums/SecretKeys';
import { StatusLabelList } from 'enums/StatusLabels';
import { Variables } from 'enums/Variables';
import { convertDateToDigifiFormat } from 'helpers/formatters';
import { getCompanyRepresentativeValues } from 'helpers/getCompanyRepresentativeValues';
import { getSecretValue } from 'helpers/getSecretValue';
import { trimStringValues } from 'helpers/trimStringValues';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const CreateAccount = () => {
  const [isCompanyRepresentativeInputError, setIsCompanyRepresentativeInputError] = useState(false);
  const { instance } = useMsal();
  const azureAccount = instance.getActiveAccount();
  const navigate = useNavigate();

  const { borrowersApi, applicationsApi, documentsApi } = useContext(ApiContext);

  const [selectedCompanyRepresentativeValue, setSelectedCompanyRepresentativeValue] =
    useState<CompanyRepresentativeInputValue | null>(null);

  const [companyInformationData, setCompanyInformationData] = useState(
    DEFAULT_COMPANY_INFORMATION_VALUES,
  );
  const [directorOneInformationData, setDirectorOneInformationData] = useState(
    DEFAULT_DIRECTOR_ONE_INFORMATION_VALUES,
  );
  const [directorTwoInformationData, setDirectorTwoInformationData] = useState(
    DEFAULT_DIRECTOR_TWO_INFORMATION_VALUES,
  );

  const [step, setStep] = useState(CreateAccountSteps.AuthorizationInformation);
  const [completedSteps, setCompletedSteps] = useState<CreateAccountSteps[]>([]);
  const [loading, setLoading] = useState(false);

  const borrowerDirectorTwoExist = Boolean(directorTwoInformationData.borrower_director_2_bvn);

  const companyRepresentativeValues = getCompanyRepresentativeValues(borrowerDirectorTwoExist);

  const getRepresentativeFormData = () => {
    switch (selectedCompanyRepresentativeValue) {
      case CompanyRepresentativeInputValue.DirectorOne:
        return setFirstDirectorDataToRepresentative(directorOneInformationData);
      case CompanyRepresentativeInputValue.DirectorTwo:
        return setSecondDirectorDataToRepresentative(directorTwoInformationData);
      case CompanyRepresentativeInputValue.Other:
      default:
        return REPRESENTATIVE_INFORMATION_VALUES;
    }
  };

  const representativeFormData = getRepresentativeFormData();

  const handleNextStep = (newStep: CreateAccountSteps) => {
    setCompletedSteps([...completedSteps, step]);
    setStep(newStep);
  };

  const handlePrevStep = (newStep: CreateAccountSteps) => {
    setStep(newStep);
  };

  const handleAccountCreate = async (companyRepresentativeData: TRepresentativeData) => {
    try {
      setLoading(true);
      const existingBorrower = (await borrowersApi.findByCompanyRegistrationNumber(
        companyInformationData[Variables.BorrowerCompanyRegistrationNumber],
      )) as IBorrower | null;

      const {
        borrower_director_1_bvn_image,
        borrower_director_1_date_of_birth,
        ...directorOneDataWithoutFiles
      } = directorOneInformationData;
      const {
        borrower_director_2_bvn_image,
        borrower_director_2_date_of_birth,
        ...directorTwoDataWithoutFiles
      } = directorTwoInformationData;
      const {
        borrower_company_representative_bvn_image,
        borrower_company_representative_date_of_birth,
        ...companyRepresentativeDataWithoutFiles
      } = companyRepresentativeData;

      const borrowerCompanyRepresentativeExists =
        selectedCompanyRepresentativeValue === CompanyRepresentativeInputValue.Other;

      const borrowerData = trimStringValues({
        ...companyInformationData,
        ...directorOneDataWithoutFiles,
        ...(borrowerDirectorTwoExist ? directorTwoDataWithoutFiles : {}),
        ...(borrowerCompanyRepresentativeExists ? companyRepresentativeDataWithoutFiles : {}),
        [Variables.BorrowerDirectorTwoExists]: borrowerDirectorTwoExist,
        [Variables.BorrowerCompanyRepresentativeExists]: borrowerCompanyRepresentativeExists,
        [Variables.BorrowerVerificationStatus]: StatusLabelList.InProgress,
        [Variables.BorrowerAzureId]: azureAccount!.localAccountId,
        [Variables.BorrowerCompanyRepresentative]: selectedCompanyRepresentativeValue,
        [Variables.BorrowerDirectorOneIsRepresentative]:
          selectedCompanyRepresentativeValue === CompanyRepresentativeInputValue.DirectorOne,
        [Variables.BorrowerDirectorTwoIsRepresentative]:
          selectedCompanyRepresentativeValue === CompanyRepresentativeInputValue.DirectorTwo,
        [Variables.BorrowerDirectorOneDateOfBirth]: convertDateToDigifiFormat(
          borrower_director_1_date_of_birth,
        ),
        [Variables.BorrowerDirectorTwoDateOfBirth]: borrowerDirectorTwoExist
          ? convertDateToDigifiFormat(borrower_director_2_date_of_birth)
          : undefined,
        [Variables.BorrowerCompanyRepresentativeDateOfBirth]: borrowerCompanyRepresentativeExists
          ? convertDateToDigifiFormat(borrower_company_representative_date_of_birth)
          : undefined,
      });

      let borrower: IBorrower | null = null;

      if (existingBorrower?.id) {
        borrower = await borrowersApi.update(existingBorrower.id, borrowerData);
      } else {
        borrower = await borrowersApi.create({
          variables: borrowerData,
          type: BorrowerType.Company,
        });
      }

      if (!borrower.locked) {
        const applicationData = {
          ...borrowerData,
        };

        const application = await applicationsApi.create({
          variables: applicationData,
          borrower: borrower.id,
          productId: getSecretValue(SecretKeys.BorrowerVerificationProductId),
          coBorrowers: [],
        });

        const filesToUpload = borrower_director_1_bvn_image;

        if (borrowerDirectorTwoExist) {
          filesToUpload.push(borrower_director_2_bvn_image[0]);
        }

        if (borrowerCompanyRepresentativeExists) {
          filesToUpload.push(borrower_company_representative_bvn_image[0]);
        }

        await documentsApi.upload(application.id, filesToUpload);
      }

      navigate(ApplicationRoutes.Verification);
    } catch {
      navigate(ApplicationRoutes.SomethingWentWrong);
    }
  };

  const handleExistingCompanyCreate = async (authorizationData: TAuthorizationData) => {
    try {
      const existingBorrower = await borrowersApi.findByCompanyRegistrationNumber(
        authorizationData[Variables.BorrowerCompanyRegistrationNumber],
      );

      if (existingBorrower?.id) {
        await applicationsApi.create({
          borrower: existingBorrower.id,
          productId: getSecretValue(SecretKeys.BorrowerVerificationProductId),
          variables: {
            ...authorizationData,
            [Variables.BorrowerVerificationStatus]: StatusLabelList.InProgress,
            [Variables.BorrowerAzureId]: azureAccount!.localAccountId,
          },
          coBorrowers: [],
          labelsIds: [getSecretValue(SecretKeys.SuspiciousLabelId)],
        });

        navigate(ApplicationRoutes.Verification);
        return;
      }

      setCompanyInformationData({
        ...DEFAULT_COMPANY_INFORMATION_VALUES,
        [Variables.BorrowerCompanyName]: authorizationData[Variables.BorrowerCompanyName],
        [Variables.BorrowerCompanyRegistrationNumber]:
          authorizationData[Variables.BorrowerCompanyRegistrationNumber],
      });
      setDirectorOneInformationData({
        ...DEFAULT_DIRECTOR_ONE_INFORMATION_VALUES,
        [Variables.BorrowerDirectorOneFirstName]:
          authorizationData[Variables.BorrowerDirectorOneFirstName],
        [Variables.BorrowerDirectorOneLastName]:
          authorizationData[Variables.BorrowerDirectorOneLastName],
      });
      setStep(CreateAccountSteps.CompanyInformation);
    } catch (e) {
      navigate(ApplicationRoutes.SomethingWentWrong);
    }
  };

  const renderCurrentPage = () => {
    switch (step) {
      case CreateAccountSteps.AuthorizationInformation:
        return (
          <AuthorizationInformation
            data={DEFAULT_AUTHORIZATION_INFORMATION_VALUES}
            onNextStep={handleExistingCompanyCreate}
          />
        );
      case CreateAccountSteps.CompanyInformation:
        return (
          <CompanyInformation
            data={companyInformationData}
            onDataUpdate={setCompanyInformationData}
            onNextStep={handleNextStep}
          />
        );
      case CreateAccountSteps.DirectorsInformation:
        return (
          <DirectorsInformation
            directorOneData={directorOneInformationData}
            directorTwoData={directorTwoInformationData}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            onDirectorOneDataUpdate={setDirectorOneInformationData}
            onDirectorTwoDataUpdate={setDirectorTwoInformationData}
          />
        );
      case CreateAccountSteps.RepresentativeInformation:
        return (
          <RepresentativeInformation
            companyRepresentativeValues={companyRepresentativeValues}
            selectedCompanyRepresentativeValue={selectedCompanyRepresentativeValue}
            setSelectedCompanyRepresentativeValue={(value: CompanyRepresentativeInputValue) =>
              setSelectedCompanyRepresentativeValue(value)
            }
            onPrevStep={handlePrevStep}
            data={representativeFormData as TRepresentativeData}
            onAccountCreate={handleAccountCreate}
            isCompanyRepresentativeInputError={isCompanyRepresentativeInputError}
            setIsCompanyRepresentativeInputError={setIsCompanyRepresentativeInputError}
          />
        );
    }
  };

  const renderPageTitle = () => {
    switch (step) {
      case CreateAccountSteps.AuthorizationInformation:
        return 'Please provide Authentication Information';
      case CreateAccountSteps.CompanyInformation:
        return 'Please provide Company Information';
      case CreateAccountSteps.DirectorsInformation:
        return 'Please Provide Director(s) Information';
      case CreateAccountSteps.RepresentativeInformation:
        return 'Please Provide Representative Information';
    }
  };

  const leftNavData = useMemo(() => {
    if (step === CreateAccountSteps.AuthorizationInformation) {
      const { description, title } = CREATE_ACCOUNT_NAVIGATION_DATA;
      return { description, title };
    }
    const subLinks = CREATE_ACCOUNT_NAVIGATION_DATA.subLinks?.map(({ name }) => {
      const isCompleted = completedSteps.includes(name as CreateAccountSteps);
      const isActive = name === step;

      const disabled = !isCompleted && !isActive;

      return {
        name,
        isCompleted,
        isActive,
        disabled,
        onClick: () => {
          setStep(name as CreateAccountSteps);
        },
      };
    });

    return {
      ...CREATE_ACCOUNT_NAVIGATION_DATA,
      subLinks,
    };
  }, [step]);

  return (
    <Layout navData={leftNavData} loading={loading} pageTitle={renderPageTitle()}>
      {renderCurrentPage()}
    </Layout>
  );
};

export default CreateAccount;

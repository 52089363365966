import React from 'react';
import styles from './Loader.module.scss';
import { LoaderIcon } from 'static/images';

const Loader = () => {
  return (
    <div className={styles.container}>
      <LoaderIcon />
    </div>
  );
};

export default Loader;

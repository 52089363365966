import { DocumentsUploadFormVariables } from 'enums/DocumentsUploadFormVariables';
import FileUploadInput from 'components/FileUploadInput';
import { Formik } from 'formik';
import { InputLabels } from 'enums/InputLabels';
import NextButton from 'components/NextButton';
import PrevButton from 'components/PrevButton';
import React from 'react';
import { TOptionalString } from 'types/common';
import { TWorkingCapitalUploadDocumentData } from 'pages/SubmitLoanApplication/types';
import styles from './DocumentsUploadForm.module.scss';
import { workingCapitalDocumentsUploadFormValidationSchema } from './validationSchema';

interface IWorkingCapitalDocumentsUploadFormProps {
  data: TWorkingCapitalUploadDocumentData;
  onPrevStep: () => void;
  onSubmit: (data: TWorkingCapitalUploadDocumentData) => void;
}

const WorkingCapitalDocumentsUploadForm = ({
  data,
  onPrevStep,
  onSubmit,
}: IWorkingCapitalDocumentsUploadFormProps) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={workingCapitalDocumentsUploadFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue, touched, errors, submitForm }) => {
        const getErrorMessage = (name: keyof TWorkingCapitalUploadDocumentData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleNextClick = async () => {
          await submitForm();
        };

        return (
          <>
            <div className={styles.container}>
              <FileUploadInput
                label={InputLabels.ProFormaInvoice}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.ProFormaInvoice, value);
                }}
                error={
                  getErrorMessage(DocumentsUploadFormVariables.ProFormaInvoice) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.ProFormaInvoice]}
                name={DocumentsUploadFormVariables.ProFormaInvoice}
                limit={false}
              />
              <FileUploadInput
                label={InputLabels.FinancialStatement}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.FinancialStatement, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.FinancialStatement,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.FinancialStatement]}
                name={DocumentsUploadFormVariables.FinancialStatement}
                limit={false}
              />
              <FileUploadInput
                label={InputLabels.BankStatement}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.BankStatement, value);
                }}
                error={
                  getErrorMessage(DocumentsUploadFormVariables.BankStatement) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.BankStatement]}
                name={DocumentsUploadFormVariables.BankStatement}
                limit={false}
              />
              <FileUploadInput
                label={InputLabels.CompanyOrManagementProfile}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.CompanyOrManagementProfile, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.CompanyOrManagementProfile,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.CompanyOrManagementProfile]}
                name={DocumentsUploadFormVariables.CompanyOrManagementProfile}
                limit={false}
              />
              <FileUploadInput
                label={InputLabels.PreviouslyExecutedContracts}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.PreviouslyExecutedContracts, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.PreviouslyExecutedContracts,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.PreviouslyExecutedContracts]}
                name={DocumentsUploadFormVariables.PreviouslyExecutedContracts}
                limit={false}
              />
              <FileUploadInput
                label={InputLabels.FormalApplicationOrBoardResolution}
                onFileChange={(value) => {
                  setFieldValue(
                    DocumentsUploadFormVariables.FormalApplicationOrBoardResolution,
                    value,
                  );
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.FormalApplicationOrBoardResolution,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.FormalApplicationOrBoardResolution]}
                name={DocumentsUploadFormVariables.FormalApplicationOrBoardResolution}
                limit={false}
              />
              <FileUploadInput
                label={InputLabels.CashFlowProjection}
                onFileChange={(value) => {
                  setFieldValue(DocumentsUploadFormVariables.CashFlowProjection, value);
                }}
                error={
                  getErrorMessage(
                    DocumentsUploadFormVariables.CashFlowProjection,
                  ) as TOptionalString
                }
                files={values[DocumentsUploadFormVariables.CashFlowProjection]}
                name={DocumentsUploadFormVariables.CashFlowProjection}
                limit={false}
              />
            </div>
            <div className={styles.buttonsContainer}>
              <PrevButton onClick={onPrevStep} />
              <NextButton title="Submit Application" onClick={handleNextClick} />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkingCapitalDocumentsUploadForm;

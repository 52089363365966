import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import WelcomePage from 'pages/WelcomePage/WelcomePage';
import CreateAccount from 'pages/CreateAccount/CreateAccount';
import Verification from 'pages/Verification';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import Redirect from 'pages/Redirect/Redirect';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import SubmitLoanApplication from 'pages/SubmitLoanApplication/SubmitLoanApplication';
import Applications from 'pages/Applications';
import ApplicationView from 'pages/ApplicationView/ApplicationView';
import NotFound from 'pages/Errors/NotFound';
import SomethingWentWrong from 'pages/Errors/SomethingWentWrong';
import VerificationApplicationContextLayout from 'components/VerificationApplicationContextLayout';
import LockedBorrower from 'pages/Errors/LockedBorrower';

const CheckAuth = () => {
  const isAuth = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();

  if (!isAuth && inProgress === InteractionStatus.None && accounts.length === 0) {
    return <Navigate to={ApplicationRoutes.Welcome} />;
  }

  return <Outlet />;
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ApplicationRoutes.Welcome} element={<WelcomePage />} />
        <Route element={<CheckAuth />}>
          <Route path={ApplicationRoutes.CreateAccount} element={<CreateAccount />} />
          <Route path={ApplicationRoutes.Redirect} element={<Redirect />} />
          <Route element={<VerificationApplicationContextLayout />}>
            <Route path={ApplicationRoutes.Verification} element={<Verification />} />
            <Route path={ApplicationRoutes.SubmitApplication} element={<SubmitLoanApplication />} />
            <Route path={ApplicationRoutes.Applications} element={<Applications />} />
            <Route
              path={`${ApplicationRoutes.Applications}/:applicationId`}
              element={<ApplicationView />}
            />
          </Route>
        </Route>
        <Route path={ApplicationRoutes.SomethingWentWrong} element={<SomethingWentWrong />} />
        <Route path={ApplicationRoutes.LockedBorrower} element={<LockedBorrower />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

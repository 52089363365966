import clsx from 'clsx';
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { FCWithChildren } from 'types/common';
import styles from './Button.module.scss';

export interface IButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

const Button: FCWithChildren<IButtonProps> = ({ children, className, ...props }: IButtonProps) => {
  return (
    <button {...props} className={clsx(styles.button, className)}>
      {children}
    </button>
  );
};

export default Button;

export enum ApplicationRoutes {
  CreateAccount = '/create-account',
  Verification = '/verification',
  Applications = '/applications',
  SubmitApplication = '/applications/submit',
  Redirect = '/redirect',
  Welcome = '/',
  SomethingWentWrong = '/something-went-wrong',
  LockedBorrower = '/locked-borrower',
}

import { Variables } from 'enums/Variables';
import { TFirstDirectorData, TSecondDirectorData } from 'pages/CreateAccount/types';

export const setFirstDirectorDataToRepresentative = (directorsData: TFirstDirectorData) => {
  return {
    [Variables.BorrowerCompanyRepresentativeFirstName]:
      directorsData[Variables.BorrowerDirectorOneFirstName],
    [Variables.BorrowerCompanyRepresentativeMiddleName]:
      directorsData[Variables.BorrowerDirectorOneMiddleName],
    [Variables.BorrowerCompanyRepresentativeLastName]:
      directorsData[Variables.BorrowerDirectorOneLastName],
    [Variables.BorrowerCompanyRepresentativeDateOfBirth]:
      directorsData[Variables.BorrowerDirectorOneDateOfBirth],
    [Variables.BorrowerCompanyRepresentativeBvn]:
      directorsData[Variables.BorrowerDirectorOneBankVerificationNumber],
    [Variables.BorrowerCompanyRepresentativeEmail]:
      directorsData[Variables.BorrowerDirectorOneEmailAddress],
    [Variables.BorrowerCompanyRepresentativePhoneNumber]:
      directorsData[Variables.BorrowerDirectorOnePhoneNumber],
    [Variables.BorrowerCompanyRepresentativeResidentialAddress]:
      directorsData[Variables.BorrowerDirectorOneResidentialAddress],
    [Variables.BorrowerCompanyRepresentativeIdDocumentType]:
      directorsData[Variables.BorrowerDirectorOneIdDocumentType],
    [Variables.BorrowerCompanyRepresentativeBvnImage]: [
      ...directorsData[Variables.BorrowerDirectorOneBvnImage],
    ],
    [Variables.BorrowerCompanyRepresentativeNationalIdNumber]:
      directorsData[Variables.BorrowerDirectorOneNationalIdNumber],
    [Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber]:
      directorsData[Variables.BorrowerDirectorOneVirtualNationalIdNumber],
  };
};

export const setSecondDirectorDataToRepresentative = (directorsData: TSecondDirectorData) => {
  return {
    [Variables.BorrowerCompanyRepresentativeFirstName]:
      directorsData[Variables.BorrowerDirectorTwoFirstName],
    [Variables.BorrowerCompanyRepresentativeMiddleName]:
      directorsData[Variables.BorrowerDirectorTwoMiddleName],
    [Variables.BorrowerCompanyRepresentativeLastName]:
      directorsData[Variables.BorrowerDirectorTwoLastName],
    [Variables.BorrowerCompanyRepresentativeDateOfBirth]:
      directorsData[Variables.BorrowerDirectorTwoDateOfBirth],
    [Variables.BorrowerCompanyRepresentativeBvn]:
      directorsData[Variables.BorrowerDirectorTwoBankVerificationNumber],
    [Variables.BorrowerCompanyRepresentativeEmail]:
      directorsData[Variables.BorrowerDirectorTwoEmailAddress],
    [Variables.BorrowerCompanyRepresentativePhoneNumber]:
      directorsData[Variables.BorrowerDirectorTwoPhoneNumber],
    [Variables.BorrowerCompanyRepresentativeResidentialAddress]:
      directorsData[Variables.BorrowerDirectorTwoResidentialAddress],
    [Variables.BorrowerCompanyRepresentativeIdDocumentType]:
      directorsData[Variables.BorrowerDirectorTwoIdDocumentType],
    [Variables.BorrowerCompanyRepresentativeBvnImage]: [
      ...directorsData[Variables.BorrowerDirectorTwoBvnImage],
    ],
    [Variables.BorrowerCompanyRepresentativeNationalIdNumber]:
      directorsData[Variables.BorrowerDirectorTwoNationalIdNumber],
    [Variables.BorrowerCompanyRepresentativeVirtualNationalIdNumber]:
      directorsData[Variables.BorrowerDirectorTwoVirtualNationalIdNumber],
  };
};

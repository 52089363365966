import React from 'react';
import styles from './BusinessLoanStatusLabel.module.scss';
import { BusinessLoanProductApplicationStatuses } from 'enums/BusinessLoanProductApplicationStatuses';
import clsx from 'clsx';

export const IN_PROGRESS_BUSINESS_LOAN_APPLICATION_STATUSES = [
  BusinessLoanProductApplicationStatuses.CreditAssessment,
  BusinessLoanProductApplicationStatuses.HeadCreditApproval,
  BusinessLoanProductApplicationStatuses.NewApplication,
  BusinessLoanProductApplicationStatuses.CreditReview,
  BusinessLoanProductApplicationStatuses.DirectorFinancialServicesApproval,
  BusinessLoanProductApplicationStatuses.ExecutionOfPreDisbursementDocuments,
  BusinessLoanProductApplicationStatuses.DisbursementOfFunds,
  BusinessLoanProductApplicationStatuses.CollateralManagerVisit,
];

interface IBusinessLoanStatusLabelProps {
  statusName: BusinessLoanProductApplicationStatuses;
}

const BusinessLoanStatusLabel = ({ statusName }: IBusinessLoanStatusLabelProps) => {
  const isInProgressStatus = IN_PROGRESS_BUSINESS_LOAN_APPLICATION_STATUSES.includes(statusName);
  const isApprovedStatus = statusName === BusinessLoanProductApplicationStatuses.Approved;
  const isRejectedStatus = statusName === BusinessLoanProductApplicationStatuses.Rejected;

  const containerClassName = clsx(
    styles.container,
    isInProgressStatus && styles.inProgress,
    isApprovedStatus && styles.approved,
    isRejectedStatus && styles.rejected,
  );

  return <div className={containerClassName}>{statusName}</div>;
};

export default BusinessLoanStatusLabel;

import React, { useEffect, useState } from 'react';
import Input from 'components/Input';
import NextButton from 'components/NextButton';
import NumberInput from 'components/NumberInput';
import SelectInput from 'components/SelectInput';
import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import { Formik } from 'formik';
import { getSelectedOptionByValue } from 'helpers/getSelectedOptionByValue';
import { TCommonProductInformationData } from 'pages/SubmitLoanApplication/types';
import styles from './ProductInformationForm.module.scss';
import {
  MAX_L0AN_AMOUNT_VALUE,
  PRODUCT_TYPE_OPTIONS_LIST,
  PRODUCT_UNITS_OPTION_LIST,
  ProductTypes,
  TRANSACTION_TYPE_OPTIONS_LIST,
  getProductList,
} from './constants';
import { commonProductInformationFormValidationSchema } from './validationSchema';
import { DEFAULT_CURRENCY } from 'helpers/constants';
import PrevButton from 'components/PrevButton';

interface ICommonProductInformationForm {
  data: TCommonProductInformationData;
  onSubmit: (data: TCommonProductInformationData) => void;
  onPrevStep: () => void;
}

const CommonProductInformationForm = ({
  data,
  onSubmit,
  onPrevStep,
}: ICommonProductInformationForm) => {
  const [productType, setProductType] = useState<ProductTypes>(
    data[Variables.ProductTypeList] as ProductTypes,
  );
  const [isProductOtherSelected, setIsProductOtherSelected] = useState(false);

  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={commonProductInformationFormValidationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue, touched, errors, handleChange, handleBlur, submitForm }) => {
        const getErrorMessage = (name: keyof TCommonProductInformationData) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        const handleNextClick = async () => {
          await submitForm();
        };

        useEffect(() => {
          if (productType === ProductTypes.Other) {
            setFieldValue(Variables.ProductList, 'Other');
          }
        }, [productType]);

        return (
          <>
            <div className={styles.container}>
              <div className={styles.title}>Product Information</div>
              <SelectInput
                selectedOption={getSelectedOptionByValue(
                  PRODUCT_TYPE_OPTIONS_LIST,
                  values[Variables.ProductTypeList],
                )}
                onValuePicked={(value) => {
                  setFieldValue(Variables.ProductTypeList, value);
                  setProductType(value as ProductTypes);
                }}
                options={PRODUCT_TYPE_OPTIONS_LIST}
                name={Variables.ProductTypeList}
                placeholder={InputLabels.ProductType}
                errorMessage={getErrorMessage(Variables.ProductTypeList)}
              />
              {productType === ProductTypes.Other && (
                <>
                  <Input
                    label={InputLabels.OtherProductType}
                    value={values[Variables.ProductTypeOther]}
                    onChange={handleChange}
                    name={Variables.ProductTypeOther}
                    onBlur={handleBlur}
                    error={getErrorMessage(Variables.ProductTypeOther)}
                  />
                  <Input
                    label={InputLabels.OtherProduct}
                    value={values[Variables.ProductOther]}
                    onChange={handleChange}
                    name={Variables.ProductOther}
                    onBlur={handleBlur}
                    error={getErrorMessage(Variables.ProductOther)}
                  />
                </>
              )}
              <SelectInput
                selectedOption={getSelectedOptionByValue(
                  TRANSACTION_TYPE_OPTIONS_LIST,
                  values[Variables.TransactionType],
                )}
                onValuePicked={(value) => {
                  setFieldValue(Variables.TransactionType, value);
                }}
                options={TRANSACTION_TYPE_OPTIONS_LIST}
                name={Variables.TransactionType}
                placeholder={InputLabels.TransactionType}
                errorMessage={getErrorMessage(Variables.TransactionType)}
              />
              {productType && productType !== ProductTypes.Other && (
                <SelectInput
                  selectedOption={getSelectedOptionByValue(
                    getProductList(productType),
                    values[Variables.ProductList],
                  )}
                  onValuePicked={(value) => {
                    setFieldValue(Variables.ProductList, value);
                    setIsProductOtherSelected(value === 'Other');
                  }}
                  options={getProductList(productType)}
                  name={Variables.ProductList}
                  placeholder={InputLabels.Product}
                  errorMessage={getErrorMessage(Variables.ProductList)}
                />
              )}
              {isProductOtherSelected && (
                <Input
                  label={InputLabels.OtherProduct}
                  value={values[Variables.ProductOther]}
                  onChange={handleChange}
                  name={Variables.ProductOther}
                  onBlur={handleBlur}
                  error={getErrorMessage(Variables.ProductOther)}
                />
              )}
              <SelectInput
                selectedOption={getSelectedOptionByValue(
                  PRODUCT_UNITS_OPTION_LIST,
                  values[Variables.ProductUnits],
                )}
                onValuePicked={(value) => {
                  setFieldValue(Variables.ProductUnits, value);
                }}
                options={PRODUCT_UNITS_OPTION_LIST}
                name={Variables.ProductUnits}
                placeholder={InputLabels.ProductUnits}
                errorMessage={getErrorMessage(Variables.ProductUnits)}
              />
              <NumberInput
                label={InputLabels.ProductQuantity}
                value={values[Variables.ProductQuantity]}
                onChange={handleChange}
                name={Variables.ProductQuantity}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.ProductQuantity)}
              />
              <NumberInput
                label={InputLabels.ProductUnitPurchasePrice}
                value={values[Variables.ProductUnitPurchasePrice]}
                onChange={handleChange}
                thousandSeparator
                prefix={DEFAULT_CURRENCY}
                name={Variables.ProductUnitPurchasePrice}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.ProductUnitPurchasePrice)}
              />
              <NumberInput
                label={InputLabels.ProductUnitSellingPrice}
                value={values[Variables.ProductUnitSellingPrice]}
                onChange={handleChange}
                thousandSeparator
                prefix={DEFAULT_CURRENCY}
                name={Variables.ProductUnitSellingPrice}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.ProductUnitSellingPrice)}
              />
              <NumberInput
                label={InputLabels.LogisticsCostPerTruck}
                value={values[Variables.LogisticsCostPerTruck]}
                onChange={handleChange}
                thousandSeparator
                prefix={DEFAULT_CURRENCY}
                name={Variables.LogisticsCostPerTruck}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.LogisticsCostPerTruck)}
              />
              <NumberInput
                label={InputLabels.LogisticsNumberOfTrucks}
                value={values[Variables.LogisticsNumberOfTrucks]}
                onChange={handleChange}
                name={Variables.LogisticsNumberOfTrucks}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.LogisticsNumberOfTrucks)}
              />
              <NumberInput
                label={InputLabels.OtherCostsAmount}
                value={values[Variables.OtherCostsAmount]}
                onChange={handleChange}
                thousandSeparator
                prefix={DEFAULT_CURRENCY}
                name={Variables.OtherCostsAmount}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OtherCostsAmount)}
                optional
              />
              <Input
                label={InputLabels.OtherCostsDescription}
                value={values[Variables.OtherCostsDescription]}
                onChange={handleChange}
                name={Variables.OtherCostsDescription}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OtherCostsDescription)}
                optional
              />
              <NumberInput
                label={InputLabels.RequestAmount}
                value={values[Variables.LoanAmount]}
                onChange={handleChange}
                thousandSeparator
                prefix={DEFAULT_CURRENCY}
                name={Variables.LoanAmount}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.LoanAmount)}
                max={MAX_L0AN_AMOUNT_VALUE}
              />
              <Input
                label={InputLabels.SupplierAddress}
                value={values[Variables.SupplierAddress]}
                onChange={handleChange}
                name={Variables.SupplierAddress}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.SupplierAddress)}
              />
              <NumberInput
                label={InputLabels.PaymentTenorDays}
                value={values[Variables.PaymentTenorInDays]}
                onChange={handleChange}
                name={Variables.PaymentTenorInDays}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.PaymentTenorInDays)}
              />
              <Input
                label={InputLabels.OfftakersName}
                value={values[Variables.OfftakerName]}
                onChange={handleChange}
                name={Variables.OfftakerName}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OfftakerName)}
              />
              <Input
                label={InputLabels.OfftakersAddress}
                value={values[Variables.OfftakerAddress]}
                onChange={handleChange}
                name={Variables.OfftakerAddress}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OfftakerAddress)}
              />
              <Input
                label={InputLabels.OfftakersEmail}
                value={values[Variables.OfftakerEmail]}
                onChange={handleChange}
                name={Variables.OfftakerEmail}
                onBlur={handleBlur}
                error={getErrorMessage(Variables.OfftakerEmail)}
              />
            </div>

            <div className={styles.buttonContainer}>
              <PrevButton onClick={onPrevStep} />

              <NextButton onClick={handleNextClick} />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CommonProductInformationForm;

import React from 'react';
import styles from './ErrorLayout.module.scss';
import NextButton from 'components/NextButton';
import SupportEmail from 'components/SupportEmail';
import { LogoErrorIcon, SunErrorIcon } from 'static/images';

interface IErrorPageLayout {
  title: string;
  subTitle: string;
  description: string;
  onClick: () => void;
}

const ErrorLayout = ({ title, subTitle, description, onClick }: IErrorPageLayout) => {
  return (
    <div className={styles.container}>
      <LogoErrorIcon className={styles.sabiLogo} />
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
        <div className={styles.description}>{description}</div>
        <NextButton onClick={onClick} title="Back to Home" className={styles.button} />
      </div>
      <SupportEmail className={styles.email} />
      <SunErrorIcon className={styles.sunImage} />
    </div>
  );
};

export default ErrorLayout;

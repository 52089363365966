import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import { INavigation } from 'components/Layout/Navigation/Navigation';

export const SUPPORT_EMAIL = 'support@sabi.am';
export const DEFAULT_CURRENCY = '₦';

export const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;

export const CREATE_ACCOUNT_NAVIGATION_DATA: INavigation = {
  title: 'Sabi Application Portal',
  description:
    'Welcome to the Sabi Application Portal, please capture all information to create your account.',
  links: [],
  subLinks: [
    {
      name: 'Company Information',
      isCompleted: true,
      isActive: false,
      disabled: false,
      onClick: () => {},
    },
    {
      name: 'Director(s) Information',
      isCompleted: false,
      isActive: true,
      disabled: false,
      onClick: () => {},
    },
    {
      name: 'Representative Information',
      isCompleted: false,
      isActive: false,
      disabled: true,
      onClick: () => {},
    },
  ],
};

export const NAVIGATION_WITH_ACCOUNT_DATA: INavigation = {
  title: '',
  description: '',
  links: [
    {
      name: 'Verification',
      isActive: false,
      status: '',
      route: ApplicationRoutes.Verification,
      onClick: () => {},
    },
    {
      name: 'Applications',
      isActive: false,
      status: '',
      route: ApplicationRoutes.Applications,
      onClick: () => {},
    },
  ],
  subLinks: [
    {
      name: 'Select Credit Product',
      isCompleted: true,
      isActive: false,
      disabled: false,
      onClick: () => {},
    },
    {
      name: 'Product Information',
      isCompleted: false,
      isActive: false,
      disabled: false,
      onClick: () => {},
    },
    {
      name: 'Bank Account',
      isCompleted: false,
      isActive: false,
      disabled: false,
      onClick: () => {},
    },
    {
      name: 'Upload Document',
      isCompleted: false,
      isActive: false,
      disabled: true,
      onClick: () => {},
    },
  ],
};

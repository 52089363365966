import React, { useContext, useEffect, useMemo, useState } from 'react';

import AddApplicationButton from 'components/AddApplicationButton';
import { ApiContext } from 'providers/ApiProvider';
import ApplicationCard from 'components/ApplicationCard';
import { ApplicationRoutes } from 'enums/ApplicationRoutes';
import { BusinessLoanProductApplicationStatuses } from 'enums/BusinessLoanProductApplicationStatuses';
import { CreditProducts } from 'enums/CreditProducts';
import { IApplication } from 'api/types';
import { IN_PROGRESS_BUSINESS_LOAN_APPLICATION_STATUSES } from 'components/BusinessLoanStatusLabel/BusinessLoanStatusLabel';
import Layout from 'components/Layout';
import { NAVIGATION_WITH_ACCOUNT_DATA } from 'helpers/constants';
import NextButton from 'components/NextButton/NextButton';
import { SecretKeys } from 'enums/SecretKeys';
import StatusLabel from 'components/StatusLabel';
import { StatusLabelList } from 'enums/StatusLabels';
import { Variables } from 'enums/Variables';
import { VerificationApplicationContext } from 'providers/VerificationApplicationProvider';
import { getSecretValue } from 'helpers/getSecretValue';
import styles from './Applications.module.scss';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Applications';
const NOT_VERIFIED_MESSAGE =
  'You are unable to submit a loan application until your profile has been verified.';

const GO_TO_VERIFICATION_BUTTON_TITLE = 'Go to Verification';

const NotVerifiedContent = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <div className={styles.notVerifiedMessage}>{NOT_VERIFIED_MESSAGE}</div>
      <NextButton title={GO_TO_VERIFICATION_BUTTON_TITLE} onClick={onClick} />
    </>
  );
};

const Applications = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { applicationsApi } = useContext(ApiContext);
  const { setVerificationApplicationStatus, verificationStatus } = useContext(
    VerificationApplicationContext,
  );

  const acc = instance.getActiveAccount();
  const azureId = acc?.localAccountId;

  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState<IApplication[]>([]);
  const [isVerified, setIsVerified] = useState(true);
  const [hasActiveApplication, setHsActiveApplication] = useState(false);

  const getApplicationsData = async () => {
    if (azureId) {
      setLoading(true);

      const [borrowerVerificationApplication] = await applicationsApi.find(
        azureId,
        getSecretValue(SecretKeys.BorrowerVerificationProductId),
      );

      if (
        borrowerVerificationApplication?.variables[Variables.BorrowerVerificationStatus] !==
        StatusLabelList.Verified
      ) {
        setIsVerified(false);
        setLoading(false);
        return;
      }

      const status = borrowerVerificationApplication.variables[
        Variables.BorrowerVerificationStatus
      ] as StatusLabelList;

      setVerificationApplicationStatus(status);

      const registrationNumber = borrowerVerificationApplication.variables[
        Variables.BorrowerCompanyRegistrationNumber
      ] as string;

      const commodityFinancingApplications = await applicationsApi.findByRegistrationNumber(
        registrationNumber,
        getSecretValue(SecretKeys.BusinessLoanProductId),
      );

      const activeApplicationExists = commodityFinancingApplications.some((application) =>
        IN_PROGRESS_BUSINESS_LOAN_APPLICATION_STATUSES.includes(
          application.status.name as BusinessLoanProductApplicationStatuses,
        ),
      );
      setHsActiveApplication(activeApplicationExists);

      setApplications(commodityFinancingApplications);

      setLoading(false);
    }
  };

  const handleAddApplication = () => {
    navigate(ApplicationRoutes.SubmitApplication);
  };

  const handleGoToVerification = () => {
    navigate(ApplicationRoutes.Verification);
  };

  useEffect(() => {
    getApplicationsData();
  }, []);

  const renderContent = () => {
    if (!isVerified) {
      return <NotVerifiedContent onClick={handleGoToVerification} />;
    }

    return (
      <>
        {hasActiveApplication ? null : <AddApplicationButton onClick={handleAddApplication} />}
        {applications.map((application) => {
          const { variables, displayId, id, createdAt, status } = application;

          return (
            <ApplicationCard
              creditProduct={variables[Variables.ProductCategory] as CreditProducts}
              key={id}
              displayId={displayId}
              onClick={() => navigate(`${ApplicationRoutes.Applications}/${displayId}`)}
              status={status.name as BusinessLoanProductApplicationStatuses}
              loanAmount={variables[Variables.LoanAmount] as number}
              loanRate={variables[Variables.LoanRate] as number}
              loanTerm={variables[Variables.PaymentTenorInDays] as number}
              createdAt={createdAt}
              id={id}
            />
          );
        })}
      </>
    );
  };

  const navData = useMemo(() => {
    const links = NAVIGATION_WITH_ACCOUNT_DATA.links?.map(({ name, route }) => {
      const isApplicationsPage = route === ApplicationRoutes.Applications;
      const isVerificationStep = route === ApplicationRoutes.Verification;

      return {
        name,
        isActive: isApplicationsPage,
        status: isVerificationStep && verificationStatus && (
          <StatusLabel isTransparent type={verificationStatus} />
        ),
        route,
        onClick: () => navigate(route),
      };
    });

    return {
      ...NAVIGATION_WITH_ACCOUNT_DATA,
      subLinks: [],
      links,
    };
  }, [verificationStatus]);

  return (
    <Layout
      navData={navData}
      loading={loading}
      pageTitle={PAGE_TITLE}
      verificationStatus={verificationStatus}
    >
      {renderContent()}
    </Layout>
  );
};

export default Applications;

import {
  BusinessLoanProductApplicationLabels,
  BusinessLoanProductApplicationStatuses,
} from 'enums/BusinessLoanProductApplicationStatuses';

import { FileNames } from 'enums/FileNames';
import { InputLabels } from 'enums/InputLabels';
import { StatusLabelList } from 'enums/StatusLabels';
import { Variables } from 'enums/Variables';

export const LABELS_TO_WORKING_CAPITAL_INPUTS_PRODUCT_INFORMATION = {
  [InputLabels.RequestAmount]: Variables.LoanAmount,
  [InputLabels.PaymentTenorDays]: Variables.PaymentTenorInDays,
  [InputLabels.OfftakersName]: Variables.OfftakerName,
  [InputLabels.OfftakersAddress]: Variables.OfftakerAddress,
  [InputLabels.OfftakersEmail]: Variables.OfftakerEmail,
};

export const LABELS_TO_INVOICE_DISCOUNTING_INPUTS_PRODUCT_INFORMATION = {
  [InputLabels.RequestAmount]: Variables.LoanAmount,
  [InputLabels.SupplierAddress]: Variables.SupplierAddress,
  [InputLabels.PaymentTenorDays]: Variables.PaymentTenorInDays,
  [InputLabels.OfftakersName]: Variables.OfftakerName,
  [InputLabels.OfftakersAddress]: Variables.OfftakerAddress,
  [InputLabels.OfftakersEmail]: Variables.OfftakerEmail,
};

export const LABELS_TO_COMMON_INPUTS_PRODUCT_INFORMATION = {
  [InputLabels.ProductType]: Variables.ProductList,
  [InputLabels.TransactionType]: Variables.TransactionType,
  [InputLabels.Product]: Variables.ProductList,
  [InputLabels.ProductUnits]: Variables.ProductUnits,
  [InputLabels.ProductQuantity]: Variables.ProductQuantity,
  [InputLabels.ProductUnitPurchasePrice]: Variables.ProductUnitPurchasePrice,
  [InputLabels.ProductUnitSellingPrice]: Variables.ProductUnitSellingPrice,
  [InputLabels.LogisticsCostPerTruck]: Variables.LogisticsCostPerTruck,
  [InputLabels.LogisticsNumberOfTrucks]: Variables.LogisticsNumberOfTrucks,
  [InputLabels.OtherCostsAmount]: Variables.OtherCostsAmount,
  [InputLabels.OtherCostsDescription]: Variables.OtherCostsDescription,
  [InputLabels.RequestAmount]: Variables.LoanAmount,
  [InputLabels.SupplierAddress]: Variables.SupplierAddress,
  [InputLabels.PaymentTenorDays]: Variables.PaymentTenorInDays,
  [InputLabels.OfftakersName]: Variables.OfftakerName,
  [InputLabels.OfftakersAddress]: Variables.OfftakerAddress,
  [InputLabels.OfftakersEmail]: Variables.OfftakerEmail,
};

export const LABELS_TO_INPUTS_BANK_ACCOUNT = {
  [InputLabels.BankAccountName]: Variables.BankAccountName,
  [InputLabels.BankAccountNumber]: Variables.BankAccountNumber,
  [InputLabels.BankName]: Variables.BankName,
};

export const LABELS_TO_WORKING_CAPITAL_UPLOAD_DOCUMENT = {
  [InputLabels.ProFormaInvoice]: FileNames.ProFormaInvoice,
  [InputLabels.FinancialStatement]: FileNames.AuditedFinancialStatement,
  [InputLabels.BankStatement]: FileNames.BankStatement,
  [InputLabels.CompanyOrManagementProfile]: FileNames.CompanyOrManagementProfile,
  [InputLabels.PreviouslyExecutedContracts]: FileNames.PreviouslyExecutedContracts,
  [InputLabels.FormalApplicationOrBoardResolution]: FileNames.FormalApplicationOrBoardResolution,
  [InputLabels.CashFlowProjection]: FileNames.CashFlowProjection,
};

export const LABELS_TO_INVOICE_DISCOUNTING_UPLOAD_DOCUMENT = {
  [InputLabels.InvoiceStampedByPrincipal]: FileNames.InvoiceStampedByPrincipal,
  [InputLabels.FinancialStatement]: FileNames.AuditedFinancialStatement,
  [InputLabels.CompanyOrManagementProfile]: FileNames.CompanyOrManagementProfile,
  [InputLabels.EvidenceOfPastSupplies]: FileNames.EvidenceOfPastSupplies,
  [InputLabels.FormalApplicationOrBoardResolution]: FileNames.FormalApplicationOrBoardResolution,
  [InputLabels.CopyOfDeliveryNote]: FileNames.CopyOfDeliveryNote,
};

export const LABELS_TO_COMMON_NAMES_UPLOAD_DOCUMENT = {
  [InputLabels.PurchaseOrder]: FileNames.PurchaseOrder,
  [InputLabels.ContractAgreement]: FileNames.TradeContractAgreement,
  [InputLabels.Invoice]: FileNames.Invoice,
  [InputLabels.FinancialStatement]: FileNames.AuditedFinancialStatement,
  [InputLabels.StatementOfAccount]: FileNames.StatementOfAccount,
  [InputLabels.CompanyOrManagementProfile]: FileNames.CompanyOrManagementProfile,
  [InputLabels.PreviouslyExecutedContracts]: FileNames.PreviouslyExecutedContracts,
};

export const MONETARY_VARIABLE_INPUT_LABELS = [
  Variables.ProductUnitPurchasePrice,
  Variables.ProductUnitSellingPrice,
  Variables.LogisticsCostPerTruck,
  Variables.OtherCostsAmount,
  Variables.LoanAmount,
];

export const BUSINESS_LOAN_STATUSES_TO_LABEL = {
  [BusinessLoanProductApplicationStatuses.NewApplication]:
    BusinessLoanProductApplicationLabels.ApplicationSubmitted,
  [BusinessLoanProductApplicationStatuses.CreditReview]:
    BusinessLoanProductApplicationLabels.CreditReview,
  [BusinessLoanProductApplicationStatuses.CreditAssessment]:
    BusinessLoanProductApplicationLabels.CreditAssessment,
  [BusinessLoanProductApplicationStatuses.HeadCreditApproval]:
    BusinessLoanProductApplicationLabels.CreditApproval,
  [BusinessLoanProductApplicationStatuses.ChiefCommercialOfficerApproval]:
    BusinessLoanProductApplicationLabels.CreditApproval,
  [BusinessLoanProductApplicationStatuses.DirectorFinancialServicesApproval]:
    BusinessLoanProductApplicationLabels.CreditApproval,
  [BusinessLoanProductApplicationStatuses.ExecutionOfPreDisbursementDocuments]:
    BusinessLoanProductApplicationLabels.SigningOfPreDisbursmentDocuments,
  [BusinessLoanProductApplicationStatuses.CollateralManagerVisit]:
    BusinessLoanProductApplicationLabels.CollateralManagerVisit,
  [BusinessLoanProductApplicationStatuses.DisbursementOfFunds]:
    BusinessLoanProductApplicationLabels.DisbursementOfFunds,
  [BusinessLoanProductApplicationStatuses.Rejected]: BusinessLoanProductApplicationLabels.Rejected,
  [BusinessLoanProductApplicationStatuses.Approved]: BusinessLoanProductApplicationLabels.Approved,
};

export const BUSINESS_STATUS_LABEL_TO_STATUS_TYPE = {
  [BusinessLoanProductApplicationLabels.ApplicationSubmitted]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.CreditReview]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.CreditAssessment]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.CreditApproval]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.SigningOfPreDisbursmentDocuments]:
    StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.CollateralManagerVisit]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.DisbursementOfFunds]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.Rejected]: StatusLabelList.InProgress,
  [BusinessLoanProductApplicationLabels.Approved]: StatusLabelList.InProgress,
};

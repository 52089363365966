import ContactInfoFields from 'components/ContactInfoFields';
import DateInput from 'components/DateInput';
import FileUploadInput from 'components/FileUploadInput/FileUploadInput';
import Input from 'components/Input';
import SelectInput from 'components/SelectInput';
import { InputLabels } from 'enums/InputLabels';
import { Variables } from 'enums/Variables';
import { Formik, FormikProps } from 'formik';
import { getSelectedOptionByValue } from 'helpers/getSelectedOptionByValue';
import {
  TFirstDirectorData,
  TSecondDirectorData,
  TRepresentativeData,
} from 'pages/CreateAccount/types';
import React, { RefObject } from 'react';
import styles from './PersonalInformationForm.module.scss';
import { ObjectSchema, AnyObject } from 'yup';
import { NATIONAL_ID_TYPE_OPTIONS_LIST } from './constants';
import { TOptionalString } from 'types/common';
import NumberInput from 'components/NumberInput';

interface IDirectorOneInformationFormProps<T> {
  data: T;
  formRef: RefObject<FormikProps<Partial<T>>>;
  variablesMap: Record<string, keyof T>;
  validationSchema: ObjectSchema<AnyObject>;
  disableInputs?: boolean;
}

const virtualNationalIdInputDescription = (
  <div className={styles.virtualNationalIdINputDescription}>
    <p>Virtual NIN consists of 16 alpha-numeric characters that can be generated using:</p>
    <p>1. NIMC official mobile app</p>
    <p>2. USSD - *346*3*customer NIN*696739#</p>
  </div>
);

const DirectorOneInformationForm = <
  T extends TFirstDirectorData | TSecondDirectorData | TRepresentativeData,
>({
  data,
  formRef,
  variablesMap,
  validationSchema,
  disableInputs,
}: IDirectorOneInformationFormProps<T>) => {
  const {
    firstName,
    phone,
    middleName,
    lastName,
    dateOfBirth,
    bankVerificationNumber,
    email,
    residentialAddress,
    documentType,
    bvnImage,
    nationalIdNumber,
    virtualNationalIdNumber,
  } = variablesMap;

  return (
    <Formik
      initialValues={data}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
      innerRef={formRef}
    >
      {({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => {
        const getErrorMessage = (name: keyof T) =>
          !!touched[name] && errors[name] ? errors[name] : undefined;

        return (
          <div className={styles.container}>
            <Input
              label={InputLabels.FirstName}
              value={values[firstName] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={firstName as Variables}
              error={getErrorMessage(firstName)}
              disabled={disableInputs}
            />
            <Input
              label={InputLabels.MiddleName}
              value={values[middleName] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={middleName as Variables}
              disabled={disableInputs}
              optional
            />
            <Input
              label={InputLabels.LastName}
              value={values[lastName] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={lastName as Variables}
              error={getErrorMessage(lastName)}
              disabled={disableInputs}
            />
            <DateInput
              label={InputLabels.DateOfBirth}
              value={values[dateOfBirth] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={dateOfBirth as Variables}
              error={getErrorMessage(dateOfBirth)}
              disabled={disableInputs}
            />
            <NumberInput
              label={InputLabels.BankVerificationNumber}
              value={values[bankVerificationNumber] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={bankVerificationNumber as Variables}
              error={getErrorMessage(bankVerificationNumber)}
              disabled={disableInputs}
            />
            <ContactInfoFields
              onChange={handleChange}
              onBlur={handleBlur}
              phoneInputName={phone as Variables}
              emailInputName={email as Variables}
              phoneInputErrorMessage={getErrorMessage(phone) as TOptionalString}
              emailInputErrorMessage={getErrorMessage(email) as TOptionalString}
              phoneNumberValue={values[phone] as unknown as string}
              emailValue={values[email] as unknown as string}
              disableInputs={disableInputs}
            />
            <Input
              label={InputLabels.ResidentialAddress}
              value={values[residentialAddress] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={residentialAddress as Variables}
              error={getErrorMessage(residentialAddress)}
              disabled={disableInputs}
            />
            <SelectInput
              selectedOption={getSelectedOptionByValue(
                NATIONAL_ID_TYPE_OPTIONS_LIST,
                values[documentType] as unknown as string,
              )}
              onValuePicked={(value) => {
                setFieldValue(documentType as Variables, value);
              }}
              options={NATIONAL_ID_TYPE_OPTIONS_LIST}
              placeholder={InputLabels.NationalIdType}
              name={documentType as Variables}
              errorMessage={getErrorMessage(documentType) as TOptionalString}
              disabled={disableInputs}
            />
            <FileUploadInput
              label={InputLabels.PassportPhoto}
              onFileChange={(value) => {
                setFieldValue(bvnImage as string, value);
              }}
              error={getErrorMessage(bvnImage) as TOptionalString}
              files={values[bvnImage] as unknown as File[]}
              disabled={disableInputs}
              name={bvnImage as string}
            />
            <Input
              label={InputLabels.NationalIdNumber}
              value={values[nationalIdNumber] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={nationalIdNumber as Variables}
              error={getErrorMessage(nationalIdNumber)}
              disabled={disableInputs}
            />
            <Input
              label={InputLabels.VirtualNationalIdNumber}
              value={values[virtualNationalIdNumber] as unknown as string}
              onChange={handleChange}
              onBlur={handleBlur}
              name={virtualNationalIdNumber as Variables}
              error={getErrorMessage(virtualNationalIdNumber)}
              disabled={disableInputs}
              inputDescription={virtualNationalIdInputDescription}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default DirectorOneInformationForm;
